import React, { useState, useEffect } from "react";
import { MDBDataTable } from "mdbreact";
import { Row, Col, Card, CardBody, Button, Label } from "reactstrap";
import accessToken from "../../../helpers/jwt-token-access/accessToken";
import axios from "axios";
// import $ from "jquery";
import Select from "react-select";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
// import { CSVLink } from "react-csv";
import { Pagination } from "@mui/material"
import { get, jsonToExcel } from "../../../helpers/api_helper";
import {
  getLocalbody,
  getFirstday,
  getDate,
  isSuperAdmin,
} from "../../../helpers/globalFunctions";
import moment from "moment";
import "./localbodyReport.scss";
import toastr from "toastr";

// import * as XLSX from 'xlsx';

const LocalbodyReport = (props) => {
  const [details, setDetails] = useState([]);
  const [wardOptions, setWardOptions] = useState([]);
  const [page, setPage] = useState(1)
  const [count, setCount] = useState(0)
  const [limit] = useState(20);
  const [selectedWard, setSelectedWard] = useState(null);
  const [searchData, setSearchData] = useState({
    // from_date: getMonth(new Date()),
    from_date: getFirstday(new Date()),
    to_date: getDate(new Date()),
  });

  const [disableButton, setDisableButton] = useState(false);
  // const [currentPage, setCurrentPage] = useState(1);
  // const [itemsPerPage] = useState(10);


  // const indexOfLastItem = currentPage * itemsPerPage;
  // const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  // const currentItems = details.slice(indexOfFirstItem, indexOfLastItem);

  const [currentLocalbody, setCurrectLocalbody] = useState(getLocalbody());
  const [staffOptions, setStaffOptions] = useState([]);
  const [selectedStaff, setSelectedStaff] = useState(null);
  // const [dataToBeExported, setDataToBeExported] = useState([]);
  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";


  const handleTableData = async (
    // from_date = "",
    // to_date = "",
    // ward_id = "",
    // staff_id = ""
  ) => {


    try {
      const userId = JSON.parse(localStorage.getItem("authUser"))?._id;
      if (!userId) {
        console.warn("authUser not found in localStorage");
        return;
      }

      const startIndex = (page - 1) * limit;

      const params = new URLSearchParams({
        localbody_id: currentLocalbody,
        user_id: userId,
        from_date: searchData.from_date || getFirstday(new Date()),
        to_date: searchData.to_date || getDate(new Date()),
        ward_id: searchData.ward_id || "",
        staff_id: searchData.staff_id || "",
        start: startIndex.toString(),  // Make sure it's a string
        limit: limit.toString()
      });

      console.log('Request parameters:', {
        startIndex,
        limit,
        page,
        url: `${API_URL}reports/new/localbodyReport?${params.toString()}`
      });

      const response = await axios.get(`${API_URL}reports/new/localbodyReport`, {
        params,
        headers: {
          "x-access-token": accessToken,
        },
      });


      if (response.data.data && Array.isArray(response.data.data)) {
        setCount(response.data.count);

        const result = response.data.data.map((item, index) => ({
          ...item,
          id: startIndex + index + 1,  // Calculate correct ID based on page
          total_houses: item.house.house,
          permanent_lock_house: item.house.permanentLock,
          visited_houses: item.house.visited,
          no_waste_houses: item.house.noWaste,
          house_closed: item.house.closed,
          payment_pending_houses: item.house.paymentPending,
          paid_houses: item.house.paid,
          house_amt: item.house.amount,
          total_weight_house: item.house.weight,
          total_shops: item.shop.shop,
          visited_shops: item.shop.visited,
          permanent_lock_shops: item.shop.permanentLock,
          no_waste_shops: item.shop.noWaste,
          shop_closed: item.shop.closed,
          payment_pending_shops: item.shop.paymentPending,
          paid_shops: item.shop.paid,
          shop_amt: item.shop.amount,
          total_weight_shop: item.shop.weight,
          totalBuilding: item.shop.shop + item.house.house,
          totalVisited: item.shop.visited + item.house.visited,
          totalPermanentLock: item.shop.permanentLock + item.house.permanentLock,
          totalNoWaste: item.shop.noWaste + item.house.noWaste,
          totalClosed: item.shop.closed + item.house.closed,
          totalPaymentPending: item.shop.paymentPending + item.house.paymentPending,
          totalPaid: item.shop.paid + item.house.paid,
          totalWeight: item.shop.weight + item.house.weight,
          totalAmount: item.shop.amount + item.house.amount

        }));

        setDetails(result);
        // setCurrentPage(1); // Reset to first page when new data is loaded
      } else {
        console.error('Invalid data format received:', response.data);
        setDetails([]);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setDetails([]);
    }
  };

  useEffect(() => {
    handleTableData();
    fetch_all_staff(currentLocalbody);
    fetch_all_wards(currentLocalbody);
  }, [page, searchData, currentLocalbody]);

  useEffect(() => {
    const ward_id = searchData?.ward_id || "";
    const staff_id = searchData?.staff_id || "";
    const date1 = searchData?.from_date || "";
    const date2 = searchData?.to_date || "";
    handleTableData(date1, date2, ward_id, staff_id);
    // setCurrentPage(1);
  }, [searchData, page]);

  const getCurrentUserId = () => {
    try {
      const authUser = localStorage.getItem("authUser");
      if (authUser) {
        const userData = JSON.parse(authUser);
        return userData._id; // This is how you access the ID in your code
      }
      return null;
    } catch (error) {
      console.error("Error getting user ID:", error);
      return null;
    }
  };
  function handleExport() {
    setDisableButton(true);
    let dateStart = moment(searchData?.from_date);
    let dateEnd = moment(searchData?.to_date);
    let dates = [];
    while (dateEnd > dateStart || dateStart.format('M') === dateEnd.format('M')) {
      let formattedDate = dateStart.format('MMM YY');
      dates.push(formattedDate);
      dateStart.add(1, 'month'); // Increment by 1 month
    }

    let url = `${API_URL}reports/new/localbodyReport/export?localbody_id=${currentLocalbody}&user_id=${getCurrentUserId()}&from_date=${searchData?.from_date}&to_date=${searchData?.to_date}&`;
    if (searchData?.ward_id) {
      url += `ward_id=${searchData?.ward_id}&`;
    }
    if (searchData?.staff_id) {
      url += `staff_id=${searchData?.staff_id}&`;
    }


    axios
      .get(url, {
        headers: {
          'x-access-token': accessToken,
        },
      })
      .then((res) => {
        let result = res?.data?.data || [];
        let exportData = [];
        result.map((ward) => {
          let exportItem = {
            'Ward Name': ward?.ward_name,
            'Houses': ward?.house?.house,
            'Houses Visited': ward?.house?.visited,
            'Permanent Lock': ward?.house?.permanentLock,
            'No Waste': ward?.house?.noWaste,
            'Closed': ward?.house?.closed,
            'Payment Pending': ward?.house?.paymentPending,
            'Paid': ward?.house?.paid,
            'Amount': ward?.house?.amount,
            'Weight': ward?.house?.weight,
            'Shops': ward?.shop?.shop,
            'Shops Visited': ward?.shop?.visited,
            'Permanent Lock Shop': ward?.shop?.permanentLock,
            'No Waste Shop': ward?.shop?.noWaste,
            'Closed Shop': ward?.shop?.closed,
            'Payment Pending Shop': ward?.shop?.paymentPending,
            'Paid Shop': ward?.shop?.paid,
            'Amount Shop': ward?.shop?.amount,
            'Weight Shop': ward?.shop?.weight,
            'Total Building': ward?.house?.house + ward?.shop?.shop,
            'Total Visited': ward?.house?.visited + ward?.shop?.visited,
            'Total Permanent Lock': ward?.house?.permanentLock + ward?.shop?.permanentLock,
            'Total No Waste': ward?.house?.noWaste + ward?.shop?.noWaste,
            'Total Closed': ward?.house?.closed + ward?.shop?.closed,
            'Total Payment Pending': ward?.house?.paymentPending + ward?.shop?.paymentPending,
            'Total Paid': ward?.house?.paid + ward?.shop?.paid,
            'Total Amount': ward?.house?.amount + ward?.shop?.amount,
            'Total Weight': ward?.house?.weight + ward?.shop?.weight,
          };
          exportData.push(exportItem);
        });
        if (exportData.length > 0) {
          jsonToExcel(exportData, `ulb-report-${moment().format('DD-MM-YY')}`);
        } else {
          toastr.info("There are no data to export")
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally((res) => {
        setDisableButton(false);
      });
  }

  // Add pagination handler
  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };



  function fetch_all_wards(localbody) {
    axios
      .get(`${API_URL}api/staff/wards?localbody_id=` + localbody, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var ward_data =
          res.data.data &&
          res.data.data.map((el) => {
            return {
              label: el.ward_name,
              value: el._id,
            };
          });

        setWardOptions([
          {
            options: ward_data,
          },
        ]);
      });
  }


  const fetch_all_staff = (id) => {
    axios
      .get(`${API_URL}user/options?localbody_id=` + id, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var staff_data =
          res.data.data &&
          res.data.data.map((el) => {
            if (el?.lastName) el.name = el.firstName + " " + el.lastName;
            else el.name = el.firstName;
            return {
              label: el.name,
              value: el._id,
            };
          });
        setStaffOptions([
          {
            options: staff_data,
          },
        ]);
      });
  };





  const data = {
    columns: [
      // {
      //   label: "Ward No",
      //   field: "ward_no",
      //   sort: "asc",
      // },
      {
        label: "Ward Name",
        field: "ward_name",
        sort: "asc",
      },
      {
        label: "Total Houses",
        field: "total_houses",
        sort: "asc",
      },
      {
        label: "Visited Houses",
        field: "visited_houses",
        sort: "asc",
      },
      {
        label: "Permanent Lock (Houses)",
        field: "permanent_lock_house",
        sort: "asc",
      },
      {
        label: "No Waste (Houses)",
        field: "no_waste_houses",
        sort: "asc",
      },
      {
        label: "Closed Houses",
        field: "house_closed",
        sort: "asc",
      },
      {
        label: "Payment Pending Houses",
        field: "payment_pending_houses",
        sort: "asc",
      },
      {
        label: "Paid Houses",
        field: "paid_houses",
        sort: "asc",
      },
      {
        label: "House Amount",
        field: "house_amt",
        sort: "asc",
      },
      {
        label: "Total Weight House",
        field: "total_weight_house",
        sort: "asc",
      },
      {
        label: "Total Shops",
        field: "total_shops",
        sort: "asc",
      },
      {
        label: "Visited Shops",
        field: "visited_shops",
        sort: "asc",
      },
      {
        label: "Permanent Lock (Shops)",
        field: "permanent_lock_shops",
        sort: "asc",
      },
      {
        label: "No Waste (Shops)",
        field: "no_waste_shops",
        sort: "asc",
      },
      {
        label: "Closed Shops",
        field: "shop_closed",
        sort: "asc",
      },
      {
        label: "Payment Pending Shops",
        field: "payment_pending_shops",
        sort: "asc",
      },
      {
        label: "Paid Shops",
        field: "paid_shops",
        sort: "asc",
      },
      {
        label: "Shop Amount",
        field: "shop_amt",
        sort: "asc",
      },
      {
        label: "Total Weight Shops",
        field: "total_weight_shop",
        sort: "asc",
      },
      {
        label: "Total Amount",
        field: "totalAmount",
        sort: "asc",
      },
      {
        label: "Total Weight(Kg)",
        field: "totalWeight",
        sort: "asc",
      },
      {
        label: "Total Building",
        field: "totalBuilding",
        sort: "asc",
      },
      {
        label: "Total Visited",
        field: "totalVisited",
        sort: "asc",
      },
      {
        label: "Permanent Lock (Total)",
        field: "totalPermanentLock",
        sort: "asc",
      },
      {
        label: "No Waste (Total)",
        field: "totalNoWaste",
        sort: "asc",
      },
      {
        label: "Total Closed",
        field: "totalClosed",
        sort: "asc",
      },
      {
        label: "Total Payment Pending",
        field: "totalPaymentPending",
        sort: "asc",
      },
      {
        label: "Total Paid",
        field: "totalPaid",
        sort: "asc",
      },
    ],
    rows: details,
  };


  const handleDate = (e) => {
    const { name, value } = e.target;
    const toDate = moment(value);
    const fromDate = moment(searchData.to_date);
    const daysDiff = fromDate.diff(toDate, "days");

    setSearchData(prev => ({
      ...prev,
      [name]: value,
      ...(daysDiff + 1 <= 0 && name === 'from_date' && { to_date: "" })
    }));
  };



  const handleSelectChange = (selected, name) => {
    const fieldMap = {
      ward: "ward_id",
      staff: "staff_id"
    };

    const field = fieldMap[name];
    if (field) {
      setSearchData(prev => ({
        ...prev,
        [field]: selected?.value || ""
      }));

      if (name === 'ward') setSelectedWard(selected);
      if (name === 'staff') setSelectedStaff(selected);
    }
  };


  const reset = () => {
    setSearchData({
      // from_date: getMonth(new Date()),
      from_date: getFirstday(new Date()),
      to_date: getDate(new Date()),
    });
    setSelectedWard(null);
    setSelectedStaff(null);
    // handleTableData();
    setPage(1);
  };

  const startingYear = 2025;
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const [monthOptions, setMonthOptions] = useState([]);
  const [yearOptions, setYearOptions] = useState([]);
  const [selectedValues, setselectedValues] = useState({})
  const [downloadDate, setdownloadDate] = useState("")




  useEffect(() => {
    // Set defaults on page load
    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth(); // 0-based index

    // Set year options
    const startingYear = 2025; // Example starting year
    const years = Array.from(
      { length: currentYear - startingYear + 1 },
      (_, i) => ({
        label: (startingYear + i).toString(),
        value: startingYear + i,
      })
    );
    setYearOptions(years);

    // Set month options for the current year
    const monthsToDisplay = months.slice(0, currentMonth + 1);
    const defaultMonthOptions = monthsToDisplay.map((month, index) => ({
      label: `${currentYear}-${month.slice(0, 3)}`, // Short month name
      value: `${currentYear}-${index + 1}`, // 1-based index for months
    }));
    setMonthOptions(defaultMonthOptions);

    // Set default filter object with current year and current month
    setselectedValues({
      year: { label: currentYear.toString(), value: currentYear },
      month: {
        label: `${currentYear}-${months[currentMonth].slice(0, 3)}`, // Current month label
        value: `${currentYear}-${currentMonth + 1}`, // Current month value (1-based index)
      },
    });
    setdownloadDate(`${currentYear}-${currentMonth + 1}`)

  }, []);

  console.log(downloadDate, "downlod date");

  function getAllyears() {
    const currentYear = new Date().getFullYear();
    const years = Array.from(
      { length: currentYear - startingYear + 1 },
      (_, i) => ({
        label: (startingYear + i).toString(),
        value: startingYear + i,
      })
    );
    setYearOptions(years);
  }

  function getcurrentyearMonth() {
    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth(); // 0-based index
    const monthOptions = months.slice(0, currentMonth + 1).map((month, index) => ({
      label: `${currentYear}-${month.slice(0, 3)}`, // Format YYYY-MMM
      value: `${currentYear}-${index + 1}`, // Format YYYY-MM
    }));
    setYearOptions([]); // Reset year options
    setMonthOptions(monthOptions);

  }

  const handleYearChange = (name, selectedYear) => {
    setselectedValues({ [name]: selectedYear })
    setdownloadDate("")
    const selectedYearValue = selectedYear.value;
    // Adjust months for the selected year
    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth(); // 0-based index
    const monthsToDisplay =
      selectedYearValue === currentYear
        ? months.slice(0, currentMonth + 1)
        : months;

    const monthOptions = monthsToDisplay.map((month, index) => ({
      label: `${selectedYearValue}-${month.slice(0, 3)}`,
      value: `${selectedYearValue}-${index + 1}`,
    }));

    setMonthOptions(monthOptions);

  };

  function handleMonthChnage(name, select) {
    setselectedValues({ ...selectedValues, [name]: select })
    setdownloadDate(select.value)
  }


  console.log(selectedValues);

  function downloadLocalbodyReport() {
    get(`reports/localbody/download?localbodyId=${getLocalbody()}&month=${downloadDate}`).then((res) => {
      console.log(res, "res");
      toastr.success("file downloaded")
    }).catch((err)=>{
      toastr.error("File not found")
    })
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Localbody Report" />
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <Row>
                    {/* <Col md="2">
                      <div className="mb-3">
                        <Label htmlFor="validationCustom05">From</Label>
                        <input
                          className="form-control"
                          type="month"
                          id="from_date"
                          name="from_date"
                          value={searchData?.from_date}
                          onChange={handleDate}
                        />
                      </div>
                    </Col> */}
                    <Col md="2">
                      <div className="mb-3">
                        <Label htmlFor="validationCustom05">From</Label>
                        <input
                          className="form-control"
                          type="date"
                          id="from_date"
                          name="from_date"
                          value={searchData?.from_date}
                          onChange={handleDate}
                        />
                      </div>
                    </Col>
                    <Col md="2">
                      <div className="mb-3">
                        <Label htmlFor="validationCustom05">To</Label>
                        <input
                          className="form-control"
                          type="date"
                          id="to_date"
                          name="to_date"
                          value={searchData?.to_date}
                          min={searchData?.from_date}
                          onChange={handleDate}
                        />
                      </div>
                    </Col>
                    <Col md="3">
                      <div className="mb-3">
                        <Label>Ward</Label>
                        <Select
                          name="ward_id"
                          value={selectedWard}
                          onChange={(selected) => {
                            handleSelectChange(selected, "ward");
                          }}
                          options={wardOptions}
                          classNamePrefix="select2-selection"
                          isClearable
                        />
                      </div>
                    </Col>
                    <Col md="3">
                      <div className="mb-3">
                        <Label>Staff</Label>
                        <Select
                          name="staff_id"
                          value={selectedStaff}
                          onChange={(selected) => {
                            handleSelectChange(selected, "staff");
                          }}
                          options={staffOptions}
                          classNamePrefix="select2-selection"
                          isClearable
                        />
                      </div>
                    </Col>
                    <Col md="2">
                      <div style={{ display: "flex" }}>
                        {isSuperAdmin() && (
                          <div className="mt-1 mb-3 mx-1" style={{ paddingTop: "23px" }}>
                            <Button
                              color="success"
                              onClick={handleExport}
                            >
                              Export
                            </Button>
                          </div>
                        )}
                        <div className="mt-1 mb-3 mx-1" style={{ paddingTop: "23px" }}>
                          <Button
                            color="danger"
                            type="reset"
                            onClick={reset}
                          >
                            Reset
                          </Button>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="2">
                      <Label>Year</Label>
                      <Select onChange={(selected) => handleYearChange("year", selected)}
                        options={yearOptions} value={selectedValues?.year || null} />
                    </Col>
                    <Col md="2">
                      <Label>Month</Label>
                      <Select onChange={(selected) => handleMonthChnage("month", selected)} options={monthOptions} value={selectedValues?.month || null} />
                    </Col>
                    <Col md="2">
                      <div className="mt-1 mb-3 mx-1" style={{ paddingTop: "23px" }}>
                        <Button
                          color="primary"
                          onClick={downloadLocalbodyReport}
                        >
                          Download
                        </Button>
                      </div>
                    </Col>
                  </Row>
                  <MDBDataTable
                    responsive
                    striped
                    bordered
                    data={data}
                    info={true}
                    searching={true}
                    disableRetreatAfterSorting={true}
                    entries={limit}
                    displayEntries={false}
                    paging={false}
                  />

                  {/* <div class="row align-items-center">
                    <div
                      dataTest="datatable-info"
                      className="col-sm-12 col-md-5"
                    >
                      <div
                        className="dataTables_info"
                        role="status"
                        ariaLive="polite"
                      >
                        Showing {(page - 1) * limit + 1} to{" "}
                        {Math.min(page * limit, count)} of {count} entries
                      </div>
                    </div>
                    <div
                      data-test="datatable-pagination"
                      className="col-sm-12 col-md-7"
                    >
                      <div className="dataTables_paginate">
                        <Pagination
                          page={page}
                          count={Math.ceil(count / limit)}
                          shape="rounded"
                          size="small"
                          onChange={handlePageChange}
                        />
                      </div>
                    </div>
                  </div> */}

                  {/* Add console log to check values */}
                  {/* {console.log("Pagination props:", {
                    totalItems: details.length,
                    itemsPerPage,
                    currentPage,
                    details: details
                  })} */}

                  {/* Make sure details exists and has length before rendering pagination
                  {details && details.length > 0 && (
                    <div className="mt-4">
                      <Pagination
                        totalItems={details.length}
                        itemsPerPage={itemsPerPage}
                        currentPage={currentPage}
                        onPageChange={handlePageChange}
                      />
                    </div>
                  )} */}

                  <div className="row align-items-center">
                    <div className="col-sm-12 col-md-5">
                      <div className="dataTables_info">
                        Showing {count > 0 ? (page - 1) * limit + 1 : 0} to{" "}
                        {Math.min(page * limit, count)} of {count} entries
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-7">
                      <div className="dataTables_paginate">
                        <Pagination
                          page={page}
                          count={Math.ceil(count / limit)}
                          shape="rounded"
                          size="small"
                          onChange={handlePageChange}
                          disabled={count === 0}
                        />
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

export default LocalbodyReport;
