import React from "react"
import { Button } from "reactstrap"

function LoadingButton({
  onClick = () => {},
  className,
  loading = false,
  children,
  style = {},
  disabled,
}) {
  return (
    <Button
      onClick={onClick}
      loading={true}
      color="success"
      className={`mx-2 ${className}`}
      style={style}
      disabled={disabled || loading}
    >
      {children} {loading && <i className="mdi mdi-spin mdi-loading"></i>}
    </Button>
  )
}

export default LoadingButton
