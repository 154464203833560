import React, { useEffect, useRef, useState } from "react";
import { MDBDataTable } from "mdbreact";
import Select from "react-select";
import { Row, Col, Card, CardBody, Button, Label } from "reactstrap";
import Creatable from "react-select/creatable";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { AvField, AvForm } from "availity-reactstrap-validation";
import { isArray } from "lodash";
import { Modal } from "react-bootstrap";
import axios from "axios";
import toastr from "toastr";
import "./style.scss";
import accessToken from "../../../helpers/jwt-token-access/accessToken";
import {
  checkObjectIdValid,
  getLocalbody,
  getUserId,
} from "../../../helpers/globalFunctions";
import { Pagination } from "@mui/material"
import CreatableSelect from "react-select/creatable";
import { post, put } from "../../../helpers/api_helper";
import moment from "moment";
import SweetAlert from "react-bootstrap-sweetalert";
import { id } from "date-fns/locale";
const Complaints = () => {
  const localbody = getLocalbody();
  const formRef = useRef();
  const PopUpRef = useRef();
  const initialState = {
    localbodyId: getLocalbody(),
    userId: getUserId(),
    image: [""],
  };

  const followupState = {
    localbodyId: getLocalbody(),
    userId: getUserId(),
    image: [""],
    status: "",
    comment: "",
  };
  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";
  const [master, setMaster] = useState(initialState);
  const [filterObject, setFilterObject] = useState({});
  const [filterSelectObject, setFilterSelectObject] = useState({});

  const [followUp, setFollowUp] = useState(followupState);

  const [wardOptions, setWardOptions] = useState([]);
  const [staffComplaintIdToBeDeleted, setStaffComplaintIdToBeDeleted] =
    useState(null);
  const [complaintId, setComplaintId] = useState(null);
  const [categoryOption, setcategoryOption] = useState([])

  const [groupOptions, setGroupOptions] = useState([]);
  const [confirmDeleteAlert, setConfirmDeleteAlert] = useState(null);
  const [customerOptions, setCustomerOptions] = useState([]);
  const [complaintCategoryOptions, setComplaintCategoryOptions] = useState([]);
  const [staffOptions, setStaffOptions] = useState([]);
  const [assaignedStaff, setAssaignedStaff] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [FollowUpTableData, setFollowUpTableData] = useState([]);
  const [selected, setSelected] = useState({});
  const [openModal, setOpenModal] = useState(false);
  const [popupData, setPopupData] = useState({});
  const [page, setPage] = useState(1)
  const [count, setCount] = useState(0)
  const [limit, setLimit] = useState(20)
  const [oldNewData, setoldNewData] = useState([])
  const [errors, setErrors] = useState({});
  const handleCloseModal = () => {
    setOpenModal(false);
  };
  useEffect(() => {
    fetch_all_wards(localbody);
    fetch_all_customers(localbody, "");
    fetch_all_staff();
    fetch_complaint_catagory();
    // handleTableData(localbody);
    fetch_customers();
    fetch_complaint_staff();
    handlePopUpTable();
  }, [localbody, master?.wardId, followUp?.complaintId]);

  useEffect(() => {
    handleTableData(localbody);
  }, [filterObject, page, localbody]);

  useEffect(() => {
    handlePopUpTable();
  }, [followUp?.complaintId]);

  useEffect(()=>{
    fetchAllCategory()
  },[master.ticketType, master.complaintType])

  const fetch_all_staff = () => {
    axios
      // .get(`${API_URL}complaint/name/select?wardId=${master.wardId}&type=2`,
      .get(`${API_URL}staff/staff-list?localBody_id=${localbody}`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let staff_data = res.data.data;

        setStaffOptions(staff_data);
      });
  };
  const fetch_complaint_staff = () => {
    axios
      .get(
        `${API_URL}complaint/staff/toassign?complaintId=${followUp?.complaintId}&wardId=${master.wardId}`,
        {
          headers: {
            "x-access-token": accessToken,
          },
        }
      )
      .then((res) => {
        var assaigned_staff =
          res.data.data &&
          res.data.data.map((el) => {
            return {
              label: el.firstName + el.lastName,
              value: el._id,
            };
          });

        setAssaignedStaff([
          {
            options: assaigned_staff,
          },
        ]);
      });
  };
  function fetch_all_wards(localbody) {
    axios
      .get(`${API_URL}api/staff/wards?localbody_id=` + localbody, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var ward_data =
          res.data.data &&
          res.data.data.map((el) => {
            return {
              label: el.ward_name,
              value: el._id,
            };
          });

        setWardOptions([
          {
            options: ward_data,
          },
        ]);
      });
  }
  const fetch_complaint_catagory = () => {
    axios
      .get(`${API_URL}customercomplaint/categorylist`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var complaint_category_data =
          res.data.data &&
          res.data.data.map((el) => {
            return {
              label: el.category_name,
              value: el._id,
            };
          });

        setComplaintCategoryOptions([
          {
            options: complaint_category_data,
          },
        ]);
      });
  };


  const handleTableData = (localbody) => {
    axios
      .get(
        `${API_URL}complaint?localbodyId=${localbody}&start=${page}&limit=20&fromDate=${filterObject?.fromDate}&toDate=${filterObject?.toDate}&wardId=${filterObject?.wardId}&complaintType=${filterObject?.complaintType}&ticketType=${filterObject?.ticketType}&postingType=${filterObject?.postingType}&crntStatus=${filterObject?.crntStatus}`,
        {
          headers: {
            "x-access-token": accessToken,
          },
        }
      )
      .then((res) => {
        setCount(res.data.count)

        let result = res.data.data
        result && result.map((item, index) => {
          item.id = (page - 1) * limit + index + 1
          item.date = item?.date ? moment(item?.date).format("DD-MM-YYYY") : "DD-MM-YYYY";
          item.type1 = item.ticketType == "1" ? "Complaint" : item.ticketType == "2" ? "Request" : item.ticketType == "3" ? "Interaction" : "---"
          item.from1 = item?.from ? item.from?.name : "--"
          item.to1 = item?.to ? item.to?.name : "--"
          item.category = item?.complaintType == "0" ? "Normal" : item?.complaintType == "1" ? "Public" : item?.complaintType == "2" ? "No waste" : "--"
          item.status = item?.crntStatus == "0" ? "Active" : item?.crntStatus == "1" ? "Esculated" : item?.crntStatus == "2" ? "Closed" : item?.crntStatus == "3" ? "Reject" : "---"
          item.action = (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <i
                className="fas fa-eye"
                onClick={() => {
                  setComplaintId(item._id);
                  handleClickOpenModal(item._id);
                  setFollowUp((prev) => ({
                    ...prev,
                    complaintId: item._id,
                  }));
                }}
                style={{
                  fontSize: "1em",
                  cursor: "pointer",
                  marginRight: "0.5em",
                }}
              ></i>
              <i
                onClick={() => {
                  setStaffComplaintIdToBeDeleted(item._id);
                  setConfirmDeleteAlert(true);
                }}
                className="far fa-trash-alt"
                style={{ fontSize: "1em", cursor: "pointer" }}
              ></i>
            </div>
          );
          return item
        })
        setTableData(result)

      });
  };


  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
      },
      {
        label: "Date",
        field: "date",
        sort: "asc",
      },
      {
        label: "Type",
        field: "type1",
        sort: "asc",
      },
      {
        label: "ID",
        field: "uniqueId",
        sort: "asc",
      },
      {
        label: "From",
        field: "from1",
        sort: "asc",
      },
      {
        label: "To",
        field: "to1",
        sort: "asc",
      },
      {
        label: "Category",
        field: "category",
        sort: "asc",
        width: "150",
      },
      // {
      //   label: "Mobile",
      //   field: "Staff_mobile",
      //   sort: "asc",
      //   width: 150,
      // },
      // {
      //   label: "Localbody",
      //   field: "localbody",
      //   sort: "asc",
      //   width: 150,
      // },
      {
        label: "Ward",
        field: "ward",
        sort: "asc",
      },
      // {
      //   label: "Group",
      //   field: "group",
      //   sort: "asc",
      //   width: 150,
      // },
      {
        label: "Comment",
        field: "comment",
        sort: "asc",
        width: 150,
      },
      {
        label: "Status",
        field: "status",
        sort: "asc",
      },
      // {
      //   label: "Staff",
      //   field: "staaff",
      //   sort: "asc",
      //   width: 150,
      // },
      {
        label: "Action",
        field: "action",
        sort: "asc",
      },
    ],
    rows: tableData,
  };


  const handlePopUpTable = () => {
    axios
      .get(
        `${API_URL}complaint/followup?complaintId=${followUp?.complaintId}`,
        {
          headers: {
            "x-access-token": accessToken,
          },
        }
      )
      .then((res) => {
        const data = res?.data?.data;
        data?.map((item, index) => {
          item.date = moment(item?.followupDate).format("DD-MM-YYYY");
          item.time = item?.time ? moment(item.time, "HH mm ss").format("hh:mm A") : "--"
          item.id = index + 1;
          item.status =
            item?.crntStatus == 0
              ? "Active"
              : item?.crntStatus == 1
                ? "Escalated"
                : item?.crntStatus == 2
                  ? "Closed"
                  : null;
          item.staff = `${item.addedBy?.firstName || ""} ${item.addedBy?.lastName || ""}`;
          item.assigned = `${item.assignTo?.firstName || ""} ${item.assignTo?.lastName || ""}`;
          const validImages =
            item.followupImg?.filter((img) => img.trim() !== "") || [];
          item.img =
            validImages.length > 0
              ? item.followupImg.map((el, index) => (
                <a
                  key={index}
                  href={`${API_URL}uploads/complaint_images/${el}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ margin: "5px" }} // Optional styling for spacing
                >
                  <i
                    className="fas fa-file-image"
                    style={{ cursor: "pointer", fontSize: "18px" }} // Adjust size as needed
                  />
                </a>
              ))
              : null;

          item.audio = item?.followupAudio && (
            <audio controls style={{ height: "35px", width: "252px" }}>
              <source
                src={`${API_URL}uploads/complaintaudio/${item.followupAudio}`}
                target="_blank"
                type="audio/mpeg"
              />{" "}
              6r
              {/* <source
                              src={`https://nodeapi.nellikkastore.com/uploads/complaintaudio/20240902154453_sample-3s.mp3`}
                              type="audio/mpeg"
                            /> */}
              Your browser does not support the audio element.
            </audio>
          );
          return item;
        });
        setFollowUpTableData(data);
      });
  };
  const handleClickOpenModal = (id) => {
    // setOpenModal(true);
    axios
      .get(`${API_URL}complaint/single?complaintId=` + id, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        const data = res?.data?.data[0];
        setOpenModal(true);
        setPopupData(data);
      });
  };

  const fetch_all_groups = (selected) => {
    axios
      .get(
        `${API_URL}api/staff/groups?localbody_id=` +
        localbody +
        "&ward_id=" +
        selected.value,
        {
          headers: {
            "x-access-token": accessToken,
          },
        }
      )
      .then((res) => {
        if (res.data && res.data.success === true) {
          var group_data =
            res.data.data &&
            res.data.data.map((el) => {
              return {
                label: el.group_name,
                value: el._id,
              };
            });
        } else {
          group_data = [];
        }

        setGroupOptions([
          {
            options: group_data,
          },
        ]);
      });
  };
  const fetch_all_customers = (localbody, query) => {
    let wardId = master?.wardId;
    let data = {
      keyword: query,
      localbody_id: [localbody],
      wardId,
    };
    axios
      .post(`${API_URL}customers/option/list`, data, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {

        let customer_data =
          res.data.data &&
          res.data.data.map((customer) => {
            return {
              label: customer?.cust_name,
              value: customer?._id,
              mobile: customer?.cust_phone
            };
          });
        setCustomerOptions([
          {
            options: customer_data,
          },
        ]);
      });
  };
  const fetch_customers = () => {
    axios
      .get(`${API_URL}complaint/name/select?wardId=${master.wardId}&type=1`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let customer_data = res.data.data;

        // setCustomerOptions(customer_data);
      });
  };

  const handlePageChange = (e, value) => {
    setPage(value)
  }
  const handleValueChange = (e) => {
    let { name, value } = e.target
    const mastr = { ...master };
    if (name == "frommobile") {
      mastr.from = {
        ...(mastr.from || {}),
        mobile: value
      }
    } else if (name == "tomobile") {
      mastr.to = {
        ...(mastr.to || {}),
        mobile: value
      }
    } else {
      mastr[name] = value
    }
    setMaster(mastr)
  };
  const handleReset = () => {
    formRef.current.reset();
    setMaster(initialState);
    setSelected({
      complaintType: "",
      wardId: "",
      category: "",
      against: "",
    });
  };
  const handleSelect = (name, e) => {

    const mastr = { ...master };
    const select = { ...selected };

    const value = e
      ? isArray(e)
        ? e.map((item) => item.value)
        : e.value
      : null;

    if (name == "from") {
      // const mobile = name === "mobile" ? value : master?.form?.mobile || selected?.mobile
      mastr.from = {
        ...(mastr.from || {}),
        id: e.__isNew__ ? null : value,
        name: e.label,
        mobile: e.mobile
      }

    } else if (name == "against") {
      mastr.to = {
        ...(mastr.to || {}),
        id: value,
        name: e.label,
        mobile: e.mobile
      }
    }
    else if (name === "ticketType"){
      if(value === "1"){
        mastr.complaintType = "1"
        select["complaintType"] = {label:"Public",value:"1"}

      }else{ 
        delete mastr.complaintType 
        select["complaintType"] = null
      }
      mastr.ticketType = value
    }
    else {
      mastr[name] = value;
    }
    select[name] = e;
    setMaster(mastr);
    setSelected(select);

  };
  const handlePopUpValue = (name, e) => {
    const followup = { ...followUp };
    followup[name] = e;
    setFollowUp(followup);
  };

  console.log(master,">>>>")



  const handlePopUpSelect = (name, e) => {
    const followup = { ...followUp };
    const select = { ...selected };
    const value = e
      ? isArray(e)
        ? e.map((item) => item.value)
        : e.value
      : null;
    followup[name] = value;
    select[name] = e;
    setFollowUp(followup);

    setSelected(select);
  };
  const handleSelectchange = (name, e) => {
    if (name === "complaintFrom" || name === "complaintTo") {
      // const isExistingOption = e && e.hasOwnProperty("value");
      const isObjectId = checkObjectIdValid(e.value);
      setMaster((prev) => ({
        ...prev,
        [name]: {
          ...prev[name],
          id: isObjectId ? e.value : null,
          name: e.label,
          number: e.mobile || null,
        },
      }));
    }
  };
  const handleFilterSelectValueChange = (name, selected) => {
    const obj = { ...filterSelectObject };
    obj[name] = selected;
    setFilterSelectObject(obj);
    handleFilterValueChange(name, selected.value);
  };
  const handleFilterValueChange = (name, value) => {
    const obj = { ...filterObject };
    obj[name] = value;
    setFilterObject(obj);
  };
  const deleteComplaintImage = (e, idx) => {
    e.preventDefault();
    const prevImage = [...master.image];
    prevImage[idx] = "";
    setMaster((prevState) => ({
      ...prevState,
      image: prevImage,
    }));

    // FollowUp
    const popUpprevImage = [...followUp.image];
    popUpprevImage[idx] = "";
    setFollowUp((prevState) => ({
      ...prevState,
      image: popUpprevImage,
    }));
  };
  const uploadImage = (e, idx) => {
    const fd = new FormData();
    fd.append("complaint_image", e.target.files[0]);
    axios
      .post(`${API_URL}customercomplaint/complaintImage`, fd, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((response) => {
        if (response.data.status === "success") {
          let result = response.data.file[0];
          if (e.target.name === "image") {
            const prevImage = [...master.image];
            prevImage[idx] = result.filename;
            setMaster((prevState) => ({
              ...prevState,
              image: prevImage,
            }));
          } else {
            const popUpprevImage = [...followUp.image];
            popUpprevImage[idx] = result.filename;
            setFollowUp((prevState) => ({
              ...prevState,
              image: popUpprevImage,
            }));
          }
        } else {
          toastr.error(response.data.message);
        }
      });
  };
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const fd = new FormData();
      fd.append("file", file);
      axios
        .post(`${API_URL}complaint/audio/`, fd, {
          headers: {
            "x-access-token": accessToken,
          },
        })
        .then((response) => {
          if (response.data.status === 200) {
            setMaster((prev) => ({
              ...prev,
              audio: response.data.file.filename,
            }));

            setFollowUp((prev) => ({
              ...prev,
              audio: response.data.file.filename,
            }));
          } else {
            toastr.error(response.data.message);
          }
        })
        .catch((error) => {
          console.error("Error uploading file:", error);
        });
    }
  };
  const complaintTableData = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 150,
      },
      {
        label: "Date",
        field: "date",
        sort: "asc",
        width: 150,
      },
      {
        label: "Time",
        field: "time",
        sort: "asc",
        width: 270,
      },
      // {
      //   label: "Complaint ID",
      //   field: "complaint_id",
      //   sort: "asc",
      //   width: 150,
      // },
      {
        label: "Status",
        field: "status",
        sort: "asc",
        width: 150,
      },
      // {
      //   label: "Audio",
      //   field: "audio",
      //   sort: "asc",
      //   width: 150,
      // },
      // {
      //   label: "Image",
      //   field: "img",
      //   sort: "asc",
      //   width: 150,
      // },
      {
        label: "Assigned",
        field: "assigned",
        sort: "asc",
        width: 150,
      },
      {
        label: "Comment",
        field: "comment",
        sort: "asc",
        width: 150,
      },

      {
        label: "AddedBy",
        field: "staff",
        sort: "asc",
        width: 150,
      },
    ],
    rows: FollowUpTableData,
  };


  console.log(errors, "errorsssssss");


  function handleSubmit() {
    if (selected.wardId == null || selected.postingType == null||selected.ticketType==null) {
      const newErrors = {};
      if (selected.wardId == null) newErrors.wardId = "Please select ward";
      if(selected.postingType==null) newErrors.postingType="Please select";
      if(selected.ticketType==null) newErrors.ticketType="Please select Type"

      setErrors(newErrors)
    } else {
      if (master._id) {
        put(`/complaint/staff/update`, master)
          .then((res) => {
            toastr.success(res.message);
            handleTableData(localbody);
            handleReset();

            formRef.current.reset();
          })
          .catch((err) => {
            toastr.error(err.response.data.message);
          });
      } else {
        post(`/complaint`, master)
          .then((res) => {
            toastr.success(res.message);
            handleTableData(localbody);
            handleReset();
            setSelected({});
            formRef.current.reset();
          })
          .catch((err) => {
            // formRef.current.reset()
            toastr.error(err.response.data.message);
          });
      }

    }

  }



  function handlePopUpSubmit(e) {
    e.preventDefault();
    post(`/complaint/followup`, followUp)
      .then((res) => {
        handlePopUpTable();
        handlePopupReset();
        handleClickOpenModal(complaintId)
        handleTableData(localbody);
        toastr.success(res?.message);
      })
      .catch((err) => {
        // formRef.current.reset()
        toastr.error(err?.data?.message);
      });

  }
  const handlePopupReset = () => {
    setFollowUp({
      localbodyId: getLocalbody(),
      userId: getUserId(),
      complaintId: complaintId,
      image: [""],
      status: "",
      comment: "",
    });
    setSelected({
      status: "",
    });
  };
  const handleFilterReset = () => {
    setFilterObject({
      fromDate: "",
      toDate: "",
      wardId: "",
      status: "",
      complaintType: "",
      against: "",
    });
    setFilterSelectObject({
      wardId: "",
      status: "",
      complaintType: "",
      against: "",
    });
  };



  // function fetchAllCategory() {
  //   axios.get(`${API_URL}complaint/category`, {
  //     headers: {
  //       "x-access-token": accessToken
  //     }
  //   }).then((res) => {
  //     let result = res.data.data
  //     let catoption = result.map((item, index) => {
  //       return {
  //         label: item?.category_name,
  //         value: item?._id,
  //       }
  //     })
  //     setcategoryOption(catoption)
  //   })
  // }

  function fetchAllCategory() {
    axios.get(`${API_URL}complaintcategory/list?type=${master.ticketType}&sub_type=${master?.complaintType === "0" ? "2" : "1" || ""}`, {
      headers: {
        "x-access-token": accessToken
      }
    }).then((res) => {
      let result = res.data.data
      let catoption = result.map((item, index) => {
        return {
          label: item?.category_name,
          value: item?._id,
        }
      })
      setcategoryOption(catoption)
    })
  }


  function handleInputChange(e) {
    fetch_all_customers(localbody, e);



  }


  function fetchAllolddata() {
    axios.get(`${API_URL}complaint/customer/edit-old/data?complaintId=${complaintId}`, {
      headers: {
        "x-access-token": accessToken
      }
    }).then((res) => {
      console.log(res, "res ");
      setoldNewData(res.data.data)
    }).catch((err) => {
      console.log(err);
    })
  }

  console.log(oldNewData, "oldand new data");


  useEffect(() => {
    if (complaintId) {
      fetchAllolddata()
    }
  }, [complaintId])

  console.log(complaintId, "complaint id");

  function handleVerifyandApprove() {
    axios.post(`${API_URL}complaint/close-or-reject`, { complaintId: complaintId, userId: getUserId(), status: 2 }, {
      headers: {
        "x-access-token": accessToken
      }
    }).then((res) => {
      toastr.success(res.data.message)
      setOpenModal(false)
      setPopupData([])
    }).catch((err) => {
      toastr.error(err.response.data.message)
    })
  }

  function handleeditReject() {
    axios.post(`${API_URL}complaint/close-or-reject`, { complaintId: complaintId, userId: getUserId(), status: 2 }, {
      headers: {
        "x-access-token": accessToken
      }
    }).then((res) => {
      toastr.success(res.data.message)
      setOpenModal(false)
      setPopupData([])
    }).catch((err) => {
      toastr.error(err.response.data.message)
    })
  }

  console.log(oldNewData, "oldennnw data");



  return (
    <div className="page-content">
      {confirmDeleteAlert ? (
        <SweetAlert
          title=""
          showCancel
          confirmButtonText="Delete"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            axios
              .delete(
                `${API_URL}complaint?userId=${getUserId()}&complaintId=` +
                staffComplaintIdToBeDeleted,
                {
                  headers: {
                    "x-access-token": accessToken,
                  },
                }
              )
              .then((res) => {
                toastr.success(res?.data?.message);
                handleTableData(localbody);
              })
              .catch((err) => {
                toastr.error(err?.data?.message);
                return;
              });
            setConfirmDeleteAlert(false);
          }}
          onCancel={() => setConfirmDeleteAlert(false)}
        >
          Are you sure you want to delete it?
        </SweetAlert>
      ) : null}
      <div className="container-fluid">
        <Breadcrumbs title="Home" breadcrumbItem="Complaint" />
        <Row>
          <Col xl="12">
            <Card>
              <CardBody>
                <AvForm
                  onValidSubmit={handleSubmit}
                  ref={formRef}
                  className="needs-validation"
                >
                  <Row>
                    <Col md="3">
                      <div className="mb-3">
                        <Label>Ward</Label>
                        <Select
                          name="wardId"
                          options={wardOptions}
                          value={selected.wardId || null}
                          onChange={(selected) => {
                            handleSelect("wardId", selected);
                            setErrors({...errors,wardId:null})
                          }}
                          classNamePrefix="select2-selection"
                        />
                        {errors?.wardId && <div><p className="text-danger"
                          style={{ fontSize: "11px" }}>{errors?.wardId}</p></div>}
                      </div>
                    </Col>

                    <Col md="2">
                      <div className="mb-3">
                        <Label>From</Label>
                        <Select
                          name="postingType"
                          options={[{ label: "Customer", value: "1" }, { label: "Staff", value: "2" }]}
                          value={selected.postingType || null}
                          onChange={(selected) => {
                            handleSelect("postingType", selected);
                          }}
                          classNamePrefix="select2-selection"
                        />
                        {errors?.postingType && <div><p className="text-danger"
                          style={{ fontSize: "11px" }}>{errors?.postingType}</p></div>}
                      </div>
                    </Col>
                    {selected?.postingType?.value == "1" ? (
                      <>
                        <Col md="3">
                          <div className="mb-3">
                            <Label>Customer</Label>
                            {/* <Select
                              name="from"
                              options={customerOptions}
                              value={selected.from || null}
                              onChange={(selected) => {
                                handleSelect("from", selected);
                              }}
                              classNamePrefix="select2-selection"
                              onInputChange={handleInputChange}
                            /> */}
                            <CreatableSelect
                              name="from"
                              options={customerOptions}
                              value={selected.from || null}
                              onChange={(selected) => {
                                handleSelect("from", selected);
                              }}
                              classNamePrefix="select2-selection"
                              onInputChange={handleInputChange}
                              isClearable // Optional: to allow clearing the selection
                              placeholder="Select or create a customer"
                            />
                          </div>
                        </Col>
                      </>
                    ) : null}
                    {selected?.postingType?.value == "2" ? (
                      <>
                        <Col md="3">
                          <div className="mb-3">
                            <Label>Staff</Label>
                            <Select
                              name="from"
                              options={staffOptions}
                              value={selected.from || null}
                              onChange={(selected) => {
                                handleSelect("from", selected);
                              }}
                              classNamePrefix="select2-selection"
                            />
                          </div>
                        </Col>
                      </>
                    ) : null}
                    {selected?.postingType ? (
                      <>
                        <Col md="3">
                          <div className="mb-3">
                            <Label>Mobile</Label>
                            <AvField name="frommobile" placeholder="Mobile" value={master?.from?.mobile} onChange={handleValueChange} />
                          </div>
                        </Col>
                      </>
                    ) : null}

                    <Col md="3">
                      <div className="mb-3">
                        <Label>Type</Label>
                        <Select
                          name="ticketType"
                          options={[{ label: "Compliant", value: "1" }, { label: "Request", value: "2" }, { label: "Interaction", value: "3" }]}
                          value={selected.ticketType || null}
                          onChange={(selected) => {
                            handleSelect("ticketType", selected);
                          }}
                          classNamePrefix="select2-selection"
                        />
                         {errors?.ticketType && <div><p className="text-danger"
                          style={{ fontSize: "11px" }}>{errors?.ticketType}</p></div>}
                      </div>
                    </Col>
                    {selected?.ticketType?.value == "1" ? (
                      <>
                        <Col md="3">
                          <div className="mb-3">
                            <Label>Complaint(public/normal)</Label>
                            <Select
                              name="complaintType"
                              options={[{ label: "Public", value: "1" }, { label: "Normal", value: "0" },]}
                              value={selected.complaintType || null}
                              onChange={(selected) => {
                                handleSelect("complaintType", selected);
                              }}
                              classNamePrefix="select2-selection"
                            />
                          </div>
                        </Col>
                      </>
                    ) : null}
                    <Col md="3">
                      <div className="mb-3">
                        <Label>Complaint Category</Label>
                        <Select
                          name="category"
                          options={categoryOption}
                          // options={[{ label: "Misbehaviour", value: "1" }, { label: "No Response", value: "2" }, { label: "Unhygenic", value: "3" }]}
                          value={selected.category || null}
                          onChange={(selected) => {
                            handleSelect("category", selected);
                          }}
                          classNamePrefix="select2-selection"
                        />
                      </div>
                    </Col>
                    {selected?.ticketType?.value == "1" && selected?.complaintType?.value == "0" ? (
                      <>
                        <h6>Complaint againt {selected?.postingType == 1 ? "Customer " : "Staff "}Details</h6>

                        {selected?.postingType?.value == "1" ? (
                          <>
                            <Col md="3">
                              <div className="mb-3">
                                <Label>Staff</Label>
                                <Select
                                  name="against"
                                  options={staffOptions}
                                  value={selected.against || null}
                                  onChange={(selected) => {
                                    handleSelect("against", selected);
                                  }}
                                  classNamePrefix="select2-selection"
                                />
                              </div>
                            </Col>
                          </>
                        ) : null}

                        {selected?.postingType?.value == "2" ? (
                          <>
                            <Col md="3">
                              <div className="mb-3">
                                <Label>Customer</Label>
                                {/* <Select
                                  name="against"
                                  options={customerOptions}
                                  value={selected.against || null}
                                  onChange={(selected) => {
                                    handleSelect("against", selected);
                                  }}
                                  classNamePrefix="select2-selection"
                                /> */}
                                <CreatableSelect
                                  name="against"
                                  options={customerOptions}
                                  value={selected.against || null}
                                  onChange={(selected) => {
                                    handleSelect("against", selected);
                                  }}
                                  classNamePrefix="select2-selection"
                                  onInputChange={handleInputChange}
                                  isClearable // Optional: to allow clearing the selection
                                  placeholder="Select or create a customer"
                                />
                              </div>
                            </Col>
                          </>
                        ) : null}
                        <Col md="3">
                          <div className="mb-3">
                            <Label>Mobile</Label>
                            <AvField
                              name="tomobile"
                              placeholder="Mobile"
                              value={master?.to?.mobile}
                              // readOnly={true}
                              onChange={handleValueChange}
                            />
                          </div>
                        </Col>
                        <Col md="3">
                          <div className="mb-3">
                            <Label>TAT</Label>
                            <AvField
                              name="tat"
                              placeholder="TAT"
                              onChange={handleValueChange}
                              value={master?.tat}
                            />
                          </div>
                        </Col>
                      </>
                    ) : null}

                    <Col md="3">
                      <div className="mb-3">
                        <Label htmlFor="validationCustom03">Comments</Label>
                        <AvField
                          name="comment"
                          type="textarea"
                          className="form-control"
                          placeholder="Comments"
                          rows={1}
                          id="validationCustom03"
                          onChange={handleValueChange}
                          required
                          errorMessage="Comment is required"
                        />
                      </div>
                    </Col>


                    <Col md="3">
                      <div className="mb-3">
                        <Label htmlFor="validationCustom03">Upload Audio</Label>
                        <AvField
                          name="comment "
                          type="file"
                          className="form-control"
                          id="validationCustom03"
                          onChange={handleFileChange}
                          accept="audio/*" // This restricts the file selection to audio files
                        />
                      </div>
                    </Col>
                    {master?.image.map((item, idx) => (
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom03">Image</Label>
                          {item ? (
                            <div div className="img-wraps">
                              <img
                                alt=""
                                width="150"
                                height="150"
                                src={`${API_URL}uploads/complaint_images/${item}`}
                              />

                              <button
                                className="btn btn-danger btn-sm btn-block waves-effect waves-light btn btn-danger"
                                type="button"
                                onClick={(e) => deleteComplaintImage(e, idx)}
                                style={{ width: "150px" }}
                              // type="reset"
                              >
                                Delete
                              </button>
                            </div>
                          ) : (
                            <AvField
                              name="image"
                              type="file"
                              className="form-control"
                              id="validationCustom03"
                              onChange={(e) => uploadImage(e, idx)}
                              rows="1"
                            />
                          )}
                        </div>

                        <div className="d-flex justify-content-end align-items-center">
                          {master?.image?.length - 1 === idx ? (
                            <span
                              onClick={() => {
                                const data = { ...master };
                                data.image.push("");
                                setMaster(data);
                              }}
                              style={{ cursor: "pointer" }}
                            >
                              Add more image{" "}
                              <i className="mdi mdi-plus-circle-outline"></i>
                            </span>
                          ) : (
                            <span
                              onClick={() => {
                                const data = { ...master };
                                data.image.splice(idx, 1);
                                setMaster(data);
                              }}
                              style={{
                                fontSize: "1.125rem",
                                cursor: "pointer",
                              }}
                            >
                              <i className="trash-btn mdi mdi-delete"></i>
                            </span>
                          )}
                        </div>
                      </Col>
                    ))}
                    <Col>
                      <div className="mb-3" style={{ paddingTop: "25px" }}>
                        <Button
                          className="me-2"
                          color={master._id ? "warning" : "primary"}
                          type="submit"
                        >
                          {master._id ? "Update" : "Submit"}
                        </Button>
                        <Button
                          onClick={handleReset}
                          color="danger"
                          type="button"
                        >
                          Reset
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </AvForm>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <AvForm>
            <Row>
              <Col md={2} className="mb-3">
                <Label>From</Label>
                <AvField
                  name="fromDate"
                  type="date"
                  onChange={(e) =>
                    handleFilterValueChange("fromDate", e.target.value)
                  }
                  value={filterObject.fromDate}
                />
              </Col>
              <Col md={2} className="mb-3">
                <Label>To </Label>
                <AvField
                  name="toDate"
                  type="date"
                  onChange={(e) =>
                    handleFilterValueChange("toDate", e.target.value)
                  }
                  value={filterObject.toDate}
                />
              </Col>
              {/* <Col md="2">
                <div className="mb-3">
                  <Label htmlFor="validationCustom01">Type</Label>
                  <Select
                    name="complaintType"
                    value={filterSelectObject?.complaintType}
                    onChange={(selected) => {
                      handleFilterSelectValueChange("complaintType", selected);
                    }}
                    options={[
                      {
                        label: "Normal",
                        value: 0,
                      },
                      {
                        label: "Public",
                        value: 1,
                      },

                    ]}
                    classNamePrefix="select2-selection"
                  />
                </div>
              </Col> */}
              <Col md="2">
                <div className="mb-3">
                  <Label>Ward</Label>
                  <Select
                    name="wardId"
                    options={wardOptions}
                    value={filterSelectObject?.wardId}
                    onChange={(selected) => {
                      handleFilterSelectValueChange("wardId", selected);
                    }}
                    classNamePrefix="select2-selection"
                  />
                </div>
              </Col>
              <Col md="2">
                <div className="mb-3">
                  <Label>Complaint Type</Label>
                  <Select
                    name="ticketType"
                    value={filterSelectObject?.ticketType}
                    onChange={(selected) => {
                      handleFilterSelectValueChange("ticketType", selected);
                    }}
                    options={[
                      {
                        label: "Complaint",
                        value: 1,
                      },
                      {
                        label: "Request",
                        value: 2,
                      },
                      {
                        label: "Interaction",
                        value: 3,
                      },

                    ]}
                    classNamePrefix="select2-selection"
                  />
                </div>
              </Col>
              <Col md="2">
                            <div className="mb-3">
                                <Label>Sub Type</Label>
                                <Select
                                    name="complaintType"
                                    value={filterSelectObject?.complaintType}
                                    onChange={(selected) => {
                                        handleFilterSelectValueChange("complaintType", selected);
                                    }}
                                    options={[
                                        {
                                            label: "Normal",
                                            value: 0,
                                        },
                                        {
                                            label: "Public",
                                            value: 1,
                                        },
                                        {
                                            label: "No-waste",
                                            value: 2,
                                        },
                                        {
                                            label: "Customer Edit",
                                            value: 3,
                                        },

                                    ]}
                                    classNamePrefix="select2-selection"
                                />
                            </div>
                        </Col>
              <Col md="2">
                <div className="mb-3">
                  <Label>From</Label>
                  <Select
                    name="postingType"
                    value={filterSelectObject?.postingType}
                    onChange={(selected) => {
                      handleFilterSelectValueChange("postingType", selected);
                    }}
                    options={[
                      {
                        label: "Staff",
                        value: 2,
                      },
                      {
                        label: "Customer",
                        value: 1,
                      },
                    ]}
                    classNamePrefix="select2-selection"
                  />
                </div>
              </Col>
              <Col md="2">
                <div className="mb-3">
                  <Label>Status</Label>
                  <Select
                    name="crntStatus"
                    value={filterSelectObject?.crntStatus}
                    onChange={(selected) => {
                      handleFilterSelectValueChange("crntStatus", selected);
                    }}
                    options={[
                      {
                        label: "Active",
                        value: 0,
                      },
                      {
                        label: "Rejected",
                        value: 3,
                      },
                      {
                        label: "Escalated",
                        value: 1,
                      },
                      {
                        label: "Closed",
                        value: 2,
                      },

                    ]}
                    classNamePrefix="select2-selection"
                  />
                </div>
              </Col>
              <Col style={{ paddingTop: "5px" }} md={2} >
                <Button
                  color="danger"
                  onClick={handleFilterReset}
                  type="button"
                  className="mt-4"

                >
                  Reset
                </Button>
              </Col>
            </Row>
          </AvForm>
          <MDBDataTable
            id="complainstId"
            data={data}
            sortable={false}
            searching={false}
            displayEntries={false}
            paging={false}
            disableRetreatAfterSorting={true}
            bordered
            responsive
            entries={20}
          />
          <div class="row align-items-center">
            <div
              dataTest="datatable-info"
              className="col-sm-12 col-md-5"
            >
              <div
                className="dataTables_info"
                role="status"
                ariaLive="polite"
              >
                Showing {(page - 1) * limit + 1} to{" "}
                {Math.min(page * limit, count)} of {count} entries
              </div>
            </div>
            <div
              data-test="datatable-pagination"
              className="col-sm-12 col-md-7"
            >
              <div className="dataTables_paginate">
                <Pagination
                  page={page}
                  count={Math.ceil(count / limit)}
                  shape="rounded"
                  size="small"
                  onChange={handlePageChange}
                />
              </div>
            </div>
          </div>
        </Row>
      </div>
      <Modal
        show={openModal}
        toggle={handleCloseModal}
        size="xl"
        centered={true}
      >
        <div className="modal-header">
          {/* <h5 className="modal-title mt-0">Complaint </h5> */}
          <button
            type="button"
            onClick={() => {
              setOpenModal(false);
              setFollowUp(followupState);
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        {/* <AvForm className="needs-validation"> */}
        <div className="modal-body">
          <div class="row">
            <div class="col-md-12">
              <ul className="lead-details">
                <li>
                  Date :
                  <label class="form-label">
                    {" "}
                    {moment(popupData?.date).format("DD-MM-YYYY")}
                  </label>
                </li>
                <li>
                  Ward :<label class="form-label"> {popupData.ward}</label>
                </li>
                <li>
                  Type :
                  <label class="form-label">
                    {" "}
                    {popupData?.ticketType === 1
                      ? "Complaint"
                      : popupData?.ticketType === 3
                        ? "Interaction"
                        : popupData?.ticketType === 2
                          ? "Request"
                          : ""}
                  </label>
                </li>
                <li>
                  Category :
                  <label class="form-label"> {popupData?.complaintType === 0
                    ? "Normal"
                    : popupData?.complaintType === 1
                      ? "Public"
                      : popupData?.complaintType === 2
                        ? "No Waste"
                        : ""}</label>
                </li>


                <li>
                  From :
                  <label class="form-label">
                    {popupData?.from?.name}
                  </label>
                </li>
                <li>
                  Mobile :
                  <label class="form-label">
                    {" "}
                    {popupData?.from?.mobile}
                  </label>
                </li>
                <li>
                  To :
                  <label class="form-label">
                    {popupData?.to?.name}
                  </label>
                </li>
                <li>
                  Mobile :
                  <label class="form-label">
                    {" "}
                    {popupData?.to?.mobile}
                  </label>
                </li>
                <li>
                  TAT :<label class="form-label"> {popupData?.tatTime}</label>
                </li>
                <li>
                  Comments :
                  <label class="form-label"> {popupData?.comment || "--"}</label>
                </li>
                <li>
                  Current Status :
                  <label class="form-label">
                    {" "}
                    {popupData?.crntStatus === 0
                      ? "Active"
                      : popupData?.crntStatus === 1
                        ? "Escalated"
                        : popupData?.crntStatus === 2
                          ? "Closed"
                          : popupData?.crntStatus === 3
                            ? "Rejected"
                            : ""}
                  </label>
                </li>
                <li>
                  Assigned To :
                  <label class="form-label"> {popupData?.assign}</label>
                </li>
                {popupData?.closed &&
                  Object.keys(popupData?.closed).length > 0 && (
                    <>
                      <li>
                        Closed By:
                        <label className="form-label">
                          {" "}
                          {popupData?.closed?.by}
                        </label>
                      </li>
                      <li>
                        Closed Date & Time:
                        <label className="form-label">
                          {`${moment(popupData?.closed?.date).format(
                            "DD-MM-YYYY"
                          )} ${moment(popupData?.closed?.time, "HHmmss").format(
                            "hh:mm a"
                          )}`}
                        </label>
                      </li>
                    </>
                  )}
              </ul>
            </div>
            <div class="col-md-12">
              <ul className="lead-details">
                {popupData?.image?.filter((item) => item).length > 0 && (
                  <li>
                    Image :
                    <label className="form-label">
                      {popupData?.image
                        ?.filter((item) => item)
                        .map((item, index) => (
                          <img
                            key={index}
                            className="me-2"
                            style={{ width: "100px", height: "100px" }}
                            src={`${API_URL}uploads/complaint_images/${item}`}
                            alt={`Image ${index}`}
                          />
                        ))}
                    </label>
                  </li>
                )}
                {popupData?.audio && (
                  <li>
                    Audio :
                    <label class="form-label">
                      <audio controls style={{ height: "35px" }}>
                        <source
                          src={`${API_URL}uploads/complaintaudio/${popupData.audio}`}
                          target="_blank"
                          type="audio/mpeg"
                        />
                        {/* <source
                              src={`https://nodeapi.nellikkastore.com/uploads/complaintaudio/20240902154453_sample-3s.mp3`}
                              type="audio/mpeg"
                            /> */}
                        Your browser does not support the audio element.
                      </audio>
                    </label>
                  </li>
                )}
              </ul>
            </div>
          </div>
        </div>
        {popupData?.ticketType != "3" ? (
          <>

            <Col className="col-12">
              {popupData?.ticketType == "2" && popupData?.complaintType == "3" && popupData?.postingType == "2" ? (
                <>
                  <Row>
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
                      <Col md="2">
                        {popupData?.crntStatus == "0" ? (
                          <>
                            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                              <Button onClick={handleVerifyandApprove} type="" color="primary">Verify&Approve</Button>
                              <Button className="mx-2" onClick={handleeditReject} type="" color="danger">Reject</Button>
                            </div>
                          </>
                        ) : null}
                        {popupData?.crntStatus == "2" ? (
                          <>
                            <Button type="" color="success">Approved</Button>
                          </>
                        ) : null}
                        {popupData?.crntStatus == "3" ? (
                          <>
                            <Button type="" color="danger">Rejected</Button>
                          </>
                        ) : null}
                      </Col>
                    </div>
                  </Row>
                  <Row style={{ paddingLeft: "15px", paddingRight: "15px" }}>
                    <Col md="12" >
                      <h5></h5>
                      <table className=" table table-responsive">
                        <thead>
                          <tr>
                            <th style={{ textAlign: "left" }}>Field Name</th>
                            <th style={{ textAlign: "left" }}>Old Data</th>
                            <th style={{ textAlign: "left" }}>Edited Data</th>
                          </tr>
                        </thead>
                        <tbody>
                          {oldNewData?.newData?.cust_address ? (
                            <tr>
                              <td>Address</td>
                              <td>{oldNewData?.oldData?.cust_address}</td>
                              <td>{oldNewData?.newData?.cust_address}</td>
                            </tr>
                          ) : null}
                          {oldNewData?.newData?.cust_billing_type ? (
                            <tr>
                              <td>Billing Type</td>
                              <td>{oldNewData?.oldData?.cust_billing_type?.name}</td>
                              <td>{oldNewData?.newData?.cust_billing_type?.name}</td>
                            </tr>
                          ) : null}
                          {oldNewData?.newData?.cust_email ? (
                            <tr>
                              <td>Email</td>
                              <td>{oldNewData?.oldData?.cust_email}</td>
                              <td>{oldNewData?.newData?.cust_email}</td>
                            </tr>
                          ) : null}
                          {oldNewData?.newData?.cust_designation ? (
                            <tr>
                              <td>Designation</td>
                              <td>{oldNewData?.oldData?.cust_designation?.desig_name}</td>
                              <td>{oldNewData?.newData?.cust_designation?.desig_name}</td>
                            </tr>
                          ) : null}
                          {oldNewData?.newData?.cust_group_id ? (
                            <tr>
                              <td>Group</td>
                              <td>{oldNewData?.oldData?.cust_group_id[0]?.group_name}</td>
                              <td>{oldNewData?.newData?.cust_group_id[0]?.group_name}</td>
                            </tr>
                          ) : null}

                          {oldNewData?.newData?.cust_house_num ? (
                            <tr>
                              <td>House . No</td>
                              <td>{oldNewData?.oldData?.cust_house_num}</td>
                              <td>{oldNewData?.newData?.cust_house_num}</td>
                            </tr>
                          ) : null}
                          {oldNewData?.newData?.cust_landline_no ? (
                            <tr>
                              <td>landline No</td>
                              <td>{oldNewData?.oldData?.cust_landline_no}</td>
                              <td>{oldNewData?.newData?.cust_landline_no}</td>
                            </tr>
                          ) : null}

                          {oldNewData?.newData?.cust_name ? (
                            <tr>
                              <td>Name </td>
                              <td>{oldNewData?.oldData?.cust_name}</td>
                              <td>{oldNewData?.newData?.cust_name}</td>
                            </tr>
                          ) : null}
                          {oldNewData?.newData?.cust_no_members ? (
                            <tr>
                              <td>Members </td>
                              <td>{oldNewData?.oldData?.cust_no_members}</td>
                              <td>{oldNewData?.newData?.cust_no_members}</td>
                            </tr>
                          ) : null}
                          {oldNewData?.newData?.cust_package_id ? (
                            <tr>
                              <td>Package </td>
                              <td>{oldNewData?.oldData?.cust_package_id?.package_name}</td>
                              <td>{oldNewData?.newData?.cust_package_id?.package_name}</td>
                            </tr>
                          ) : null}
                          {oldNewData?.newData?.cust_type ? (
                            <tr>
                              <td>Type </td>
                              <td>{oldNewData?.oldData?.cust_type?.customer_type_name}</td>
                              <td>{oldNewData?.newData?.cust_type?.customer_type_name}</td>
                            </tr>
                          ) : null}
                          {oldNewData?.newData?.cust_whatsapp_no ? (
                            <tr>
                              <td>Whatsapp Number</td>
                              <td>{oldNewData?.oldData?.cust_whatsapp_no}</td>
                              <td>{oldNewData?.newData?.cust_whatsapp_no}</td>
                            </tr>
                          ) : null}
                          {oldNewData?.newData?.district ? (
                            <tr>
                              <td>District</td>
                              <td>{oldNewData?.oldData?.district?.district_name}</td>
                              <td>{oldNewData?.newData?.district?.district_name}</td>
                            </tr>
                          ) : null}

                          {oldNewData?.newData?.houseowner_name ? (
                            <tr>
                              <td>District</td>
                              <td>{oldNewData?.oldData?.houseowner_name}</td>
                              <td>{oldNewData?.newData?.houseowner_name}</td>
                            </tr>
                          ) : null}
                          {oldNewData?.newData?.shop_name ? (
                            <tr>
                              <td>District</td>
                              <td>{oldNewData?.oldData?.shop_name}</td>
                              <td>{oldNewData?.newData?.shop_name}</td>
                            </tr>
                          ) : null}
                          {oldNewData?.newData?.ward ? (
                            <tr>
                              <td>Ward</td>
                              <td>{oldNewData?.oldData?.ward?.ward_name}</td>
                              <td>{oldNewData?.newData?.ward?.ward_name}</td>
                            </tr>
                          ) : null}
                        </tbody>
                      </table>
                    </Col>
                  </Row>


                </>
              ) : <>
                <Row>
                  {!(popupData?.crntStatus == "2") && (
                    <AvForm ref={PopUpRef} onValidSubmit={handlePopUpSubmit}>
                      <Row style={{ paddingLeft: "15px" }}>
                        <h5>Followup</h5>
                        <Col md="2">
                          <div className="mb-3">
                            <Label>Status</Label>
                            <Select
                              name="crntStatus"
                              value={selected.crntStatus || ""}
                              onChange={(selected) => {
                                handlePopUpSelect("crntStatus", selected);
                              }}
                              options={[
                                // {
                                //   label: "Active",
                                //   value: 0,
                                // },
                                {
                                  label: "Escalated",
                                  value: 1,
                                },
                                {
                                  label: "Closed",
                                  value: 2,
                                },
                              ]}
                              classNamePrefix="select2-selection"
                            />
                            <p
                              className="text-danger"
                              style={{ fontSize: "11px" }}
                            ></p>
                          </div>
                        </Col>

                        {followUp.crntStatus == 1 && (
                          <Col md="3">
                            <div className="mb-3">
                              <Label>Assign Staff</Label>
                              <Select
                                name="assignTo"
                                options={assaignedStaff}
                                value={selected.assignTo || ""}
                                onChange={(selected) => {
                                  handlePopUpSelect("assignTo", selected);
                                }}
                                classNamePrefix="select2-selection"
                              />
                            </div>
                          </Col>
                        )}

                        {/* {followUp.status === 1 ? null : (
                        <>
                          <Col md="3">
                            <div className="mb-3">
                              <Label htmlFor="validationCustom03">
                                Upload Audio
                              </Label>
                              <AvField
                                name="complaint_audio"
                                type="file"
                                className="form-control"
                                id="validationCustom03"
                                onChange={handleFileChange}
                                accept="audio/*" // This restricts the file selection to audio files
                              />
                            </div>
                          </Col>
                          {followUp?.image.map((item, idx) => (
                            <Col md="3">
                              <div className="mb-3">
                                <Label htmlFor="validationCustom03">Image</Label>
                                {item ? (
                                  <div div className="img-wraps">
                                    <img
                                      alt=""
                                      width="150"
                                      height="150"
                                      src={`${API_URL}uploads/complaint_images/${item}`}
                                    />

                                    <button
                                      className="btn btn-danger btn-sm btn-block waves-effect waves-light btn btn-danger"
                                      type="button"
                                      onClick={(e) => deleteComplaintImage(e, idx)}
                                      style={{ width: "150px" }}
                                    // type="reset"
                                    >
                                      Delete
                                    </button>
                                  </div>
                                ) : (
                                  <AvField
                                    name="popupimage"
                                    type="file"
                                    className="form-control"
                                    id="validationCustom03"
                                    onChange={(e) => uploadImage(e, idx)}
                                    rows="1"
                                  />
                                )}
                              </div>

                              <div className="d-flex justify-content-end align-items-center">
                                {followUp?.image?.length - 1 === idx ? (
                                  <span
                                    onClick={() => {
                                      const data = { ...followUp };
                                      data.image.push("");
                                      setFollowUp(data);
                                    }}
                                    style={{ cursor: "pointer" }}
                                  >
                                    Add more image{" "}
                                    <i className="mdi mdi-plus-circle-outline"></i>
                                  </span>
                                ) : (
                                  <span
                                    onClick={() => {
                                      const data = { ...followUp };
                                      data.image.splice(idx, 1);
                                      setFollowUp(data);
                                    }}
                                    style={{
                                      fontSize: "1.125rem",
                                      cursor: "pointer",
                                    }}
                                  >
                                    <i className="trash-btn mdi mdi-delete"></i>
                                  </span>
                                )}
                              </div>
                            </Col>
                          ))}
                         
                        </>
                      )} */}
                        <Col md="3">
                          <div className="mb-3">
                            <Label>Comments</Label>
                            <AvField
                              name="comment"
                              placeholder="Comments"
                              type="textarea"
                              value={followUp.comment}
                              onChange={(e) => {
                                handlePopUpValue(e.target.name, e.target.value);
                              }}
                              rows={1}
                              className="form-control"
                            />
                          </div>
                        </Col>

                        <Col md="3" style={{ paddingTop: "25px" }}>
                          <Button color="primary" className="me-2" type="submit">
                            Submit
                          </Button>
                          <Button
                            onClick={handlePopupReset}
                            color="danger"
                            className="me-2"
                            type="reset"
                          >
                            {"Reset"}
                          </Button>
                        </Col>
                      </Row>
                    </AvForm>
                  )}
                </Row>
              </>}
              <h6>Followup Details</h6>
              <Row
                style={{ paddingLeft: "15px", paddingRight: "15px" }}
                className="mt-2"
              >
                <MDBDataTable
                  id="complaintPopupTableDataId"
                  responsive
                  bordered
                  data={complaintTableData}
                  paging={false}
                  searching={false}
                />
              </Row>
            </Col>
          </>
        ) : null}
        {/* </AvForm> */}
      </Modal>
    </div>
  );
};

export default Complaints; 
