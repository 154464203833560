import React, { useState } from 'react'
import ReactECharts from 'echarts-for-react';
import { Col, Label, Row } from 'reactstrap';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ReactApexChart from "react-apexcharts";
import Select from "react-select";
import { AvField, AvForm } from "availity-reactstrap-validation";

const WarehouseDashboard = () => {
    const [alignment, setAlignment] = React.useState('item');
    const handleChange = (event, newAlignment) => {
        setAlignment(newAlignment);
    };

    // const [chartData] = useState({
    //     series: [
    //       {
    //         name: "Total Weight",
    //         data: [200, 150, 156, 102, 184, 210, 200],
    //       },
    //       {
    //         name: "Total Bag",
    //         data: [11, 32, 45, 32, 34, 52, 41],
    //       },
    //       {
    //         name: "Total Trips",
    //         data: [30, 23, 22, 15, 10, 12, 20],
    //       },
    //       {
    //         name: "Plastics",
    //         data: [20, 5, 100, 15, 100, 50, 20],
    //       },
    //       {
    //         name: "Bottles",
    //         data: [110, 32, 20, 2, 84, 152, 41],
    //       },
    //     ],
    //     options: {
    //       chart: {
    //         height: 350,
    //         type: "area",
    //       },
    //       dataLabels: {
    //         enabled: false,
    //       },
    //       stroke: {
    //         curve: "smooth",
    //       },
    //       xaxis: {
    //         type: "datetime",
    //         categories: [
    //           "2018-09-19T00:00:00.000Z",
    //           "2018-09-19T01:30:00.000Z",
    //           "2018-09-19T02:30:00.000Z",
    //           "2018-09-19T03:30:00.000Z",
    //           "2018-09-19T04:30:00.000Z",
    //           "2018-09-19T05:30:00.000Z",
    //           "2018-09-19T06:30:00.000Z",
    //         ],
    //       },
    //       tooltip: {
    //         x: {
    //           format: "dd/MM/yy HH:mm",
    //         },
    //       },
    //     },
    //   });

    const [chartData] = useState({
        series: [
            {
                name: "Total Weight",
                data: [200, 150, 156, 102, 184, 210, 200],
            },
            {
                name: "Total Bag",
                data: [11, 32, 45, 32, 34, 52, 41],
            },
            {
                name: "Total Trips",
                data: [30, 23, 22, 15, 10, 12, 20],
            },
            {
                name: "Plastics",
                data: [20, 5, 100, 15, 100, 50, 20],
            },
            {
                name: "Bottles",
                data: [110, 32, 20, 2, 84, 152, 41],
            },
        ],
        options: {
            chart: {
                height: 350,
                type: "area",
            },
            colors: ["#FF5733", "#33FF57", "#3357FF", "#FF33A8", "#FFC300"], // Custom colors for each series
            dataLabels: {
                enabled: false,
            },
            stroke: {
                curve: "smooth",
            },
            xaxis: {
                type: "datetime",
                categories: [
                    "2024-11-01T00:00:00.000Z",
                    "2024-11-02T00:00:00.000Z",
                    "2024-11-03T00:00:00.000Z",
                    "2024-11-04T00:00:00.000Z",
                    "2024-11-05T00:00:00.000Z",
                    "2024-11-06T00:00:00.000Z",
                    "2024-11-07T00:00:00.000Z",
                ], // Custom dates for the X-axis
                labels: {
                    format: "dd MMM yyyy", // Formats for the date labels
                },
            },
            tooltip: {
                x: {
                    format: "dd MMM yyyy", // Tooltip date format
                },
            },
        },
    });
    const options = {
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'shadow', // 'shadow' as default; can also be 'line' or 'shadow'
            },
        },
        legend: {},
        grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true,
        },
        xAxis: {
            type: 'value',
        },
        yAxis: {
            type: 'category',
            data: ['Mini-warehouse1', 'Mini-warehouse2', 'Mini-warehouse3', 'Mini-warehouse4', 'Mini-warehouse5', 'Mini-warehouse6', 'Mini-warehouse7'],
        },
        series: [
            {
                name: 'Plastic',
                type: 'bar',
                stack: 'total',
                label: {
                    show: true,
                },
                emphasis: {
                    focus: 'series',
                },
                itemStyle: {
                    color: '#64b946', // Example color
                },
                data: [320, 302, 301, 334, 657, 330, 320],
            },
            {
                name: 'Bottle',
                type: 'bar',
                stack: 'total',
                label: {
                    show: true,
                },
                emphasis: {
                    focus: 'series',
                },
                itemStyle: {
                    color: '#2b4b20', // Example color
                },
                data: [120, 132, 101, 134, 90, 230, 210],
            },
            {
                name: 'Paper',
                type: 'bar',
                stack: 'total',
                label: {
                    show: true,
                },
                emphasis: {
                    focus: 'series',
                },
                itemStyle: {
                    color: '#6a7a64', // Example color
                },
                data: [220, 182, 191, 234, 290, 330, 310],
            },
            {
                name: 'test',
                type: 'bar',
                stack: 'total',
                label: {
                    show: true,
                },
                emphasis: {
                    focus: 'series',
                },
                itemStyle: {
                    color: '#c8d3c4', // Example color
                },
                data: [150, 212, 201, 154, 190, 330, 410],
            },
            {
                name: 'test 2',
                type: 'bar',
                stack: 'total',
                label: {
                    show: true,
                },
                emphasis: {
                    focus: 'series',
                },
                itemStyle: {
                    color: '#678e67', // Example color
                },
                data: [820, 832, 901, 934, 1290, 1330, 1320],
            },
            {
                name: 'test 3',
                type: 'bar',
                stack: 'total',
                label: {
                    show: true,
                },
                emphasis: {
                    focus: 'series',
                },
                itemStyle: {
                    color: '#79e079', // Example color
                },
                data: [320, null, null, null, null, 330, 320],
            },
        ],
    };


    // volume chart

    const warehouses = [
        { name: 'Mini-warehouse1', maxVolume: 1000, filledVolume: 320 },
        { name: 'Mini-warehouse2', maxVolume: 1000, filledVolume: 302 },
        { name: 'Mini-warehouse3', maxVolume: 1000, filledVolume: 301 },
        { name: 'Mini-warehouse4', maxVolume: 500, filledVolume: 334 },
        { name: 'Mini-warehouse5', maxVolume: 700, filledVolume: 657 },
        { name: 'Mini-warehouse6', maxVolume: 1000, filledVolume: 330 },
        { name: 'Mini-warehouse7', maxVolume: 800, filledVolume: 320 },
    ];

    // Extract data for the chart
    const warehouseNames = warehouses.map((w) => w.name);
    const filledVolumes = warehouses.map((w) => w.filledVolume);
    const remainingVolumes = warehouses.map((w) => w.maxVolume - w.filledVolume);

    const options1 = {
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'shadow',
            },
            formatter: (params) => {
                let tooltipContent = `${params[0].axisValue}<br>`;
                params.forEach((item) => {
                    tooltipContent += `${item.marker} ${item.seriesName}: ${item.value}<br>`;
                });
                return tooltipContent;
            },
        },
        legend: {
            data: ['Filled Volume', 'Remaining Volume'],
        },
        grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true,
        },
        xAxis: {
            type: 'value',
            name: 'Volume',
        },
        yAxis: {
            type: 'category',
            data: warehouseNames,
        },
        series: [
            {
                name: 'Filled Volume',
                type: 'bar',
                stack: 'total',
                label: {
                    show: true,
                    formatter: '{c}', // Display the value inside the bar
                },
                itemStyle: {
                    color: '#64b946', // Filled volume color
                },
                data: filledVolumes,
            },
            {
                name: 'Remaining Volume',
                type: 'bar',
                stack: 'total',
                label: {
                    show: true,
                    formatter: '{c}', // Display the value inside the bar
                },
                itemStyle: {
                    color: '#c8d3c4', // Remaining volume color
                },
                data: remainingVolumes,
            },
        ],
    };

    const optionpi1 = {
        title: {
            //   text: 'Main ware house 1',
            subtext: 'Ware house details',
            left: 'center'
        },
        tooltip: {
            trigger: 'item'
        },
        legend: {
            orient: 'vertical',
            left: 'left'
        },
        series: [
            {
                // name: 'Access From',
                type: 'pie',
                radius: '50%',
                data: [
                    { value: 1048, name: 'Bailed' },
                    { value: 735, name: 'Billed' },
                    { value: 580, name: 'Disputed' },
                    { value: 484, name: 'Verified' },
                    { value: 300, name: 'Dispatched' }
                ],
                emphasis: {
                    itemStyle: {
                        shadowBlur: 10,
                        shadowOffsetX: 0,
                        shadowColor: 'rgba(0, 0, 0, 0.5)'
                    }
                }
            }
        ]
    };


    const optionpi2 = {
        title: {
            text: 'Main Ware House Trip Details',
            subtext: 'Total Trip - 1500' ,
            left: 'center'
        },
        tooltip: {
            trigger: 'item',
            formatter: '{a} <br/>{b}: {c} ({d}%)' // Tooltip displays name, value, and percentage
        },
        legend: {
            orient: 'vertical',
            left: 'left'
        },
        series: [
            {
                name: 'Trip Details',
                type: 'pie',
                radius: '50%',
                data: [
                    { value: 1048, name: 'Plastic' },
                    { value: 735, name: 'Bottle' },
                    { value: 580, name: 'Thermocol' },
                    { value: 484, name: 'Cardboard' },
                    { value: 300, name: 'E-waste' }
                ],
                label: {
                    show: true,
                    formatter: '{b}: {c} kg' // Display name and value on the chart
                },
                itemStyle: {
                    // Add specific colors for each slice
                    color: function(params) {
                        const colorList = ['#264653', '#2A9D8F', '#E9C46A', '#F4A261', '#E76F51']; // Array of colors
                        return colorList[params.dataIndex]; // Assign color based on data index
                    }
                },
                emphasis: {
                    itemStyle: {
                        shadowBlur: 10,
                        shadowOffsetX: 0,
                        shadowColor: 'rgba(0, 0, 0, 0.5)'
                    }
                }
            }
        ]
    };






    return (
        <>
            <div className='page-content'>
                <div className='container-fluid'>
                    <Row>
                        <ToggleButtonGroup
                            color="primary"
                            value={alignment}
                            exclusive
                            onChange={handleChange}
                            aria-label="Platform"
                        >
                            <ToggleButton value="item">Item Graph</ToggleButton>
                            <ToggleButton value="volume">Volume Graph</ToggleButton>
                        </ToggleButtonGroup>
                    </Row>
                    {alignment == "item" ? (
                        <>
                            <Row key={alignment}>
                                <ReactECharts option={options} style={{ height: 400, width: '100%' }} />
                            </Row>
                        </>
                    ) : <>
                        <Row key={alignment}>
                            <ReactECharts option={options1} style={{ height: 400, width: '100%' }} />
                        </Row>
                    </>}
                    <h3 className='mt-4' style={{ textAlign: "center" }}>Main ware house details</h3>
                    <Row className='mt-4'>
                        <Col md="6">
                            <h5>Main ware house 1</h5>
                            <ReactECharts option={optionpi1} style={{ height: 400, width: '100%' }} />
                        </Col>

                        <Col md="6">
                            <h5>Main ware house 2</h5>
                            <ReactECharts option={optionpi1} style={{ height: 400, width: '100%' }} />
                        </Col>

                    </Row>
                    <h3 className='mt-4' style={{ textAlign: "center" }}>Main ware House Details</h3>
                    <Row className='mt-4' >
                        <ReactApexChart
                            options={chartData.options}
                            series={chartData.series}
                            type="area"
                            height={350}
                        />

                    </Row>

                    {/* <h3 className='mt-4' style={{ textAlign: "center" }}>Main ware House Trip Details</h3> */}
                    <Row className='mt-4' >
                        <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }} >
                            <div style={{ width: "250px", }}>
                                <div>
                                     <Label>Select Ware House</Label>
                                <Select value={{label:"Main Ware House 1",value:"2"}} options={[{label:"Main Ware House 1",value:"1"},{label:"Main Ware House 2",value:"2"}]} />

                                </div>
                               

                            </div>

                        </div>


                        <ReactECharts option={optionpi2} style={{ height: 500, width: '100%' }} />
                    </Row>

                </div>
            </div>

        </>
    )
}

export default WarehouseDashboard