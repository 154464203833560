import React, { useState, useEffect } from "react";
import { MDBDataTable } from "mdbreact";
import { Row, Col, Card, CardBody, Button, Label } from "reactstrap";
import Select from "react-select";
import $ from "jquery";
import axios from "axios";
import accessToken from "../../../helpers/jwt-token-access/accessToken";
import { apiError } from "../../../store/actions";
import moment from "moment";
// Redux
import { connect } from "react-redux";
import { withRouter, useHistory } from "react-router-dom";
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import {
  getLocalbody,
  getFirstday,
  getDate,
} from "../../../helpers/globalFunctions";
import "./style.scss";
import qs from 'querystring'
import DataTable from "../../../components/Common/DataTable";
const TransactionLog = (props) => {
  const { chartId } = props.match.params;
  const [transationLogList, setTransactionLogList] = useState([]);
  const [selectedTransationType, setSelectedTransationType] = useState(null);
  const [currentLocalbody, setCurrectLocalbody] = useState(getLocalbody());
  const [master, setMaster] = useState({
    from_date: getFirstday(new Date()),
    to_date: getDate(new Date()),
  });
  const [chartOfAccOption, setChartOfAccOption] = useState([]);
  const [selectedChart, setSelectedChart] = useState(null);

  const [page, setPage] = useState(1)
  const [count, setCount] = useState(0)
  const [limit, setLimit] = useState(20)

  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";
  let history = useHistory();
  const handleSelectedTransactionType = (selected) => {

    setSelectedTransationType(selected);
  };
  useEffect(() => {
    if (chartId) {
      fetch_all_accounts(currentLocalbody);
    } else {
      fetch_all_accounts(currentLocalbody);
    }
  }, [currentLocalbody]);

  useEffect(()=>{
    handleTableData(master);
  },[currentLocalbody,page,limit])

  let handleDate = (e) => {
    var date1 = $("#from_date").val();
    var date2 = $("#to_date").val();

    //date validation
    let toDate = moment(date1);
    let fromDate = moment(date2);
    let result = fromDate.diff(toDate, "days");

    if (result + 1 <= 0) {
      setMaster({
        ...master,
        [e.target.name]: e.target.value,
        ["to_date"]: "",
      });
      date2 = "";
    } else {
      setMaster({
        ...master,
        [e.target.name]: e.target.value,
      });
    }
  };
  const reset = () => {
    setMaster({
      from_date: getFirstday(new Date()),
      to_date: getDate(new Date()),
    });
    setSelectedTransationType(null);
    setSelectedChart(null);
    handleTableData(master);
    if (chartId) {
      history.push("/transaction-log");
    }
  };
  function handleTableData(query) {
    let url = `${API_URL}transaction_log?localbody_id=${currentLocalbody}&${qs.stringify(query)}&page=${page}&limit=${limit}`;

    axios
      .get(url, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var result = res.data.data;
        setCount(res.data.count)
        let Data = [];
        result.map((item, index) => {
          if (item.transaction_log_type == 0) {
            item.n_Credit = parseFloat(item.transaction_log_amount);
          } else item.n_Debit = parseFloat(item.transaction_log_amount);

          item.amount_column = parseFloat(
            item.transaction_log_fundsource_crntamt
          ).toFixed(2);
          item.id = index + 1;
          item.transaction_log_date = moment(item.transaction_log_date).format("DD-MM-YYYY");
          item.time = moment(item.transaction_log_time, "HHmmss").format("hh:mm a");
          if (item?.lname) item.user = item?.fname + " " + item.lname;
          else item.user = item?.fname;
          Data.push(item);
        });
        setTransactionLogList(Data);
      });
  }
  const fetch_all_accounts = (id) => {
    axios
      .get(`${API_URL}transaction_log/accounts?localbody_id=` + id, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let account_data =
          res.data.data &&
          res.data.data.map((element) => {
            return {
              label: element.acc_chart_name,
              value: element._id,
            };
          });
        if (chartId) {
          // let type = "";
          // let date1 = "";
          // let date2 = "";
          account_data &&
            account_data.map((el) => {
              if (el.value === chartId) {
                setSelectedChart(el);
                // handleTableData(type, date1, date2, chartId);
              }
            });
        }
        setChartOfAccOption([
          {
            options: account_data,
          },
        ]);
      });
  };
  
  const handleSelectChange = (selected) => {
    setSelectedChart(selected);
  };

  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 150,
      },
      {
        label: "Date",
        field: "transaction_log_date",
        sort: "asc",
        width: 400,
      },
      {
        label: "Time",
        field: "time",
        sort: "asc",
        width: 200,
      },
      {
        label: "Type",
        field: "acc_type_name",
        sort: "asc",
        width: 200,
      },
      {
        label: "Account",
        field: "acc_chart_name",
        sort: "asc",
        width: 200,
      },
      {
        label: "Ref. ID",
        field: "log_unique_id",
        sort: "asc",
        width: 200,
      },
      {
        label: "Debit",
        field: "n_Debit",
        sort: "asc",
        width: 200,
      },
      {
        label: "Credit",
        field: "n_Credit",
        sort: "asc",
        width: 200,
      },
      {
        label: "Balance",
        field: "amount_column",
        sort: "asc",
        width: 200,
      },
      {
        label: "Remark",
        field: "transaction_log_comment",
        sort: "asc",
        width: 200,
      },
      {
        label: "User",
        field: "user",
        sort: "asc",
        width: 200,
      },
    ],
    rows: transationLogList,
  };

  const handleSearch = () => {
    let type = "";
    if (selectedTransationType !== null) {
      type = selectedTransationType.value;
    }
    let acc_chart_id = "";
    if (selectedChart !== null) {
      acc_chart_id = selectedChart.value;
    }
    handleTableData({ type, acc_chart_id,...master});
  };
  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Transaction Log" />

          <Col className="col-12">
            <Card>
              <CardBody>
                <Row>
                  <Col md="2">
                    <div className="mb-3">
                      <Label htmlFor="validationCustom05">From</Label>
                      {/* <div className="col-md-10"> */}
                      <input
                        className="form-control"
                        type="date"
                        id="from_date"
                        name="from_date"
                        onChange={handleDate}
                        value={master?.from_date}
                      />
                      {/* </div> */}
                    </div>
                  </Col>
                  <Col md="2">
                    <div className="mb-3">
                      <Label htmlFor="validationCustom05">To</Label>
                      {/* <div className="col-md-10"> */}
                      <input
                        className="form-control"
                        type="date"
                        id="to_date"
                        name="to_date"
                        onChange={handleDate}
                        value={master?.to_date}
                        min={master?.from_date}
                      />
                      {/* </div> */}
                    </div>
                  </Col>
                  <Col md="2">
                    <div className="mb-3">
                      <Label>Transaction Type</Label>
                      <Select
                        name="customer_community_id"
                        value={selectedTransationType}
                        onChange={(selected) => {
                          handleSelectedTransactionType(selected);
                        }}
                        options={[
                          { label: "Credit", value: 0 },
                          { label: "Debit", value: 1 },
                        ]}
                        classNamePrefix="select2-selection"
                      />
                    </div>
                  </Col>
                  <Col md="2">
                    <div className="mb-3">
                      <Label>Chart of account</Label>
                      <Select
                        name="chart_id"
                        value={selectedChart}
                        onChange={(selected) => {
                          handleSelectChange(selected);
                        }}
                        options={chartOfAccOption}
                        classNamePrefix="select2-selection"
                      />
                    </div>
                  </Col>
                  <Col md="2">
                    <div style={{display:"flex"}}>
                  <div className="mt-4 mb-3 mx-1" style={{ paddingTop: "5px" }}>

                    <Button
                      color="primary"
                      type="submit"
                      onClick={() => handleSearch()}
                    >
                      Search
                    </Button>
                    </div>
                    <div className="mt-4 mb-3 mx-1" style={{ paddingTop: "5px" }}>

                 
                    <Button color="danger" type="reset" onClick={() => reset()}>
                      Reset
                    </Button>
                    </div>
                    </div>
                  </Col>
                </Row>
{/* 
                <MDBDataTable
                  id="table_transaction_log"
                  responsive
                  bordered
                  data={data}
                  searching={true}
                  info={true}
                  disableRetreatAfterSorting={true}
                  entries={20}
                /> */}
                    <DataTable
                    data={data}
                    count={count}
                    page={page}
                    limit={limit}
                    onPageChange={(e) => setPage(e)}
                    onLimitChange={(e) => setLimit(e)}
                    id="table_transaction_log"
                  />
              </CardBody>
            </Card>
          </Col>
        </div>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {};

export default withRouter(
  connect(mapStateToProps, { apiError })(TransactionLog)
);

// Users.propTypes = {
//   error: PropTypes.any,
//   users: PropTypes.array,
// };
