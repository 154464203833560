
import React, { useState, useEffect, useRef } from 'react'
import Breadcrumb from '../../components/Common/Breadcrumb'
import { Button, Card, CardBody, Col, Label, Row } from 'reactstrap'
import { AvField, AvForm } from 'availity-reactstrap-validation'
import Select from "react-select";
import accessToken from '../../helpers/jwt-token-access/accessToken';
import axios from 'axios';
import { getCompany, getDate, getLocalbody, getUserId } from '../../helpers/globalFunctions';
import toastr, { options } from "toastr";
import { MDBDataTable } from "mdbreact";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import { withRouter, useHistory } from "react-router-dom";
import SweetAlert from "react-bootstrap-sweetalert";
import { Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import moment from 'moment';
import { CSVLink } from 'react-csv';



// some of the code and logics from prvious user Add module  


//  filed check value
// Admin:1
// Reporter Admin:2
// Operator Admin:3
// Health Inspector:4
// Secretary:5
// Ward Member:6
// Supervisor:7
// Team Leader:8
// Helper:9
// Driver:10


const User = () => {
    let history = useHistory();
    const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";
    const [masterObj, setmasterObj] = useState({})
    const [selectedValues, setselectedValues] = useState({})
    const [privilegeOptions, setprivilegeOptions] = useState([])
    const [companyOptions, setcompanyOptions] = useState([])
    const [branchOptions, setBranchOptions] = useState([]);
    const [fieldCheck, setfieldCheck] = useState(null)
    const [groupOption, setgroupOption] = useState([])
    const [salaryTypeOptions, setSalaryTypeOptions] = useState([]);
    const [wardOption, setwardOption] = useState([])
    const [errors, seterrors] = useState({})
    const [usersForTable, setUsersForTable] = useState([]);
    const [userPrivilege, setUserPrivilege] = useState("");
    const [userIdToBeDeleted, setUserIdToBeDeleted] = useState(null);
    const [confirmDeleteAlert, setConfirmDeleteAlert] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [userIdTobeUpdated, setUserIdToBeUpdated] = useState(null);
    const [localBodyForSearchOption, setlocalBodyForSearchOption] = useState([])
    const [passwordObject, setPasswordObject] = useState({
        password: "",
        confirmPassword: "",
    });
    const [filterSelected, setfilterSelected] = useState({})
    const [filterObj, setfilterObj] = useState({})
    const [dataToBeExported, setDataToBeExported] = useState([]);

    const genderOption = {
        0: { label: "Male", value: 0 },
        1: { label: "Female", value: 1 },
        2: { label: "Transgender", value: 2 },
    }

    const communityOption = {
        "SC": { label: "SC", value: "SC" },
        "ST": { label: "ST", value: "ST" },
        "OBC": { label: "OBC", value: "OBC" },
        "General": { label: "General", value: "General" },
        "Minority(Muslim, Christian)": { label: "Minority(Muslim, Christian)", value: "Minority(Muslim, Christian)" },
    }

    const additionalSkillOptions = {
        "Driving license": { label: "Driving license", value: "Driving license" },
        "Tailoring": { label: "Tailoring", value: "Tailoring" },
        "Fashion": { label: "Fashion", value: "Fashion" },
    }

    const hkshouseOption = {
        "Own house": { label: "Own house", value: "Own house" },
        "HKS rent house": { label: "HKS rent house", value: "HKS rent house" },
        "HKS living in joint ownership house": { label: "HKS living in joint ownership house", value: "HKS living in joint ownership house" },

    }

    const qualificationOption = {
        "Primary education(1-7)": { label: "Primary education(1-7)", value: "Primary education(1-7)" },
        "High School": { label: "High School", value: "High School" },
        "Higher Secondary": { label: "Higher Secondary", value: "Higher Secondary" },
        "Graduation & above": { label: "Graduation & above", value: "Graduation & above" },
    }


    const toTop = () => {
        window.scroll({
            top: 0,
            left: 0,
            behavior: "smooth",
        });
    };
    const formRef = useRef();
    useEffect(() => {
        fetchAllCompany()
        fetchAllRootPrivillage()
        fetchAllSalaryType()
        fetchMasterLocalbodies()

    }, [])

    useEffect(() => {
        handleTableData()

    }, [filterObj])


    function fetchAllRootPrivillage() {
        axios.get(`${API_URL}privilage/root`, {
            headers: {
                "x-access-token": accessToken
            }
        }).then((res) => {
            setprivilegeOptions(res.data.data)
        })
    }

    function fetchAllCompany() {
        axios
            .get(`${API_URL}company/list/options`, {
                headers: {
                    "x-access-token": accessToken,
                },
            })
            .then((res) => {
                var company =
                    res.data.data &&
                    res.data.data.map((el) => {
                        return {
                            label: el.company_name,
                            value: el._id,
                        };
                    });

                setcompanyOptions([
                    {
                        options: company,
                    },
                ]);
            });
    }

    function getAllLocalbodies(id) {
        axios.get(`${API_URL}user/companylocalbody?company_id=${id}&user_id=${getUserId()}`, {
            headers: {
                "x-access-token": accessToken
            }
        }).then((res) => {
            let localbody_data =
                res.data.data &&
                res.data.data.map((el) => {

                    return {
                        label: el.localbody_name,
                        value: el._id,
                    };
                });

            setBranchOptions([
                {
                    options: localbody_data,
                },
            ]);


        })
    }

    function fetchMasterLocalbodies(user_id, id) {
        var data = localStorage.getItem("authUser");
        var user_obj = JSON.parse(data);
        user_id = user_obj._id;
        if (id != null) {
            var url = `${API_URL}company/list/localbodies?id=` + user_id + `&cid=` + id;
        } else {
            var url = `${API_URL}company/list/localbodies?id=` + user_id;
        }
        axios
            .get(url, {
                headers: {
                    "x-access-token": accessToken,
                },
            })
            .then((res) => {
                var localbodies =
                    res.data.data &&
                    res.data.data.map((item) => {
                        return {
                            label: item.localbody_name,
                            value: item._id,
                        };
                    });

                setlocalBodyForSearchOption([
                    {
                        options: localbodies,
                    },
                ]);
            });
    }


    function fetchAllSalaryType() {
        axios
            .get(`${API_URL}user/salarytype`, {
                headers: {
                    "x-access-token": accessToken,
                },
            })
            .then((res) => {
                var salarytype_data =
                    res.data.data &&
                    res.data.data.map((el) => {
                        return {
                            label: el.type,
                            value: el._id,
                        };
                    });

                setSalaryTypeOptions([
                    {
                        options: salarytype_data,
                    },
                ]);
            });
    }

    function getAllwardoption(localbody, group) {
        axios.get(`${API_URL}user/ward/option?localbodyId=${localbody}&groupId=${group}`, {
            headers: {
                "x-access-token": accessToken
            }
        }).then((res) => {
            var wardOptionData =
                res.data.data.map((el) => {
                    return {
                        label: el.ward_name,
                        value: el._id
                    }
                })
            setwardOption(wardOptionData)


        })
    }

    function getAllgroup(id) {
        axios.get(`${API_URL}user/local_group?localbody_id=${id}`, {
            headers: {
                "x-access-token": accessToken
            }
        }).then((res) => {
            var groupOption =
                res.data.data.map((el) => {
                    return {
                        label: el.group_name,
                        value: el._id
                    }
                })
            setgroupOption(groupOption)
        })
    }


    function handlefieldValueChnage(e) {
        let { name, value } = e.target
        setmasterObj({ ...masterObj, [name]: value })
    }

    function handlePrivillageChange(name, select) {
        setmasterObj({ ...masterObj, [name]: select.value })
        setselectedValues({ ...selectedValues, [name]: select })
        setfieldCheck(select.check)
    }

    function handleMultiselect(name, select) {
        let valuearray = select
            ? Array.isArray(select)
                ? select.map(item => item.value)
                : select.value
            : null
        if (name == "local_body") {
            if (!Array.isArray(valuearray)) {
                valuearray = [valuearray]
            }
        }
        setselectedValues({ ...selectedValues, [name]: select })
        setmasterObj({ ...masterObj, [name]: valuearray })
        if (name == "company") getAllLocalbodies(valuearray)
        if (name == "local_body") {
            getAllgroup(valuearray)
            getAllwardoption(valuearray, "")
        }
        if (name == "group_id") getAllwardoption("", valuearray)

        seterrors(pre => ({ ...pre, [name]: "" }))
    }

    function handleSelectValue(name, select) {
        setselectedValues({ ...selectedValues, [name]: select })
        setmasterObj({ ...masterObj, [name]: select.value })
    }

    const deleteUserImage = () => {
        setmasterObj({
            ...masterObj,
            [`user_image`]: "",
        });
    };

    const uploadImage = (e) => {
        const fd = new FormData();
        fd.append("user_image", e.target.files[0]);
        axios
            .post(`${API_URL}user/image`, fd, {
                headers: {
                    "x-access-token": accessToken,
                },
                onUploadProgress: (data) => {
                    //Set the progress value to show the progress bar
                    // setUploadProgress(Math.round((100 * data.loaded) / data.total));
                },
            })
            .then((response) => {
                if (response.data.status === "success") {
                    setmasterObj({
                        ...masterObj,
                        [`user_image`]: response.data.file.filename,
                    });
                    // toastr.success("Image Uploaded");
                } else {
                    toastr.error(response.data.message);
                }
            });
    };

    const deleteUserDocument = () => {
        setmasterObj({
            ...masterObj,
            [`user_documents`]: "",
        });
    };

    const uploadDocument = (e) => {
        const fd = new FormData();
        fd.append("user_image", e.target.files[0]);
        axios
            .post(`${API_URL}user/image`, fd, {
                headers: {
                    "x-access-token": accessToken,
                },
                onUploadProgress: (data) => {
                    // setUploadProgress(Math.round((100 * data.loaded) / data.total));
                },
            })
            .then((response) => {
                if (response.data.status === "success") {
                    setmasterObj({
                        ...masterObj,
                        [`user_documents`]: response.data.file.filename,
                    });
                    // toastr.success("Document Uploaded");
                } else {
                    toastr.error(response.data.message);
                }
            });
    };


    function handleValidSubmit(e, v) {
        const validationErrors = {};

        if (!masterObj.privilage) {
            validationErrors.privilage = "Privilege is required";
        }
        if (!masterObj.company) {
            validationErrors.company = "Company is required";
        }


        if (Object.keys(validationErrors).length > 0) {
            seterrors(validationErrors);
            return;
        }


        if (masterObj?._id) {
            axios.put(`${API_URL}user`, masterObj, {
                headers: {
                    "x-access-token": accessToken
                }
            }).then((res) => {
                if (res.data.success === true) {
                    toastr.success("User updated successfully")
                    handleTableData()
                    setmasterObj({})
                    setselectedValues({})
                } else {
                    toastr.error("Failed to update")

                }
            }).catch((err) => {
                toastr.error(err.response.data.message)
            })

        } else {
            axios.post(`${API_URL}user`, masterObj, {
                headers: {
                    "x-access-token": accessToken
                }
            }).then((res) => {
                if (res.data.success === true) {
                    toastr.success("User created successfully")
                    handleTableData()
                    setmasterObj({})
                    setselectedValues({})

                } else {
                    toastr.error("Failed to create")

                }
            }).catch((err) => {
                toastr.error(err.response.data.message)
            })
        }
    }


    const data = {
        columns: [
            {
                label: "#",
                field: "id",
                sort: "asc",
                width: 150,
            },
            {
                label: "Date",
                field: "date",
                sort: "asc",
                width: 150,
            },
            {
                label: "Name",
                field: "name1",
                sort: "asc",
                width: 400,
            },
            // {
            //   label: "Email",
            //   field: "email",
            //   sort: "asc",
            //   width: 200,
            // },
            // {
            //   label: "Username",
            //   field: "username",
            //   sort: "asc",
            //   width: 200,
            // },
            {
                label: "Mobile",
                field: "mobile",
                sort: "asc",
                width: 100,
            },
            {
                label: "Privilege",
                field: "privilage1",
                sort: "asc",
                width: 150,
            },
            {
                label: "Company",
                field: "company1",
                sort: "asc",
                width: 150,
            },
            {
                label: "Localbody",
                field: "localbody",
                sort: "asc",
                width: 100,
            },
            {
                label: "Status",
                field: "block_status",
                sort: "asc",
                width: 50,
            },
            {
                label: "Info",
                field: "appUser",
                sort: "asc",
                width: 50,
            },
            {
                label: "Action",
                field: "action",
                width: 300,
            },
        ],
        rows: usersForTable,
    };



    function handleReset() {
        setmasterObj({})
        setselectedValues({})
        setfieldCheck(null)
        seterrors({})
    }



    function handleTableData() {
        let userid = "";
        let privilege = "";
        if (localStorage.getItem("authUser")) {
            var data = localStorage.getItem("authUser");
            var user_obj = JSON.parse(data);
            userid = user_obj._id;
            privilege = user_obj?.privilage;
            // setUserId(user_obj._id);
            setUserPrivilege(user_obj?.privilage);
        }
        axios.get(`${API_URL}user?user_id=${getUserId()}&localbody_id=${getLocalbody()}&local_id=${filterObj?.local_id}&status=${filterObj?.status}`, {
            headers: {
                "x-access-token": accessToken
            }
        }).then((res) => {
            let dataToBeExported = [];
            let result = res.data.data
            result && result.map((item, index) => {
                let exportItem = {};
                item.id = index + 1;
                item.name1 = [item?.firstName, item?.lastName].filter(Boolean).join(" ") || "No Name";
                item.privilage1 = item.privilage && item.privilage.privilege_name;
                item.company1 = (
                    <div>
                        <ul>
                            {item?.company?.map((el, index) => (
                                <>
                                    <li>{el.company_name}</li>
                                </>
                            ))}
                        </ul>
                    </div>
                )
                item.localbody = (
                    <div>
                        <ul>
                            {item?.local_body?.map((el) => (
                                <>
                                    <li>{el.localbody_name}</li>
                                </>
                            ))}
                        </ul>
                    </div>
                )
                if (item?.status === 0) {
                    item.block_status = (
                        <>
                            <LockOpenIcon
                                style={{
                                    color: "green",
                                    fontSize: "18px",
                                    cursor: "pointer",
                                }}
                                onClick={() => updateStatus(item)}
                            />
                        </>
                    );
                }
                if (item?.status === 2) {
                    item.block_status = (
                        <>
                            <LockOpenIcon
                                style={{
                                    color: "red",
                                    fontSize: "18px",
                                    cursor: "pointer",
                                }}
                                onClick={() => updateStatus(item)}
                            />
                        </>
                    );
                }

                if (item.app_user === 1) {
                    item.appUser = (
                        <div style={{ display: "flex", justifyContent: "center" }}>
                            <i
                                className="uil-android"
                                style={{
                                    fontSize: "1.5em",
                                    cursor: "pointer",
                                    marginLeft: "0.2em",
                                    marginRight: "0.5em",
                                    color: "green",
                                }}

                            ></i>
                        </div>
                    );
                }

                item.action = (
                    <div style={{ display: "flex", justifyContent: "center" }}>
                        {privilege && privilege == "61fa7275a7cd7dbee0b92b7b" ? (
                            <i
                                className="fas fa-key"
                                style={{
                                    fontSize: "1em",
                                    cursor: "pointer",
                                    marginLeft: "0.2em",
                                    marginRight: "0.5em",
                                }}
                                onClick={() => {
                                    setShowModal(true);
                                    setUserIdToBeUpdated(item._id);
                                }}
                            ></i>
                        ) : null}

                        <i
                            className="fas fa-eye"
                            style={{
                                fontSize: "1em",
                                cursor: "pointer",
                                marginLeft: "0.2em",
                                marginRight: "0.5em",
                            }}
                            onClick={() => {
                                history.push("/user/" + item._id);
                            }}
                        >

                        </i>

                        <i
                            className="far fa-edit"
                            style={{
                                fontSize: "1em",
                                cursor: "pointer",
                                marginLeft: "0.1em",
                                marginRight: "0.5em",
                            }}
                            onClick={() => {
                                preUpdateUser(item._id);
                                toTop();
                            }}
                        ></i>
                        <i
                            className="far fa-trash-alt"
                            style={{ fontSize: "1em", cursor: "pointer" }}
                            onClick={() => {
                                setUserIdToBeDeleted(item._id);
                                setConfirmDeleteAlert(true);
                            }}
                        ></i>
                    </div>
                );
                exportItem.id = item.id;
                exportItem.Date = item.date;
                exportItem.Name = item.name1;
                exportItem.Mobile = item.mobile;
                exportItem.Address = item?.address;
                exportItem.DOB = item?.user_dob ? moment(item.user_dob).format("MM-DD-YYYY") : "";
                exportItem.Privilege = item.privilage1;
                exportItem.Company = item?.companyE;
                exportItem.Localbody = item.localbodyName;
                exportItem["Join Date"] = item?.user_joining_date;
                exportItem.Info = item.app_user === 1 ? "App User" : "CRM User";
                dataToBeExported.push(exportItem);
                return item
            })
            setUsersForTable(result)
            setDataToBeExported(dataToBeExported);
        })
    }

    const updateStatus = (item) => {
        axios
            .put(`${API_URL}security/userblock?user_id=` + item._id, item, {
                headers: {
                    "x-access-token": accessToken,
                },
            })
            .then((res) => {
                if (res.data.success === true) {
                    if (res.data.data.status === 0) {
                        toastr.success("User unblocked successfully");
                    } else toastr.success("User blocked successfully");

                    handleTableData();
                } else {
                    toastr.error("Failed");
                    return;
                }
            })
            .catch((err) => {
                toastr.error(err.response.data.message);
                return;
            });
    };

    let closeModal = () => {
        setShowModal(false);
        setUserIdToBeUpdated(null);
        setPasswordObject({
            password: "",
            confirmPassword: "",
        });
    };

    const handleValidSubmitPassword = () => {
        if (passwordObject.password == passwordObject.confirmPassword) {
            let item = {
                user_id: userIdTobeUpdated,
                password: passwordObject?.password,
            };
            axios
                .post(`${API_URL}user/reset_password`, item, {
                    headers: {
                        "x-access-token": accessToken,
                    },
                })
                .then((res) => {
                    if (res.data.success === true) {
                        toastr.success(res.data.message);
                        setPasswordObject({
                            password: "",
                            confirmPassword: "",
                        });
                        closeModal();
                        formRef.current.reset();
                    } else {
                        toastr.error(res.data.message);
                        return;
                    }
                });
        } else {
            toastr.error("Passwords are not matching");
        }
    };

    function handleChangePassword(e) {
        let name = e.target.name;
        let value = e.target.value;
        setPasswordObject({ ...passwordObject, [name]: value });
    }

    function handleSearchSelectChange(name, select) {
        setfilterSelected(prev => ({ ...prev, [name]: select }))
        setfilterObj(prev => ({ ...filterObj, [name]: select.value }))
    }
    function resetFilter() {
        setfilterSelected({})
        setfilterObj({})
    }


    function preUpdateUser(id) {
        axios.get(`${API_URL}user/single/detail?user_id=${id}`, {
            headers: {
                "x-access-token": accessToken
            }
        }).then((res) => {
            let result = res.data.data[0]
            delete result.district
            setfieldCheck(result?.privilage?.check)
            setselectedValues({
                privilage: result?.privilage,
                company: result?.company,
                local_body: result?.local_body,
                user_group: result?.user_group,
                ward: result?.ward,
                salary_type: result?.salary_type,
                gender: genderOption[result.gender],
                community: communityOption[result.community],
                additional_skill: additionalSkillOptions[result.additional_skill],
                hks_house_details: hkshouseOption[result.hks_house_details],
                qualification: qualificationOption[result.qualification]
            })
            setmasterObj({
                ...result,
                privilage: result?.privilage.value,
                company: result?.company?.map(item => item.value),
                local_body: result?.local_body?.map(item => item.value),
                user_group: result?.user_group?.map(item => item.value),
                ward: result?.ward?.map(item => item.value),
                salary_type: result?.salary_type?.value
            })
        })
    }


    return (
        <>
            {confirmDeleteAlert ? (
                <SweetAlert
                    title=""
                    showCancel
                    confirmButtonText="Delete"
                    confirmBtnBsStyle="success"
                    cancelBtnBsStyle="danger"
                    onConfirm={() => {
                        axios
                            .delete(`${API_URL}user` + "/" + userIdToBeDeleted, {
                                headers: {
                                    "x-access-token": accessToken,
                                },
                            })
                            .then((res) => {
                                if (res.data.success === true) {
                                    toastr.success("User deleted successfully");
                                    handleTableData();
                                } else {
                                    toastr.error("Failed to delete user");
                                    return;
                                }
                            });
                        // dispatch(deleteUser(userIdToBeDeleted));
                        setConfirmDeleteAlert(false);
                    }}
                    onCancel={() => setConfirmDeleteAlert(false)}>
                    Are you sure you want to delete it?
                </SweetAlert>
            ) : null}
            <Modal
                show={showModal}
                toggle={() => {
                    closeModal();
                }}
                centered={true}
                size="md">
                <div className="modal-header">
                    <h5 className="modal-title mt-0">Reset Password</h5>
                    <button
                        type="button"
                        onClick={() => {
                            closeModal();
                        }}
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <AvForm
                    className="needs-validation"
                    onValidSubmit={(e, v) => {
                        handleValidSubmitPassword(e, v);
                    }}>
                    <div className="modal-body">
                        <Row>
                            <Col md="12">
                                <div className="mb-3">
                                    <Label htmlFor="validationCustom05">Password</Label>
                                    <AvField
                                        name="password"
                                        placeholder="Password"
                                        type="password"
                                        errorMessage=" Please provide a valid password"
                                        className="form-control"
                                        validate={{ required: { value: true } }}
                                        id="validationCustom05"
                                        value={passwordObject.password}
                                        onChange={handleChangePassword}
                                    />
                                </div>
                            </Col>
                            <Col md="12">
                                <div className="mb-3">
                                    <Label htmlFor="validationCustom05">Confirm Password</Label>
                                    <AvField
                                        name="confirmPassword"
                                        placeholder="Confirm Password"
                                        type="password"
                                        errorMessage=" Please confirm the password"
                                        className="form-control"
                                        validate={{ required: { value: true } }}
                                        id="validationCustom05"
                                        value={passwordObject.confirmPassword}
                                        onChange={handleChangePassword}
                                    />
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <div className="modal-footer">
                        <button className="btn btn-dark" style={{ marginRight: "1rem" }} onClick={closeModal}>
                            Close
                        </button>
                        <button className="btn btn-primary" type="submit">
                            Confirm
                        </button>
                    </div>
                </AvForm>
            </Modal>
            <div className='page-content'>
                <div className='container-fluid'>
                    <Breadcrumb title="Home" breadcrumbItem="Manage Users" />
                    <Row>
                        <Col xl="12">
                            <Card>
                                <CardBody>
                                    <AvForm
                                        ref={formRef}
                                        onValidSubmit={(e, v) => {
                                            handleValidSubmit(e, v);
                                        }}
                                    >
                                        <Row>

                                            <Col md="3">
                                                <div className="mb-3">
                                                    <Label htmlFor="validationCustom01">First name</Label>
                                                    <AvField
                                                        name="firstName"
                                                        placeholder="First name"
                                                        type="text"
                                                        errorMessage="Enter First Name"
                                                        className="form-control"
                                                        validate={{ required: { value: true } }}
                                                        id="validationCustom01"
                                                        value={masterObj?.firstName || ""}
                                                        onChange={handlefieldValueChnage}
                                                    />
                                                </div>
                                            </Col>
                                            <Col md="3">
                                                <div className="mb-3">
                                                    <Label htmlFor="validationCustom02">Last name</Label>
                                                    <AvField
                                                        name="lastName"
                                                        placeholder="Last name"
                                                        type="text"
                                                        className="form-control"
                                                        id="validationCustom02"
                                                        value={masterObj?.lastName || ""}
                                                        onChange={handlefieldValueChnage}

                                                    />
                                                </div>
                                            </Col>
                                            <Col md="3">
                                                <div className="mb-3">
                                                    <Label htmlFor="validationCustom03">Email</Label>
                                                    <AvField
                                                        name="email"
                                                        placeholder="Email"
                                                        type="email"
                                                        errorMessage="Enter valid Email"
                                                        className="form-control"
                                                        validate={{ required: { value: true } }}
                                                        id="validationCustom03"
                                                        value={masterObj.email || ""}
                                                        onChange={handlefieldValueChnage}

                                                    />
                                                </div>
                                            </Col>
                                            <Col md="3">
                                                <div className="mb-3">
                                                    <Label htmlFor="validationCustom03">Username</Label>
                                                    <AvField
                                                        name="username"
                                                        placeholder="Username"
                                                        type="text"
                                                        errorMessage="Enter valid Username"
                                                        className="form-control"
                                                        validate={{ required: { value: true } }}
                                                        id="validationCustom03"
                                                        value={masterObj.username || ""}
                                                        onChange={handlefieldValueChnage}

                                                    />
                                                </div>
                                            </Col>
                                            <Col md="3">
                                                <div className="mb-3">
                                                    <Label htmlFor="validationCustom04">Mobile</Label>
                                                    <AvField
                                                        name="mobile"
                                                        placeholder="Mobile"
                                                        type="number"
                                                        errorMessage="Please provide a valid mobile."
                                                        className="form-control"
                                                        validate={{ required: { value: true } }}
                                                        id="validationCustom04"
                                                        value={masterObj.mobile || ""}
                                                        minLength={10}
                                                        maxLength={10}
                                                        onChange={handlefieldValueChnage}

                                                    />
                                                </div>
                                            </Col>
                                            <Col md="3">
                                                <div className="mb-3">
                                                    <Label htmlFor="validationCustom05">DOB</Label>
                                                    <AvField
                                                        className="form-control"
                                                        type="date"
                                                        name="user_dob"
                                                        value={masterObj?.user_dob || ""}
                                                        onChange={handlefieldValueChnage}

                                                    />
                                                </div>
                                            </Col>
                                            <Col md="3">
                                                <div className="mb-3">
                                                    <Label htmlFor="validationCustom05">Date Of Joining</Label>
                                                    <input
                                                        className="form-control"
                                                        type="date"
                                                        id="user_joining_date"
                                                        name="user_joining_date"
                                                        value={masterObj?.user_joining_date || ""}
                                                        onChange={handlefieldValueChnage}

                                                    />
                                                </div>
                                            </Col>
                                            <Col md="3">
                                                <div className="mb-3">
                                                    <Label htmlFor="validationCustom05">Date Of Resigning</Label>
                                                    <input
                                                        className="form-control"
                                                        type="date"
                                                        id="user_resigning_date"
                                                        name="user_resigning_date"
                                                        value={masterObj?.user_resigning_date || ""}
                                                        min={masterObj?.user_joining_date}
                                                        onChange={handlefieldValueChnage}

                                                    />
                                                </div>
                                            </Col>
                                            <Col md={3}>
                                                <div className="mb-3">
                                                    <Label>Privilege</Label>
                                                    <Select
                                                        name="privilage"
                                                        options={privilegeOptions}
                                                        classNamePrefix="select2-selection"
                                                        value={selectedValues?.privilage || null}
                                                        onChange={(selected) => {
                                                            handlePrivillageChange("privilage", selected)
                                                            seterrors(prev => ({ ...prev, privilage: "" }))
                                                        }}
                                                    />
                                                    <p className="text-danger" style={{ fontSize: "11px" }}>
                                                        {errors?.privilage || ""}
                                                    </p>
                                                </div>
                                            </Col>
                                            <Col md={3}>
                                                <div className="mb-3">
                                                    <Label>Company</Label>
                                                    <Select
                                                        name="company"
                                                        isMulti={true}
                                                        options={companyOptions}
                                                        classNamePrefix="select2-selection"
                                                        onChange={(selected) => {
                                                            handleMultiselect("company", selected)
                                                        }}
                                                        value={selectedValues?.company || null}
                                                    />
                                                    <p className="text-danger" style={{ fontSize: "11px" }}>
                                                        {errors?.company || ""}
                                                    </p>
                                                </div>
                                            </Col>
                                            <Col md={3}>
                                                <div className="mb-3">
                                                    <Label>Localbody</Label>
                                                    <Select
                                                        isMulti={selectedValues?.privilage?.multi_localbody}
                                                        name="local_body"
                                                        // value={selectedBranchMulti}
                                                        options={branchOptions}
                                                        classNamePrefix="select2-selection"
                                                        onChange={(selected) => {
                                                            handleMultiselect("local_body", selected)
                                                        }}
                                                        value={selectedValues?.local_body || null}
                                                    />
                                                </div>
                                            </Col>
                                            {fieldCheck == 9 || fieldCheck == 8 || fieldCheck == 7 || fieldCheck == 10 ? (
                                                <Col md="3">
                                                    <div className="mb-3">
                                                        <Label htmlFor="validationCustom01">Group</Label>
                                                        <Select
                                                            isMulti
                                                            name="user_group"
                                                            options={groupOption}
                                                            classNamePrefix="select2-selection"
                                                            onChange={(selected) => {
                                                                handleMultiselect("user_group", selected)
                                                            }}
                                                            value={selectedValues?.user_group || null}

                                                        />
                                                    </div>
                                                </Col>

                                            ) : null}

                                            {fieldCheck == 9 || fieldCheck == 8 || fieldCheck == 7 || fieldCheck == 10 || fieldCheck == 6 || fieldCheck == 4 ? (
                                                <Col md="3">
                                                    <div className="mb-3">
                                                        <Label>Ward</Label>
                                                        <Select
                                                            isMulti
                                                            name="ward"
                                                            classNamePrefix="select2-selection"
                                                            onChange={(selected) => {
                                                                handleMultiselect("ward", selected)
                                                            }}
                                                            value={selectedValues?.ward || null}
                                                            options={wardOption}

                                                        />
                                                        <p className="text-danger" style={{ fontSize: "11px" }}>
                                                            {/* {selectedWardMultiLocal === null ? errors.wardError : ""} */}
                                                        </p>
                                                    </div>
                                                </Col>

                                            ) : null}


                                            {fieldCheck == 9 || fieldCheck == 1 || fieldCheck == 8 || fieldCheck == 7 || fieldCheck == 10 || fieldCheck == 3 ? (
                                                <Col md="3">
                                                    <div className="mb-3">
                                                        <Label>PF/ESI Details</Label>
                                                        <AvField
                                                            name="pf_esi_number"
                                                            type="text"
                                                            className="form-control"
                                                            value={masterObj?.pf_esi_number || ""}
                                                            placeholder="PF/ESI details"
                                                            onChange={handlefieldValueChnage}

                                                        />
                                                    </div>
                                                </Col>
                                            ) : null}


                                            {fieldCheck == 1 || fieldCheck == 9 || fieldCheck == 8 || fieldCheck == 7 || fieldCheck == 10 || fieldCheck == 3 ? (
                                                <Col md="3">
                                                    <div className="mb-3">
                                                        <Label>Aadhar/Id number</Label>
                                                        <AvField
                                                            name="id_number"
                                                            type="text"
                                                            className="form-control"
                                                            value={masterObj?.id_number || ""}
                                                            placeholder="Adhar/Id number"
                                                            onChange={handlefieldValueChnage}

                                                        />
                                                    </div>
                                                </Col>
                                            ) : null}


                                            {fieldCheck == 1 || fieldCheck == 9 || fieldCheck == 8 || fieldCheck == 7 || fieldCheck == 10 || fieldCheck == 3 ? (
                                                <Col md="3">
                                                    <div className="mb-3">
                                                        <Label>Gender</Label>
                                                        <Select
                                                            name="gender"
                                                            classNamePrefix="select2-selection"
                                                            options={[
                                                                { label: "Male", value: 0 },
                                                                { label: "Female", value: 1 },
                                                                { label: "Transgender", value: 2 },
                                                            ]}
                                                            onChange={(selected) => {
                                                                handleSelectValue("gender", selected)
                                                            }}
                                                            value={selectedValues?.gender || null}

                                                        />
                                                    </div>
                                                </Col>

                                            ) : null}

                                            {fieldCheck == 1 || fieldCheck == 9 || fieldCheck == 8 || fieldCheck == 7 || fieldCheck == 10 || fieldCheck == 3 ? (
                                                <Col md="3">
                                                    <div className="mb-3">
                                                        <Label>Age</Label>
                                                        <AvField
                                                            name="age"
                                                            type="number"
                                                            className="form-control"
                                                            value={masterObj?.age || ""}
                                                            placeholder="Age"
                                                            min={0}
                                                            onChange={handlefieldValueChnage}

                                                        />
                                                    </div>
                                                </Col>

                                            ) : null}

                                            {fieldCheck == 1 || fieldCheck == 9 || fieldCheck == 8 || fieldCheck == 7 || fieldCheck == 10 || fieldCheck == 3 ? (
                                                <Col md="3">
                                                    <div className="mb-3">
                                                        <Label>Community</Label>
                                                        <Select
                                                            name="community"
                                                            classNamePrefix="select2-selection"
                                                            options={[
                                                                { label: "SC", value: "SC" },
                                                                { label: "ST", value: "ST" },
                                                                { label: "OBC", value: "OBC" },
                                                                { label: "General", value: "General" },
                                                                { label: "Minority(Muslim, Christian)", value: "Minority(Muslim, Christian)" },
                                                            ]}
                                                            onChange={(selected) => {
                                                                handleSelectValue("community", selected)
                                                            }}
                                                            value={selectedValues?.community || null}

                                                        />
                                                    </div>
                                                </Col>

                                            ) : null}

                                            {fieldCheck == 1 || fieldCheck == 9 || fieldCheck == 8 || fieldCheck == 7 || fieldCheck == 10 || fieldCheck == 3 ? (
                                                <Col md="3">
                                                    <div className="mb-3">
                                                        <Label>Qualification</Label>
                                                        <Select
                                                            name="qualification"
                                                            classNamePrefix="select2-selection"
                                                            options={[
                                                                { label: "Primary education(1-7)", value: "Primary education(1-7)" },
                                                                { label: "High School", value: "High School" },
                                                                { label: "Higher Secondary", value: "Higher Secondary" },
                                                                { label: "Graduation & above", value: "Graduation & above" },
                                                            ]}
                                                            onChange={(selected) => {
                                                                handleSelectValue("qualification", selected)
                                                            }}
                                                            value={selectedValues?.qualification || null}

                                                        />
                                                    </div>
                                                </Col>

                                            ) : null}

                                            {fieldCheck == 1 || fieldCheck == 9 || fieldCheck == 8 || fieldCheck == 7 || fieldCheck == 10 || fieldCheck == 3 ? (
                                                <Col md="3">
                                                    <div className="mb-3">
                                                        <Label>Health card/Insurance coverage details</Label>
                                                        <AvField
                                                            name="health_insurance_number"
                                                            type="text"
                                                            className="form-control"
                                                            value={masterObj?.health_insurance_number || ""}
                                                            placeholder="Health card/Insurance coverage"
                                                            onChange={handlefieldValueChnage}

                                                        />
                                                    </div>
                                                </Col>
                                            ) : null}

                                            {fieldCheck == 1 || fieldCheck == 9 || fieldCheck == 8 || fieldCheck == 7 || fieldCheck == 10 || fieldCheck == 3 ? (
                                                <Col md="3">
                                                    <div className="mb-3">
                                                        <Label>Additional Skills</Label>
                                                        <Select
                                                            name="additional_skill"
                                                            classNamePrefix="select2-selection"
                                                            options={[
                                                                { label: "Driving license", value: "Driving license" },
                                                                { label: "Tailoring", value: "Tailoring" },
                                                                { label: "Fashion", value: "Fashion" },
                                                            ]}
                                                            onChange={(selected) => {
                                                                handleSelectValue("additional_skill", selected)
                                                            }}
                                                            value={selectedValues?.additional_skill || null}
                                                        />
                                                    </div>
                                                </Col>

                                            ) : null}

                                            {fieldCheck == 1 || fieldCheck == 9 || fieldCheck == 8 || fieldCheck == 7 || fieldCheck == 10 || fieldCheck == 3 ? (
                                                <Col md="3">
                                                    <div className="md-3">
                                                        <Label>HKS House Details</Label>
                                                        <Select
                                                            name="hks_house_details"
                                                            classNamePrefix="select2-selection"
                                                            options={[
                                                                { label: "Own house", value: "Own house" },
                                                                { label: "HKS rent house", value: "HKS rent house" },
                                                                { label: "HKS living in joint ownership house", value: "HKS living in joint ownership house" },
                                                            ]}
                                                            onChange={(selected) => {
                                                                handleSelectValue("hks_house_details", selected)
                                                            }}
                                                            value={selectedValues?.hks_house_details || null}

                                                        />
                                                    </div>
                                                </Col>

                                            ) : null}

                                            {fieldCheck == 1 || fieldCheck == 9 || fieldCheck == 8 || fieldCheck == 7 || fieldCheck == 10 || fieldCheck == 3 ? (
                                                <Col md="3">
                                                    <div className="mb-3">
                                                        <Label htmlFor="validationCustom03">Salary</Label>
                                                        <AvField
                                                            name="salary"
                                                            // type="text"
                                                            type="number"
                                                            className="form-control"
                                                            id="validationCustom03"
                                                            value={masterObj?.salary || ""}
                                                            placeholder="Salary"
                                                            // validate={{number:true}}
                                                            min={0}
                                                            onChange={handlefieldValueChnage}

                                                        />
                                                    </div>
                                                </Col>

                                            ) : null}

                                            {fieldCheck == 1 || fieldCheck == 9 || fieldCheck == 8 || fieldCheck == 7 || fieldCheck == 10 || fieldCheck == 3 ? (
                                                <Col md="3">
                                                    <div className="mb-3">
                                                        <Label>Salary Type</Label>
                                                        <Select
                                                            name="salary_type"
                                                            options={salaryTypeOptions}
                                                            classNamePrefix="select2-selection"
                                                            onChange={(selected) => {
                                                                handleSelectValue("salary_type", selected)
                                                            }}
                                                            value={selectedValues?.salary_type || null}
                                                        />
                                                    </div>
                                                </Col>

                                            ) : null}


                                            {fieldCheck == 1 || fieldCheck == 9 || fieldCheck == 8 || fieldCheck == 7 || fieldCheck == 10 || fieldCheck == 3 ? (
                                                <Col md="3">
                                                    <div className="mb-3">
                                                        <Label htmlFor="validationCustom01">Account Number</Label>
                                                        <AvField
                                                            name="account_no"
                                                            placeholder="Account Number"
                                                            type="text"
                                                            errorMessage="Enter Account Number"
                                                            className="form-control"
                                                            // validate={{ required: { value: true } }}
                                                            id="validationCustom01"
                                                            value={masterObj.account_no || ""}
                                                            onChange={handlefieldValueChnage}

                                                        />
                                                    </div>
                                                </Col>

                                            ) : null}

                                            {fieldCheck == 1 || fieldCheck == 9 || fieldCheck == 8 || fieldCheck == 7 || fieldCheck == 10 || fieldCheck == 3 ? (
                                                <Col md="3">
                                                    <div className="mb-3">
                                                        <Label htmlFor="validationCustom01">IFSC CODE</Label>
                                                        <AvField
                                                            name="IFSC"
                                                            placeholder="IFSC CODE"
                                                            type="text"
                                                            errorMessage="Enter IFSC CODE"
                                                            className="form-control"
                                                            // validate={{ required: { value: true } }}
                                                            id="validationCustom01"
                                                            value={masterObj.IFSC || ""}
                                                            onChange={handlefieldValueChnage}

                                                        />
                                                    </div>
                                                </Col>
                                            ) : null}
                                            <Col md="3">
                                                <div className="mb-3">
                                                    <Label htmlFor="validationCustom05">Password</Label>
                                                    <AvField
                                                        name="password"
                                                        placeholder="Password"
                                                        type="password"
                                                        errorMessage=" Please provide a valid password"
                                                        className="form-control"
                                                        validate={{ required: { value: true } }}
                                                        id="validationCustom05"
                                                        value={masterObj?.password || ""}
                                                        onChange={handlefieldValueChnage}

                                                    />
                                                </div>
                                            </Col>
                                            <Col md="3">
                                                <div className="mb-3">
                                                    <Label htmlFor="validationCustom01">Blood Group</Label>
                                                    <AvField
                                                        name="blood_group"
                                                        placeholder="Blood Group"
                                                        type="text"
                                                        className="form-control"
                                                        id="validationCustom01"
                                                        value={masterObj?.blood_group || ""}
                                                        onChange={handlefieldValueChnage}

                                                    />
                                                </div>
                                            </Col>
                                            <Col md="3">
                                                <div className="mb-3">
                                                    <Label htmlFor="validationCustom03">Address</Label>
                                                    <AvField
                                                        name="address"
                                                        type="textarea"
                                                        className="form-control"
                                                        id="validationCustom03"
                                                        value={masterObj?.address || ""}
                                                        onChange={handlefieldValueChnage}

                                                    />
                                                </div>
                                            </Col>
                                            <Col md="3">
                                                <div className="mb-3">
                                                    <Label htmlFor="validationCustom03">Image</Label>
                                                    {masterObj?.user_image ? (
                                                        <div div className="img-wraps">
                                                            {masterObj.user_image && masterObj.user_image.startsWith("http") ? (
                                                                <img alt="" width="150" height="150" src={`${masterObj.user_image}`} />
                                                            ) : (
                                                                <img alt="" width="150" height="150" src={`${API_URL}uploads/user_images/${masterObj.user_image}`} />
                                                            )}
                                                            <button
                                                                className="btn btn-danger btn-sm btn-block waves-effect waves-light btn btn-danger"
                                                                onClick={deleteUserImage}
                                                                style={{ width: "150px" }}
                                                            >
                                                                Delete
                                                            </button>
                                                        </div>
                                                    ) : (
                                                        <AvField
                                                            name="user_image"
                                                            type="file"
                                                            className="form-control"
                                                            id="validationCustom03"
                                                            onChange={uploadImage}
                                                            rows="1" />
                                                    )}

                                                </div>
                                            </Col>
                                            <Col md="3">
                                                <div className="mb-3">
                                                    <Label htmlFor="validationCustom03">Document</Label>
                                                    {masterObj?.user_documents ? (
                                                        <div div className="img-wraps">
                                                            {masterObj.user_documents && masterObj.user_documents.startsWith("http") ? (
                                                                <img alt="" width="150" height="150" src={`${masterObj.user_documents}`} />
                                                            ) : (
                                                                <img alt="" width="150" height="150" src={`${API_URL}uploads/user_images/${masterObj.user_documents}`} />
                                                            )}
                                                            <button
                                                                className="btn btn-danger btn-sm btn-block waves-effect waves-light btn btn-danger"
                                                                onClick={deleteUserDocument}
                                                                style={{ width: "150px" }}
                                                            // type="reset"
                                                            >
                                                                Delete
                                                            </button>
                                                        </div>
                                                    ) : (
                                                        <AvField
                                                            name="user_documents"
                                                            type="file"
                                                            className="form-control"
                                                            id="validationCustom03"
                                                            onChange={uploadDocument}
                                                            rows="1" />
                                                    )}

                                                </div>
                                            </Col>

                                            <Col md="1" className="mt-4" style={{ paddingTop: "5px" }}>
                                                <Button color={masterObj?._id ? "warning" : "primary"} type="submit" >
                                                    {masterObj?._id ? "Update" : "Submit"}
                                                </Button>
                                            </Col>
                                            <Col md="1" className="mt-4" style={{ paddingTop: "5px" }}>
                                                <Button onClick={handleReset} color="danger" type="button" >
                                                    Reset
                                                </Button>
                                            </Col>

                                        </Row>
                                    </AvForm>
                                </CardBody>
                            </Card>

                        </Col>
                    </Row>
                    <Row>
                        <Card>
                            <CardBody>
                                <Row>
                                    {userPrivilege && userPrivilege == "61fa7275a7cd7dbee0b92b7b" ? (
                                        <Row className="mb-3">
                                            <Col md="3">
                                                <div className="mb-3">
                                                    <Label>Localbody</Label>
                                                    <Select
                                                        name="local_id"
                                                        value={filterSelected?.local_id || null}
                                                        onChange={(selected) => {
                                                            handleSearchSelectChange(selected, "local_id");
                                                        }}
                                                        options={localBodyForSearchOption}
                                                        classNamePrefix="select2-selection"
                                                    />
                                                </div>
                                            </Col>
                                            <Col md="3">
                                                <div className="mb-3">
                                                    <Label>Status</Label>
                                                    <Select
                                                        name="status"
                                                        value={filterSelected?.status}
                                                        classNamePrefix="select2-selection"
                                                        options={[
                                                            { label: "UnBlocked", value: 0 },
                                                            { label: "Resigned", value: 1 },
                                                            { label: "Blocked", value: 2 },
                                                        ]}
                                                        onChange={(selected) => {
                                                            handleSearchSelectChange(selected, "status");
                                                        }}
                                                    />
                                                </div>
                                            </Col>
                                            <Col md="3">
                                                <div style={{ display: "flex" }}>


                                                    <div className="mt-4 mb-3 mx-1" style={{ paddingTop: "5px" }}>
                                                        <Button color="success" type="submit">
                                                            <CSVLink data={dataToBeExported} filename={"Users_" + getDate(new Date()) + ".xls"} style={{ color: "white" }}>
                                                                Export
                                                            </CSVLink>
                                                        </Button>
                                                    </div>

                                                    <div className="mt-4 mb-3 mx-1" style={{ paddingTop: "5px" }}>
                                                        <Button color="danger" type="reset"
                                                            onClick={() => resetFilter()}
                                                        >
                                                            Reset
                                                        </Button>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                    ) : null}
                                </Row>
                                <Row>
                                    <MDBDataTable
                                        id="userTableId"
                                        responsive
                                        bordered
                                        data={data}
                                    // searching={true}
                                    // info={true}
                                    // disableRetreatAfterSorting={true}
                                    // entries={20}
                                    />
                                </Row>
                            </CardBody>
                        </Card>
                    </Row>

                </div>
            </div >

        </>
    )
}

export default User
