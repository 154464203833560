import React, { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { MDBDataTable } from "mdbreact";
import toastr, { options } from "toastr";
import { post, put } from "../../../helpers/api_helper";
import Select from "react-select";
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Label,
  Table,
  Container,
} from "reactstrap";
import { OverlayTrigger, Tooltip, Modal } from "react-bootstrap";
import { Box, Divider } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";
import ArticleIcon from "@mui/icons-material/Article";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import Inventory2Icon from "@mui/icons-material/Inventory2";
import CancelIcon from "@mui/icons-material/Cancel";
// import Select from "react-select";
import { AvForm, AvField } from "availity-reactstrap-validation";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import RingVolumeIcon from '@mui/icons-material/RingVolume';
import { isArray } from "lodash";
import Editcustomermodal from "../AddCustomer/Editcustomermodal";

// import CardActions from "@mui/material/CardActions";
// import CardContent from "@mui/material/CardContent";
// import Typography from "@mui/material/Typography";

// import TextField from "@mui/material/TextField";

//import Table from "@mui/material/Table";
// import TableContainer from "@mui/material/TableContainer";
// import TableHead from "@mui/material/TableHead";
// import TableBody from "@mui/material/TableBody";
import { styled } from "@mui/material/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Slide from "@mui/material/Slide";

import LocationCityIcon from "@mui/icons-material/LocationCity";
import DescriptionIcon from "@mui/icons-material/Description";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import "./ViewCustomer.scss";
import axios from "axios";
import accessToken from "../../../helpers/jwt-token-access/accessToken";
import { getLocalbody, formatMoney, getUserId } from "../../../helpers/globalFunctions";
import moment from "moment";
import ReceiptIcon from "@mui/icons-material/Receipt";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import logoDark from "../../../assets/images/logo-dark.svg";
import { isEmpty } from "lodash";
import CustomerComplaints from "./CustomerComplaints";
//import CallIcon from "@mui/icons-material/Call";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ViewCustomer = (props) => {
  const formRef = useRef();
  const [showDeleteReceipt, setShowDeleteReceipt] = useState(false);
  const [details, setDetails] = useState({});
  const [visitLogData, setVisitLogData] = useState([]);
  const [receiptData, setReceiptData] = useState([]);
  const [statementData, setStatementData] = useState([]);
  const [currentLocalbody, setCurrectLocalbody] = useState(getLocalbody());
  const [creditNote, setCreditNote] = useState([]);
  const [invoiceData, setInvoiceData] = useState([]);
  const [agentNumber, setAgentNumber] = useState("");
  const [wallet, setWallet] = useState([]);
  const [popupView, setPopupView] = useState(false);
  const [popupData, setPopupData] = useState({});
  const [wasteItemDetails, setWasteItemDetails] = useState([]);
  const [packageData, setPackageData] = useState({});
  const [tariffInfo, setTariffInfo] = useState([]);
  const [invoicePopupView, setInvoicePopupView] = useState(false);
  const [invoicePopupData, setInvoicePopupData] = useState({});
  const [selectedPayment, setSelectedPayment] = useState(null);
  const [receiptObj, setReceiptObj] = useState({ receipt_type: 0 });
  const [errors, setErrors] = useState({ paymentName: "" });
  const [creditObj, setCreditObj] = useState({});
  const [showEditModal, setshowEditModal] = useState(false)
  const [categoryOption, setcategoryOption] = useState([])

  //const dispatch = useDispatch();
  const { customeId } = props.match.params;
  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";
  function tToggle() {
    var body = document.body;
    body.classList.toggle("vertical-collpsed");
    body.classList.toggle("sidebar-enable");
  }
  useEffect(() => {
    if (customeId) {
      handleTableData(customeId);
      getCustStatement(customeId);
      fetchCreditNote(customeId);
      fetch_all_invoice(customeId);
      fetch_user_details();
      fetch_all_visitlog(customeId);
      fetch_all_receipt(customeId);
      fetch_all_wallet(customeId);
      fetch_package(customeId);
    }
    tToggle();
    return () => {
      tToggle();
    };
  }, []);

  function handleTableData(id) {
    axios
      .get(`${API_URL}customers` + "/" + id, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var result = res.data.data;
        result &&
          result.forEach((element) => {
            let desig = element.cust_designation || {};
            element.designation = desig?.desig_name;
            let custWard = element.ward || {};
            element.ward_name = custWard?.ward_name;
            element.group_name = "";
            element.cust_group_id &&
              element.cust_group_id.forEach((el) => {
                if (element.group_name === "")
                  element.group_name = el?.group_name;
                else
                  element.group_name =
                    element.group_name + "," + el?.group_name;
              });
            let custDistrict = element.district || {};
            element.district_name = custDistrict?.district_name;
            let custPackage = element.cust_package_id || {};
            element.package_name = custPackage?.package_name;
            let custType = element.cust_type || {};
            element.type = custType?.customer_type_name;
            element.Date = moment(element.cust_date).format("DD-MM-YYYY");
            let custstatus = Number(element?.cust_status);

            if (custstatus === 0)
              element.custStatus = (
                <Label style={{ color: "green" }}>Active</Label>
              );
            else if (custstatus === 2)
              element.custStatus = (
                <Label style={{ color: "red" }}>Non Corporative</Label>
              );
            else if (custstatus === 3)
              element.custStatus = (
                <Label style={{ color: "red" }}>Blocked</Label>
              );
            if (custstatus === 0)
              element.custStatus = (
                <Label style={{ color: "green" }}>Active</Label>
              );
            else if (custstatus === 2)
              element.custStatus = (
                <Label style={{ color: "red" }}>Non Corporative</Label>
              );
            else if (custstatus === 3)
              element.custStatus = (
                <Label style={{ color: "red" }}>Blocked</Label>
              );
            if (element?.cust_visit_day === 0) {
              element.day = "Sunday";
            } else if (element?.cust_visit_day === 1) {
              element.day = "Monday";
            } else if (element?.cust_visit_day === 2) {
              element.day = "Tuesday";
            } else if (element?.cust_visit_day === 3) {
              element.day = "Wednesday";
            } else if (element?.cust_visit_day === 4) {
              element.day = "Thursday";
            } else if (element?.cust_visit_day === 5) {
              element.day = "Friday";
            } else if (element?.cust_visit_day === 6) {
              element.day = "Saturday";
            }
            setDetails(element);
          });
      });
  }
  const fetch_all_receipt = (id) => {
    axios
      .get(`${API_URL}customers/receipt/list?customer_id=` + id, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let receiptData = res.data.data;
        receiptData &&
          receiptData.map((item, index) => {
            if (item?.receipt_deleted_status === 1) {
              item.id = <span className="text-danger">{index + 1}</span>;
              item.time = (
                <span className="text-danger">
                  {moment(item.receipt_time, "HHmmss").format("hh:mm a")}
                </span>
              );
              item.receipt_date = (
                <span className="text-danger">
                  {moment(item.receipt_date).format("DD-MM-YYYY")}
                </span>
              );
              item.receipt_no = (
                <span className="text-danger">{item?.receipt_no}</span>
              );
              item.reciept_amount = (
                <span className="text-danger">{item?.reciept_amount}</span>
              );
              item.staff = <span className="text-danger">{item?.staff}</span>;
            } else {
              item.id = index + 1;
              item.time = moment(item.receipt_time, "HHmmss").format("hh:mm a");
              item.receipt_date = moment(item.receipt_date).format(
                "DD-MM-YYYY"
              );
            }

            item.action = (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <i
                  className="far fa-trash-alt"
                  style={{ fontSize: "1em", cursor: "pointer" }}
                  onClick={() => {
                    showDeleteForm(item);
                  }}
                ></i>
              </div>
            );
          });
        setReceiptData(receiptData);
      });
  };
  const fetch_all_visitlog = (id) => {
    axios
      .get(`${API_URL}customers/visitlog/list?customer_id=` + id, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let visitLog = res.data.data;
        visitLog &&
          visitLog.map((item, index) => {
            if (item?.waste_clt_delete_status === 1) {
              item.id = <span className="text-danger">{index + 1}</span>;
              item.time = (
                <span className="text-danger">
                  {moment(item.waste_clt_time, "HHmmss").format("hh:mm a")}
                </span>
              );
              item.receipt_date = (
                <span className="text-danger">
                  {moment(item.waste_clt_date).format("DD-MM-YYYY")}
                </span>
              );
              item.staff = <span className="text-danger">{item?.staff}</span>;
              item.waste_clt_status = (
                <span className="text-danger">{item?.waste_clt_status}</span>
              );
              item.waste_clt_total_amount = (
                <span className="text-danger">
                  {item?.waste_clt_total_amount}
                </span>
              );
            } else {
              item.id = index + 1;
              item.time = moment(item.waste_clt_time, "HHmmss").format(
                "hh:mm a"
              );
              item.date = moment(item.waste_clt_date).format("DD-MM-YYYY");
            }

            item.action = (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <i
                  className="fas fa-eye"
                  style={{
                    fontSize: "1em",
                    cursor: "pointer",
                    marginLeft: "0.1em",
                    marginRight: "0.1em",
                  }}
                  onClick={() => {
                    handleClickOpenForm(item);
                  }}
                ></i>
              </div>
            );
          });
        setVisitLogData(visitLog);
      });
  };
  const fetch_package = (id) => {
    axios
      .get(`${API_URL}customers/package/list?customer_id=` + id, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let result = res.data.data;
        let tariffData = res.data.tarriff_info;
        result &&
          result.map((item) => {
            // if (item?.cust_type === 1) item.category = "Residence";
            // else if (item?.cust_type === 0) item.category = "Commercial";
            // else item.category = "";
            setPackageData(item);
          });
        tariffData &&
          tariffData.map((element, index) => {
            element.id = index + 1;
            element.date = moment(element.tariff_assign_date).format(
              "DD-MM-YYYY"
            );
            element.time = moment(element.tariff_assign_time, "HHmmss").format(
              "hh:mm a"
            );
            if (element.tariff_assign_active_status === 0)
              element.status = "Active";
            else element.status = "Inactive";
          });
        setTariffInfo(tariffData);
      });
  };
  const handleClickOpenForm = (row) => {
    let data = {
      collection_id: row._id,
    };
    axios
      .post(`${API_URL}waste/helper_collection_view`, data, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let result = res.data.data;
        result &&
          result.map((item) => {
            if (item?.staff_lastname)
              item.staff = item.staff_firstname + " " + item.staff_lastname;
            else item.staff = item.staff_firstname;
            item.time = moment(item.waste_clt_time, "HHmmss").format("hh:mm a");

            let itemInfo = item.items_info;
            itemInfo &&
              itemInfo.map((item, index) => {
                item.id = index + 1;
              });
            setWasteItemDetails(itemInfo);
            setPopupData(item);
          });

        setPopupView(true);
      });
  };

  const fetch_all_wallet = (id) => {
    axios
      .get(`${API_URL}customers/wallet/list?customer_id=` + id, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let result = res.data.data;
        result &&
          result.map((item, index) => {
            item.id = index + 1;
            item.time = moment(item.wlog_time, "HHmmss").format("hh:mm a");
            item.date = moment(item.wlog_date).format("DD-MM-YYYY");
            let addedBy = item.wlog_added_by || {};
            item.staff = addedBy?.firstName;
            if (item.wlog_status === 0) item.type = "Debit";
            else item.type = "Credit";
          });
        setWallet(result);
      });
  };
  const fetch_user_details = () => {
    let userid = "";
    if (localStorage.getItem("authUser")) {
      var data = localStorage.getItem("authUser");
      var user_obj = JSON.parse(data);
      userid = user_obj._id;
    }
    let Userdata = {
      user_id: userid,
    };
    axios
      .post(`${API_URL}api/staff/user_profile`, Userdata, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let result = res.data.result;
        if (result && result.length > 0) {
          setAgentNumber(result[0].mobile);
        }
      });
  };

  function getCustStatement(id) {
    let data = { customer_id: id };
    axios
      .post(
        `${API_URL}customers/statementlist` +
        "?from_date=" +
        "" +
        "&to_date=" +
        "",
        data,
        {
          headers: {
            "x-access-token": accessToken,
          },
        }
      )
      .then((response) => {
        let result = response.data.data;
        result &&
          result.map((item, index) => {
            if (item?.check_status === 1) {
              item.statemnt = (
                <span className="text-danger">{item?.statement_type}</span>
              );
            } else if (item?.check_status === 2) {
              item.statemnt = (
                <>
                  {item?.statement_type}{" "}
                  <i
                    className="fas fa-sync-alt"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      handleCheque(item._id);
                    }}
                  ></i>
                </>
              );
            } else {
              item.statemnt = item?.statement_type;
            }

            if (item?.check_status === 1) {
              item.id = <span className="text-danger">{index + 1}</span>;
              item.date = (
                <span className="text-danger">
                  {moment(item?.date).format("DD-MM-YYYY")}
                </span>
              );
              item.time = (
                <span className="text-danger">
                  {moment(item?.time, "hh:mm s").format("hh:mm a")}
                </span>
              );
              item.reference_no = (
                <span className="text-danger">{item?.reference_no}</span>
              );
              // item.statement_type = <span className="text-danger">{item?.statement_type}</span>
              item.debit = <span className="text-danger">{item?.debit}</span>;
              item.credit = <span className="text-danger">{item?.credit}</span>;
              item.balance = (
                <span className="text-danger">{item?.balance}</span>
              );
            } else {
              item.id = index + 1;
              item.date = moment(item?.date).format("DD-MM-YYYY");
              item.time = moment(item?.time, "hh: mm s").format("hh:mm a");
            }

            // item.CustomerName = details?.cust_name;
          });
        let unbuilled = response.data.unbilled;
        let newArr = unbuilled.concat(result);
        newArr &&
          newArr.map((element, index) => {
            if (element.statement_type === "Unbilled") {
              element.debit = element?.vcount_month_due;
              element.credit = 0;
            }
            element.id = index + 1;
          });
        setStatementData(newArr);
      });
  }

  function handleCheque(id) {
    axios
      .post(
        `${API_URL}api/staff/cheque/rebounce`,
        { statement_id: id },
        {
          headers: {
            "x-access-token": accessToken,
          },
        }
      )
      .then((res) => {
        if (res.data.status === 200) {
          toastr.success(res.data.message);
          getCustStatement(customeId);
        }
      });
  }

  function fetchCreditNote(id) {
    let data = { customer_id: id };
    axios
      .post(`${API_URL}customers/creditnotelist`, data, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((response) => {
        let result = response.data.data;
        result &&
          result.map((item, index) => {
            item.id = index + 1;
            item.time = moment(item.credit_note_time, "HHmmss").format(
              "hh:mm a"
            );
            item.date = moment(item.credit_note_date).format("DD-MM-YYYY");
          });
        setCreditNote(result);
      });
  }

  function fetch_all_invoice(id) {
    axios
      .get(`${API_URL}customers/invoice/list?customer_id=` + id, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let result = res.data.data;
        result &&
          result.map((item, index) => {
            item.id = index + 1;
            item.time = moment(item.invoice_time, "HHmmss").format("hh:mm a");
            item.date = moment(item.invoice_date).format("DD-MM-YYYY");
            let invoiceAddedby = item.invoice_addedby || {};
            item.staff = invoiceAddedby?.firstName;
            item.action = (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <i
                  className="fas fa-eye"
                  style={{
                    fontSize: "1em",
                    cursor: "pointer",
                    marginLeft: "0.1em",
                    marginRight: "0.1em",
                  }}
                  onClick={() => {
                    handleClick(item);
                  }}
                ></i>
                <i
                  className="fas fa-print"
                  style={{
                    fontSize: "1em",
                    cursor: "pointer",
                    marginLeft: "0.2rem",
                    marginRight: "0.5rem",
                  }}
                  onClick={() => {
                    downloadPDF(item);
                  }}
                ></i>
              </div>
            );
          });
        setInvoiceData(result);
      });
  }
  const handleClick = (item) => {
    axios
      .get(`${API_URL}invoice/autoview?invoice_id=` + item._id, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let result = res.data.data.invoice;
        result &&
          result.map((el, index) => {
            el.company &&
              el.company.map((company) => {
                el.companyMob = company?.company_mob;
                el.companyEmail = company?.company_email;
                el.companyWeb = company?.company_web;
                el.companyAddress = company?.company_address;
              });
            el.groupName = "";
            el.group &&
              el.group.map((group) => {
                if (el.groupName === "") el.groupName = group;
                else el.groupName = el.groupName + group;
              });
            setInvoicePopupData(el);
            setInvoicePopupView(true);
          });
      });
  };
  // const dispatch = useDispatch();
  const downloadPDF = (item) => {
    window.open(`${API_URL}invoice/view/` + item?._id);
    // saveAs(`${API_URL}` + item?.pdf_link, item?.receipt_no + ".pdf");
  };
  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 150,
      },
      {
        label: "Date",
        field: "date",
        sort: "asc",
        width: 400,
      },
      {
        label: "Time",
        field: "time",
        sort: "asc",
        width: 200,
      },
      {
        label: "Amount",
        field: "waste_clt_total_amount",
        sort: "asc",
        width: 200,
      },
      {
        label: "Staff",
        field: "staff",
        sort: "asc",
        width: 200,
      },
      {
        label: "Status",
        field: "waste_clt_status",
        sort: "asc",
        width: 200,
      },
      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 100,
      },
    ],
    rows: visitLogData,
  };
  const receiptTableData = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 150,
      },
      {
        label: "Date",
        field: "receipt_date",
        sort: "asc",
        width: 400,
      },
      {
        label: "Time",
        field: "time",
        sort: "asc",
        width: 200,
      },
      {
        label: "Receipt No",
        field: "receipt_no",
        sort: "asc",
        width: 200,
      },
      {
        label: "Amount",
        field: "reciept_amount",
        sort: "asc",
        width: 200,
      },
      {
        label: "Staff",
        field: "staff",
        sort: "asc",
        width: 200,
      },
      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 200,
      },
    ],
    rows: receiptData,
  };
  const statementTableData = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 150,
      },
      {
        label: "Date",
        field: "date",
        sort: "asc",
        width: 400,
      },
      {
        label: "Time",
        field: "time",
        sort: "asc",
        width: 200,
      },
      //   {
      //     label: "Customer Name",
      //     field: "CustomerName",
      //     sort: "asc",
      //     width: 200,
      //   },
      {
        label: "Ref No",
        field: "reference_no",
        sort: "asc",
        width: 200,
      },
      {
        label: "Type",
        field: "statemnt",
        sort: "asc",
        width: 200,
      },

      {
        label: "Debit",
        field: "debit",
        sort: "asc",
        width: 200,
      },
      {
        label: "Credit",
        field: "credit",
        sort: "asc",
        width: 200,
      },
      {
        label: "Balance",
        field: "balance",
        sort: "asc",
        width: 200,
      },
    ],
    rows: statementData,
  };

  const creditNoteTableData = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 150,
      },
      {
        label: "Date",
        field: "date",
        sort: "asc",
        width: 400,
      },
      {
        label: "Time",
        field: "time",
        sort: "asc",
        width: 200,
      },
      {
        label: "Ref No",
        field: "credit_note_reg_no",
        sort: "asc",
        width: 200,
      },

      {
        label: "Amount",
        field: "credit_note_creditamt",
        sort: "asc",
        width: 200,
      },
      {
        label: "Balance",
        field: "credit_note_balance",
        sort: "asc",
        width: 200,
      },
      {
        label: "Remarks",
        field: "credit_note_comment",
        sort: "asc",
        width: 200,
      },
      {
        label: "Staff",
        field: "staff",
        sort: "asc",
        width: 200,
      },
    ],
    rows: creditNote,
  };

  const invoiceTableData = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 150,
      },
      {
        label: "Date",
        field: "date",
        sort: "asc",
        width: 400,
      },
      // {
      //   label: "Time",
      //   field: "time",
      //   sort: "asc",
      //   width: 200,
      // },
      {
        label: "Invoice No",
        field: "invoice_no",
        sort: "asc",
        width: 200,
      },
      {
        label: "Package",
        field: "invoice_package_name",
        sort: "asc",
        width: 200,
      },
      {
        label: "Amount",
        field: "invoice_total_amt",
        sort: "asc",
        width: 200,
      },
      {
        label: "Staff",
        field: "staff",
        sort: "asc",
        width: 200,
      },
      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 50,
      },
    ],
    rows: invoiceData,
  };
  const walletTableData = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 100,
      },
      {
        label: "Date",
        field: "date",
        sort: "asc",
        width: 200,
      },
      {
        label: "Time",
        field: "time",
        sort: "asc",
        width: 100,
      },
      {
        label: "Type",
        field: "type",
        sort: "asc",
        width: 100,
      },
      {
        label: "Amount",
        field: "wlog_amount",
        sort: "asc",
        width: 150,
      },
      {
        label: "Balance",
        field: "wlog_balance",
        sort: "asc",
        width: 150,
      },
      {
        label: "Staff",
        field: "staff",
        sort: "asc",
        width: 150,
      },
      // {
      //   label: "Message",
      //   field: "wlog_message",
      //   sort: "asc",
      //   width: 200,
      // },
    ],
    rows: wallet,
  };
  const customerViewTariffData = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 100,
      },
      {
        label: "Date",
        field: "date",
        sort: "asc",
        width: 300,
      },
      {
        label: "Time",
        field: "time",
        sort: "asc",
        width: 100,
      },
      {
        label: "Name",
        field: "package_name",
        sort: "asc",
        width: 100,
      },
      {
        label: "Reg.No",
        field: "tariff_reg_number",
        sort: "asc",
        width: 200,
      },
      {
        label: "Company",
        field: "company_name",
        sort: "asc",
        width: 200,
      },
      {
        label: "Status",
        field: "status",
        sort: "asc",
        width: 200,
      },
      {
        label: "Staff",
        field: "staff",
        sort: "asc",
        width: 200,
      },
    ],
    rows: tariffInfo,
  };

  function createData(name, calories, fat, carbs, protein) {
    return { name, calories, fat, carbs, protein };
  }

  const showDeleteForm = (item) => {
    let cust_id = "";
    let val = item?.receipt_cust_detail || [];
    val &&
      val.map((el) => {
        cust_id = el?._id;
      });
    let data = {
      customer_id: cust_id,
      localbody_id: currentLocalbody,
    };

    axios
      .post(`${API_URL}api/staff/receipt_otp`, data, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        if (res.data.success === true) {
          toastr.success("OTP Send Successfully");
          setReceiptObj({
            otp_gen: res.data.otp,
            cust_phone: item?.custPhone,
            receipt_id: item?._id,
          });
          setShowDeleteReceipt(true);
        } else {
          toastr.error(res.data.message);
          return;
        }
      })
      .catch((err) => {
        toastr.error(err.response.data.message);
        return;
      });
  };

  //   const rows = [
  //     createData("Frozen yoghurt", 159, 6.0, 24, 4.0),
  //     createData("Ice cream sandwich", 237, 9.0, 37, 4.3),
  //     createData("Eclair", 262, 16.0, 24, 6.0),
  //   ];

  // const [value, setValue] = React.useState('1');

  // const handleModalChange = (event, newValue) => {
  //   setValue(newValue);
  // };

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [tab, setTab] = React.useState("1");

  const handleTablChange = (event, newValue) => {
    setTab(newValue);
  };

  const [tab1, setTab1] = React.useState("1");

  const handleTablChange1 = (event, newValue) => {
    setTab1(newValue);
  };

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  function createData(si, date, time, staff) {
    return { si, date, time, staff };
  }

  const rows1 = [createData(1, 159, 6.0, 24), createData(2, 237, 9.0, 37)];

  // Receipt table
  const StyledTableCell1 = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow1 = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  function createData1(si, date, receiptno, amount, dueamount, staff) {
    return { si, date, receiptno, amount, dueamount, staff };
  }

  const rows2 = [
    createData1(1, "24 - 11 - 2021", "RE2111000004", "50/-", "50/-", "Prabija"),
    createData1(
      2,
      "24 - 11 - 2021",
      "RE2111000005",
      "400/-",
      "-350/-",
      "Prabija"
    ),
  ];

  function createData3(
    si,
    date,
    time,
    referenceNo,
    customerId,
    customerName,
    amount,
    comment,
    due,
    staff
  ) {
    return {
      si,
      date,
      time,
      referenceNo,
      customerId,
      customerName,
      amount,
      comment,
      due,
      staff,
    };
  }

  const rows3 = [
    createData3(
      1,
      "26-11-2020",
      "11:11:24",
      123,
      123,
      "admin",
      700,
      "abc",
      100,
      "srv"
    ),
  ];

  const [tab3, setTab3] = React.useState("7");

  const handleChangeTab = (event, newValue) => {
    setTab3(newValue);
  };

  const [date, setDate] = React.useState(new Date("2014-08-18T21:11:54"));

  const handleChangeDate = (newValue) => {
    setDate(newValue);
  };
  const customerCall = () => {
    let data = {
      customer_no: 0 + details?.cust_phone,
      agent_no: 0 + agentNumber,
      localbody: currentLocalbody,
    };
    axios
      .post(`${API_URL}api/staff/customer_call`, data, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((response) => {
        let result = response.data;
        if (result && result.success === true) {
          toastr.success("Call Send successfully");
        } else {
          toastr.error(result.message);
        }
      });
  };
  const wasteCollectingItemDetails = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 150,
      },
      {
        label: "Item Name",
        field: "waste_clt_item_name",
        sort: "asc",
        width: 150,
      },
      {
        label: "Price",
        field: "waste_clt_item_price",
        sort: "asc",
        width: 150,
      },
      {
        label: "Bags",
        field: "waste_clt_item_no_bag",
        sort: "asc",
        width: 150,
      },
      {
        label: "Weight",
        field: "waste_clt_item_no_kg",
        sort: "asc",
        width: 150,
      },
      {
        label: "Total",
        field: "waste_clt_item_amt",
        sort: "asc",
        width: 150,
      },
    ],
    rows: wasteItemDetails,
  };
  function createData4(
    si,
    date,
    time,
    customerName,
    type,
    invoiceNo,
    debit,
    credit,
    balance
  ) {
    return {
      si,
      date,
      time,
      customerName,
      type,
      invoiceNo,
      debit,
      credit,
      balance,
    };
  }

  const rows4 = [
    createData4(
      1,
      "26-11-2020",
      "11:11:24",
      "admin",
      "Invoice",
      "SRV123",
      700,
      100,
      100
    ),
  ];

  function handleSelectChange(selected, name) {
    switch (name) {
      case "receipt_payment_type":
        setSelectedPayment(selected);
        setReceiptObj({
          ...receiptObj,
          receipt_payment_type: selected.value,
        });
        break;

      default:
        break;
    }
  }

  function handleChangeInput(e) {
    let name = e.target.name;
    let value = e.target.value;
    setReceiptObj({ ...receiptObj, [name]: value });
  }

  const resetReceipt = () => {
    setSelectedPayment(null);
    fetch_all_receipt();
    setReceiptObj({});
    formRef.current.reset();
  };

  function handleReceiptSubmit() {
    if (localStorage.getItem("authUser")) {
      var data = localStorage.getItem("authUser");
      const obj = JSON.parse(data);
      var userId = obj._id;
    }

    let receiptData = {
      customer_id: details?._id,
      receipt_amount: receiptObj?.receipt_amount,
      receipt_payment_type: receiptObj?.receipt_payment_type,
      receipt_type: receiptObj?.receipt_type,
      user_id: userId,
    };
    if (selectedPayment === null) {
      let errorVal = errors;
      if (selectedPayment === null) {
        errorVal.paymentName = "Please select payment method";
      }
      setErrors({
        ...errorVal,
      });
    } else {
      axios
        .post(`${API_URL}api/staff/add_reciept`, receiptData, {
          headers: {
            "x-access-token": accessToken,
          },
        })
        .then((res) => {
          if (res.data.success === true) {
            toastr.success("Receipt added successfully");
            formRef.current.reset();
            setSelectedPayment(null);
            setReceiptObj({
              ...receiptObj,
              receipt_type: 0,
              // user_id: userId,
            });
            fetch_all_receipt();
            setErrors({
              ...errors,
              paymentName: "",
            });
          } else {
            toastr.error("Failed to add");
            return;
          }
        })
        .catch((err) => {
          toastr.error(err.response.data.message);
          return;
        });
    }
  }

  function handleCreditSubmit() {
    let creditData = {
      customer_id: details?._id,
      total_due: details?.cust_due,
      amount: creditObj?.amount,
      comment: creditObj?.comments,
    };
    axios
      .post(`${API_URL}customers/creditnote`, creditData, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        if (res.data.success === true) {
          toastr.success("Receipt added successfully");
          formRef.current.reset();
          setCreditObj({});
          fetchCreditNote(customeId);
        } else {
          toastr.error("Failed to add");
          return;
        }
      })
      .catch((err) => {
        toastr.error(err.response.data.message);
        return;
      });
  }

  function handleCreditChange(e) {
    let name = e.target.name;
    let value = e.target.value;
    setCreditObj({ ...creditObj, [name]: value });
  }

  // call log states and functions
  const initialState = {
    localbodyId: getLocalbody(),
    userId: getUserId(),
    image: [""],
  };
  const [master, setMaster] = useState(initialState);
  const [selected, setSelected] = useState({});
  const [followUp, setFollowUp] = useState();
  const [FollowUpTableData, setFollowUpTableData] = useState([]);
  const handleSelect = (name, e) => {
    const mastr = { ...master };
    const select = { ...selected };
    const value = e
      ? isArray(e)
        ? e.map((item) => item.value)
        : e.value
      : null;
    mastr[name] = value;
    select[name] = e;
    setMaster(mastr);
    setSelected(select);
  };
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    console.log(file, "audio");
    if (file) {
      const fd = new FormData();
      fd.append("file", file);
      axios
        .post(`${API_URL}complaint/audio/`, fd, {
          headers: {
            "x-access-token": accessToken,
          },
        })
        .then((response) => {
          if (response.data.status === 200) {
            setMaster((prev) => ({
              ...prev,
              audio: response.data.file.filename,
            }));


          } else {
            toastr.error(response.data.message);
          }
        })
        .catch((error) => {
          console.error("Error uploading file:", error);
        });
    }
  };
  const deleteComplaintImage = (e, idx) => {
    e.preventDefault();
    const prevImage = [...master.image];
    prevImage[idx] = "";
    setMaster((prevState) => ({
      ...prevState,
      image: prevImage,
    }));

    // FollowUp
    const popUpprevImage = [...followUp.image];
    popUpprevImage[idx] = "";

  };
  const uploadImage = (e, idx) => {
    const fd = new FormData();
    fd.append("complaint_image", e.target.files[0]);
    axios
      .post(`${API_URL}customercomplaint/complaintImage`, fd, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((response) => {
        if (response.data.status === "success") {
          let result = response.data.file[0];
          if (e.target.name === "image") {
            const prevImage = [...master.image];
            prevImage[idx] = result.filename;
            setMaster((prevState) => ({
              ...prevState,
              image: prevImage,
            }));
          } else {
            const popUpprevImage = [...followUp.image];
            popUpprevImage[idx] = result.filename;
            setFollowUp((prevState) => ({
              ...prevState,
              image: popUpprevImage,
            }));
          }
        } else {
          toastr.error(response.data.message);
        }
      });
  };
  const handleReset = () => {
    formRef.current.reset();
    setMaster(initialState);
    setSelected({
      complaintType: "",
      wardId: "",
      category: "",
      against: "",
    });
  };

  const complaintTableData = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 150,
      },
      {
        label: "Date",
        field: "date",
        sort: "asc",
        width: 150,
      },
      {
        label: "Time",
        field: "time",
        sort: "asc",
        width: 270,
      },
      // {
      //   label: "Complaint ID",
      //   field: "complaint_id",
      //   sort: "asc",
      //   width: 150,
      // },
      {
        label: "Status",
        field: "status",
        sort: "asc",
        width: 150,
      },
      {
        label: "Audio",
        field: "audio",
        sort: "asc",
        width: 150,
      },
      {
        label: "Image",
        field: "img",
        sort: "asc",
        width: 150,
      },
      // {
      //   label: "Assigned",
      //   field: "assigned",
      //   sort: "asc",
      //   width: 150,
      // },
      {
        label: "Comment",
        field: "followupComment",
        sort: "asc",
        width: 150,
      },

      {
        label: "AddedBy",
        field: "staff",
        sort: "asc",
        width: 150,
      },
    ],
    rows: FollowUpTableData,
  };



  function fetchAllCategory(id) {
    axios.get(`${API_URL}complaintcategory/list?type=${id}`, {
      headers: {
        "x-access-token": accessToken
      }
    }).then((res) => {
      let result = res.data.data
      let catoption = result.map((item, index) => {
        return {
          label: item?.category_name,
          value: item?._id,
        }
      })
      setcategoryOption(catoption)
    })
  }



  return (
    <React.Fragment>
      <Modal
        show={popupView}
        toggle={() => setPopupView(!popupView)}
        size="lg"
        centered={true}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0">Helper Collection Details </h5>
          <button
            type="button"
            onClick={() => {
              setPopupView(false);
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <AvForm className="needs-validation" onValidSubmit={(e, v) => { }}>
          <div className="modal-body">
            <table
              id="product_available_day"
              className="table table-bordered dataTable"
            >
              <tr>
                <td style={{ padding: "10px" }}>Date :</td>
                <td>{popupData?.waste_clt_date}</td>
                <td>Time :</td>
                <td style={{ textAlign: "left" }}>{popupData?.time}</td>
              </tr>
              <tr>
                <td style={{ padding: "10px" }}>Customer ID :</td>
                <td>{popupData?.cust_id}</td>
                <td>Name :</td>
                <td style={{ textAlign: "left" }}>{popupData?.cust_name}</td>
              </tr>
              <tr>
                <td style={{ padding: "10px" }}>Group :</td>
                <td>{popupData?.group_name}</td>
                <td>Ward :</td>
                <td style={{ textAlign: "left" }}>{popupData?.ward_name}</td>
              </tr>
              <tr>
                <td style={{ padding: "10px" }}>Amount :</td>
                <td>{popupData?.waste_clt_total_amount}</td>
                <td>Staff :</td>
                <td style={{ textAlign: "left" }}>{popupData?.staff}</td>
              </tr>
              <tr>
                <td style={{ padding: "10px" }}>Status :</td>
                <td>{popupData?.status_name}</td>
                <td></td>
                <td style={{ textAlign: "left" }}>{ }</td>
              </tr>
            </table>
            {wasteItemDetails && wasteItemDetails.length > 0 ? (
              <Row className="mt-3 mb-2">
                <h6
                  className="modal-title mt-0"
                  style={{ textAlign: "center" }}
                >
                  Helper Collection Item Details
                </h6>
              </Row>
            ) : null}
            {wasteItemDetails && wasteItemDetails.length > 0 ? (
              <Row>
                <MDBDataTable
                  id="wasteCollectionItemDetailId"
                  responsive
                  bordered
                  data={wasteCollectingItemDetails}
                  searching={false}
                  info={false}
                  paging={false}
                  disableRetreatAfterSorting={true}
                //entries={10}
                />
              </Row>
            ) : null}
          </div>
        </AvForm>
      </Modal>
      <Modal show={invoicePopupView} size="lg" centered={true}>
        <div className="modal-header">
          <h5 className="modal-title mt-0">View Invoice Details</h5>
          <button
            type="button"
            onClick={() => {
              setInvoicePopupView(false);
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <AvForm className="needs-validation" onValidSubmit={(e, v) => { }}>
          <div className="modal-body">
            <Container fluid>
              <Row>
                <Col lg="12">
                  <Card>
                    <CardBody>
                      <div className="invoice-title">
                        <h4 className="float-end font-size-16">
                          <span>
                            {" INVOICE "}
                            &nbsp;
                          </span>
                          <span>#</span>
                          <span style={{ color: "blue" }}>
                            {invoicePopupData?.invoice_no}
                          </span>
                        </h4>
                        <div className="mb-0">
                          <img src={logoDark} alt="logo" height="42" />
                        </div>
                        <div className="text-muted">
                          <h4 className="mb-1">{/* {} */}</h4>
                          <p className="mb-1">
                            {invoicePopupData?.companyAddress}
                          </p>
                          <p
                            className="mb-1"
                            hidden={!invoicePopupData?.companyEmail}
                          >
                            <i className="uil uil-envelope-alt me-1"></i>{" "}
                            {invoicePopupData?.companyEmail}
                          </p>
                          <p
                            className="mb-1"
                            hidden={!invoicePopupData?.companyMob}
                          >
                            <i className="uil uil-phone me-1"></i>{" "}
                            {invoicePopupData?.companyMob}
                          </p>
                          <p
                            className="mb-1"
                            hidden={!invoicePopupData?.companyWeb}
                          >
                            <i className="uil uil-google me-1"></i>{" "}
                            {invoicePopupData?.companyWeb}
                          </p>
                          <p className="mb-1">{/* {} */}</p>
                        </div>
                      </div>
                      <hr className="my-4" />
                      <Row>
                        <Col sm="6">
                          <div className="text-muted">
                            <h5 className="font-size-15 mb-2">
                              {invoicePopupData?.invoice_customer_name}
                            </h5>
                            <p className="mb-1">
                              {invoicePopupData?.customerId}
                            </p>
                            <p
                              className="mb-1"
                              hidden={!invoicePopupData?.cust_phone}
                            >
                              {" "}
                              <i className="uil uil-phone me-1"></i>
                              {invoicePopupData?.cust_phone}
                            </p>
                            <p className="mb-1">{invoicePopupData?.ward}</p>
                            <p className="mb-1">
                              {invoicePopupData?.groupName}
                            </p>
                            <p className="mb-1">
                              {/* <i className="uil uil-envelope-alt me-1"></i>{" "} */}
                              {/* {} */}
                            </p>
                          </div>
                        </Col>
                        <Col sm="6">
                          <div className="text-muted text-sm-end">
                            <div>
                              <h5 className="font-size-16 mb-1">
                                Invoice Date:
                              </h5>
                              <p>{invoicePopupData?.invoice_date}</p>
                            </div>
                            <div className="mt-4">
                              <h5 className="font-size-16 mb-1">
                                Invoice Time:
                              </h5>
                              <p>
                                {moment(
                                  invoicePopupData?.invoice_time,
                                  "h:mm:ss"
                                ).format("hh:mm a")}
                              </p>
                            </div>
                          </div>
                        </Col>
                      </Row>
                      <div className="py-2">
                        <div className="table-responsive mb-3">
                          <Table className="table-nowrap table-centered mb-0">
                            <thead>
                              <tr>
                                <th style={{ width: "70px" }}>#</th>
                                <th>Item Name</th>
                                <th>Quantity</th>
                                <th>Amount</th>
                                <th>Price</th>
                              </tr>
                            </thead>
                            <tbody>
                              {invoicePopupData.invoice_items &&
                                invoicePopupData.invoice_items.map(
                                  (item, key) => (
                                    <tr key={key}>
                                      <td className="text-center">{key + 1}</td>
                                      <td>{item?.inovice_item_name}</td>
                                      <td className="text-center">
                                        {item.invoice_item_quanty}
                                      </td>
                                      <td className="text-end">
                                        {item?.invoice_item_amt}
                                      </td>
                                      <td className="text-end">
                                        {item.invoice_item_price}
                                      </td>
                                    </tr>
                                  )
                                )}
                            </tbody>
                          </Table>
                        </div>
                        <Row className="pt-4">
                          <Col lg={7}>
                            <Row>
                              <Col md={12}></Col>
                            </Row>
                          </Col>
                          <Col md={1}></Col>
                          <Col>
                            <Table className="table-nowrap table-centered mb-0">
                              <tbody>
                                <tr>
                                  <th colSpan="4" className="text-end">
                                    Total Amount :
                                  </th>
                                  <td className="text-end">
                                    {formatMoney(
                                      invoicePopupData?.invoice_total_amt
                                    )}
                                  </td>
                                </tr>
                                <tr>
                                  <th colSpan="4" className="border-0 text-end">
                                    Taxable Amount :
                                  </th>
                                  <td className="border-0 text-end">
                                    {formatMoney(
                                      invoicePopupData?.invoice_gst_amt
                                    )}
                                  </td>
                                </tr>
                                <tr></tr>
                                <tr></tr>
                                <tr>
                                  <th colSpan="4" className="border-0 text-end">
                                    Payable Amount
                                  </th>
                                  <td className="border-0 text-end">
                                    <h4 className="m-0">
                                      {formatMoney(
                                        invoicePopupData?.totalAmount
                                      )}
                                    </h4>
                                  </td>
                                </tr>
                              </tbody>
                            </Table>
                          </Col>
                        </Row>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
          </div>
        </AvForm>
      </Modal>
      <Editcustomermodal showEditModal={showEditModal} setshowEditModal={setshowEditModal} customeId={customeId} />
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs
            title="Home"
            breadcrumbItem={"Customer" + " " + "-" + " " + details?.cust_reg_no}
          />
          <Row>
            <Col xl="12">
              {/* First Dialogue */}

              <div>
                <div className="row">
                  <div className="col-xl-3">
                    <div
                      className="card"
                      style={{
                        //width: "fit-content",
                        //width: "351px",
                        //width:"300px",
                        height: "max-content",
                        boxShadow: "2px 4px 7px 0px rgb(0 0 0 / 10%)",
                      }}
                    >
                      <div className="card-body">
                        <div className="float-end dropdown">
                          <div
                            tabindex="-1"
                            role="menu"
                            aria-hidden="true"
                            className="dropdown-menu-end dropdown-menu"
                          >
                            <a
                              to="/"
                              tabindex="0"
                              role="menuitem"
                              className="dropdown-item"
                            >
                              Edit
                            </a>
                            <a
                              to="/"
                              tabindex="1"
                              role="menuitem"
                              className="dropdown-item"
                            >
                              Action
                            </a>
                            <a
                              to="/"
                              tabindex="2"
                              role="menuitem"
                              className="dropdown-item"
                            >
                              Remove
                            </a>
                          </div>
                          {/* <div className="clearfix"></div> */}
                          {/* <div>
                                <img
                                  alt
                                  className="avatar-lg rounded-circle img-thumbnail"
                                  src="/static/media/avatar-4.b23e41d9.jpg"
                                />
                              </div> */}
                        </div>
                        {/* Customer Details Tab start */}

                        <Stack
                          direction="row"
                          spacing={2}
                          style={{ justifyContent: "center" }}
                        >
                          <a
                            href={`${API_URL}uploads/customers_images/${details?.cust_image}`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <Avatar
                              alt="Remy Sharp"
                              src={`${API_URL}uploads/customers_images/${details?.cust_image}`}
                              sx={{ width: 56, height: 56 }}
                              style={{
                                width: "6rem",
                                height: "6rem",
                                //marginTop: "20%",
                                marginBottom: "auto",
                              }}
                            />
                          </a>
                        </Stack>
                        {details?.cust_verification_status === 1 ? (
                          <a
                            aria-haspopup="true"
                            className="text-body font-size-16 ddropdown-toggle"
                            aria-expanded="false"
                          >
                            <CheckCircleIcon
                              style={{
                                fontSize: "20px",
                                color: "green",
                                position: "relative",
                                float: "right",
                                bottom: "83px",
                              }}
                            />
                          </a>
                        ) : (
                          <a
                            aria-haspopup="true"
                            className="text-body font-size-16 ddropdown-toggle"
                            aria-expanded="false"
                          >
                            <CancelIcon
                              style={{
                                fontSize: "20px",
                                color: "red",
                                position: "relative",
                                float: "right",
                                bottom: "83px",
                                // cursor: "pointer",
                              }}
                            />
                          </a>
                        )}
                        <h5
                          className="mt-3 mb-1"
                          style={{
                            textAlign: "center",
                            fontFamily: "IBM Plex Sans,sans-serif",
                          }}
                        >
                          {details?.cust_name}
                        </h5>
                        <div
                          className="text-muted"
                          style={{
                            textAlign: "center",
                            color: "green !important",
                            fontFamily: "IBM Plex Sans,sans-serif",
                            fontSize: "14px",
                            marginBottom: "3px",
                          }}
                        >
                          <div
                            class="btn-group btn-group-sm"
                            role="group"
                            aria-label="Basic example"
                          >
                            {/* <CallIcon style={{ width: "10px", height: "10px" }}/> */}
                            <OverlayTrigger
                              placement="top"
                              //delay={{ show: 250, hide: 400 }}
                              overlay={
                                <Tooltip
                                  id="button-tooltip-2"
                                  style={{ position: "absolute", top: "-30px" }}
                                >
                                  Call Now
                                </Tooltip>
                              }
                            >
                              <button
                                class="btn btn-success"
                                style={{
                                  borderRadius: "40px",
                                  marginRight: "10px",
                                  padding: "0",
                                  width: "28px",
                                }}
                                hidden={!details.cust_phone}
                                onClick={() => customerCall()}
                              >
                                <i
                                  className="uil-phone-alt"
                                  style={{ fontSize: "15px" }}
                                />
                              </button>
                            </OverlayTrigger>
                          </div>
                          {details?.cust_phone}
                        </div>
                        <p
                          className="text-muted"
                          style={{
                            textAlign: "center",
                            color: "green !important",
                            fontFamily: "IBM Plex Sans,sans-serif",
                            fontSize: "14px",
                            marginBottom: "3px",
                          }}
                          hidden={!details?.cust_whatsapp_no}
                        >
                          <div
                            class="btn-group btn-group-sm"
                            role="group"
                            aria-label="Basic example"
                          >
                            <button
                              class="btn btn-success"
                              style={{
                                borderRadius: "40px",
                                marginRight: "10px",
                                padding: "0",
                                width: "28px",
                              }}
                              hidden={!details.cust_whatsapp_no}
                            >
                              <i
                                className="uil-whatsapp"
                                style={{ fontSize: "15px" }}
                              //hidden={!details.cust_whatsapp_no}
                              />
                              {/* Call */}
                            </button>
                          </div>
                          {details?.cust_whatsapp_no}
                          {/* <img
                            //src="https://api.ir.ee/static/ssb_icon.png"
                            data-regcode="12336685"
                            
                          /> */}
                        </p>
                        <p
                          style={{
                            textAlign: "center",
                            marginBottom: "3px",
                          }}
                        >
                          {details?.custStatus}
                        </p>

                        <div
                          className="mt-3 mb-2"
                          style={{
                            textAlign: "center",
                            fontFamily: "IBM Plex Sans,sans-serif",
                          }}
                        >
                          <p onClick={() => setshowEditModal(true)} style={{ color: "blue" }}> Edit Profile <i style={{ fontSize: "16px", color: "blue", cursor: "pointer" }} class='far fa-edit mx-2'></i></p>
                        </div>


                        <Divider />


                        {/* <br /> */}
                        {/* <h5 className="font-size-16">About</h5>
                        <p style={{ fontFamily: "IBM Plex Sans,sans-serif" }}>
                          Hi I'm Marcus,has been the industry's standard dummy
                          text To an English person, it will seem like
                          simplified English, as a skeptical Cambridge.
                        </p> */}
                        {/* <div hidden={!details?.Date} className="mt-2">
                          <p className="mb-1">Date:</p>
                          <h6 className="font-size-14">{details?.Date}</h6>
                        </div> */}
                        <div hidden={!details?.Date} className="mt-2">
                          <p className="mb-1">Date:</p>
                          <h6 className="font-size-14">{details?.Date}</h6>
                        </div>
                        <div hidden={!details?.type} className="mt-2">
                          <p className="mb-1">Type:</p>
                          <h6 className="font-size-14">{details?.type}</h6>
                        </div>
                        <div hidden={!details?.designation} className="mt-2">
                          <p className="mb-1">Designation:</p>
                          <h6 className="font-size-14">
                            {details?.designation}
                          </h6>
                        </div>
                        <div hidden={!details?.cust_no_members}>
                          <p className="mb-1">Member Count:</p>
                          <h6 className="font-size-14">
                            {details?.cust_no_members}
                          </h6>
                        </div>
                        <div hidden={!details?.cust_house_num}>
                          <p className="mb-1">Building No:</p>
                          <h6 className="font-size-14">
                            {details?.cust_house_num}
                          </h6>
                        </div>
                        <div
                          hidden={
                            !details?.cust_address || !details?.district_name
                          }
                        >
                          <p className="mb-1">Address:</p>
                          <h6 className="font-size-14">
                            {details?.cust_address},
                            <br />
                            {details?.district_name}
                          </h6>
                        </div>
                        <div hidden={!details?.ward_name}>
                          <p className="mb-1">Ward:</p>
                          <h6 className="font-size-14">{details?.ward_name}</h6>
                        </div>
                        <div hidden={!details?.group_name}>
                          <p className="mb-1">Group:</p>
                          <h6 className="font-size-14">
                            {details?.group_name}
                          </h6>
                        </div>
                        <div hidden={!details?.cust_qr_code}>
                          <p className="mb-1">Qr Code:</p>
                          <h6 className="font-size-14">
                            {details?.cust_qr_code}
                          </h6>
                        </div>
                        <div hidden={!details?.cust_visit_day} className="mt-2">
                          <p className="mb-1">Visit day:</p>
                          <h6 className="font-size-14">{details?.day}</h6>
                        </div>
                        {/* <div className="mt-4">
                          <p className="mb-1">District:</p>
                          <h5 className="font-size-16">
                            {details?.district_name}
                          </h5>
                        </div> */}
                        {/* <div className="mt-4">
                          <p className="mb-1">Ward and Group:</p>
                          <h6 className="font-size-14">
                            {details?.ward_name}
                            {","}
                            <br />
                            <h6 className="font-size-14">
                              {details?.group_name}
                            </h6>
                          </h6>
                        </div> */}
                        {/* <div className="mt-4">
                          <p className="mb-1">Group:</p>
                          <h5 className="font-size-16">
                            {details?.group_name}
                          </h5>
                        </div> */}
                        {/* <div>
                          <p className="mb-1">Package:</p>
                          <h6 className="font-size-14">
                            {details?.package_name}
                          </h6>
                        </div> */}

                        {/* second paper */}

                        {/* Customer Details Tab end */}
                      </div>
                    </div>
                  </div>

                  <div className="col-xl-9" style={{ marginBottom: "30px" }}>
                    <div
                      className="mb-0 card"
                      style={{
                        boxShadow: "2px 4px 7px 0px rgb(0 0 0 / 10%)",
                        width: "100%",
                      }}
                    >
                      <Box sx={{ width: "100%", typography: "body1" }}>
                        <TabContext value={tab3}>
                          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                            <TabList
                              indicatorColor="primary"
                              textColor="primary"
                              variant="fullWidth"
                              onChange={handleChangeTab}
                              aria-label="lab API tabs example"
                            >
                              <Tab
                                icon={
                                  <Inventory2Icon
                                    style={{ fontSize: "20px" }}
                                  />
                                }
                                label="Package"
                                value="7"
                                style={{
                                  textTransform: "capitalize",
                                  fontFamily: "IBM Plex Sans,sans-serif",
                                }}
                              />
                              <Tab
                                icon={
                                  <LocationCityIcon
                                    style={{ fontSize: "20px" }}
                                  />
                                }
                                label="Visit Log"
                                value="1"
                                size="small"
                                style={{
                                  textTransform: "capitalize",
                                  fontFamily: "IBM Plex Sans,sans-serif",
                                }}
                              />
                              <Tab
                                icon={
                                  <DescriptionIcon
                                    style={{ fontSize: "20px" }}
                                  />
                                }
                                label="Invoice"
                                value="6"
                                style={{
                                  textTransform: "capitalize",
                                  fontFamily: "IBM Plex Sans,sans-serif",
                                }}
                              />
                              <Tab
                                icon={
                                  <ReceiptIcon style={{ fontSize: "20px" }} />
                                }
                                label="Receipt"
                                value="2"
                                style={{
                                  textTransform: "capitalize",
                                  fontFamily: "IBM Plex Sans,sans-serif",
                                }}
                              />
                              <Tab
                                icon={
                                  <ArticleIcon style={{ fontSize: "20px" }} />
                                }
                                label="Statement"
                                value="3"
                                style={{
                                  textTransform: "capitalize",
                                  fontFamily: "IBM Plex Sans,sans-serif",
                                }}
                              />
                              <Tab
                                icon={
                                  <ReceiptLongIcon
                                    style={{ fontSize: "20px" }}
                                  />
                                }
                                label="Credit Note"
                                value="4"
                                style={{
                                  textTransform: "capitalize",
                                  fontFamily: "IBM Plex Sans,sans-serif",
                                }}
                              />
                              <Tab
                                icon={
                                  <AccountBalanceWalletIcon
                                    style={{ fontSize: "20px" }}
                                  />
                                }
                                label="Wallet"
                                value="5"
                                style={{
                                  textTransform: "capitalize",
                                  fontFamily: "IBM Plex Sans,sans-serif",
                                }}
                              />

                              <Tab
                                icon={
                                  <RingVolumeIcon
                                    style={{ fontSize: "20px" }}
                                  />
                                }
                                label="Call log"
                                value="11"
                                size="small"
                                style={{
                                  textTransform: "capitalize",
                                  fontFamily: "IBM Plex Sans,sans-serif",
                                }}
                              />
                            </TabList>
                          </Box>
                          <TabPanel value="11">
                            <CustomerComplaints details={details} />
                          </TabPanel>
                          <TabPanel value="1">
                            <Row>
                              <Col className="col-12">
                                <Card>
                                  <CardBody>
                                    <MDBDataTable
                                      id="visitlogDataId"
                                      responsive
                                      bordered
                                      data={data}
                                    />
                                  </CardBody>
                                </Card>
                              </Col>
                            </Row>
                          </TabPanel>
                          <TabPanel value="2">
                            <Row>
                              <Col className="col-12">
                                <Card>
                                  <CardBody>
                                    <AvForm
                                      className="needs-validation"
                                      ref={formRef}
                                      onValidSubmit={(e, v) => {
                                        handleReceiptSubmit(e, v);
                                      }}
                                    >
                                      <Row>
                                        <Col md="3">
                                          <Label>Payment Method</Label>
                                          <Select
                                            name="receipt_payment_type"
                                            value={selectedPayment}
                                            options={[
                                              { label: "Cash", value: 0 },
                                              { label: "Razorpay", value: 1 },
                                              { label: "Paytm", value: 2 },
                                              { label: "Phonepay", value: 3 },
                                              { label: "Google pay", value: 4 },
                                              {
                                                label: "Wallet usage",
                                                value: 5,
                                              },
                                              { label: "Cheque", value: 7 },
                                            ]}
                                            onChange={(selected) => {
                                              handleSelectChange(
                                                selected,
                                                "receipt_payment_type"
                                              );
                                            }}
                                          />
                                          <p
                                            className="text-danger"
                                            style={{ fontSize: "11px" }}
                                          >
                                            {selectedPayment === null
                                              ? errors?.paymentName
                                              : ""}
                                          </p>
                                        </Col>

                                        <Col md="3">
                                          <Label>Amount</Label>
                                          <AvField
                                            name="receipt_amount"
                                            placeholder="Amount"
                                            validate={{
                                              required: { value: true },
                                            }}
                                            errorMessage="Enter Amount"
                                            value={receiptObj?.receipt_amount}
                                            onChange={(e) =>
                                              handleChangeInput(e)
                                            }
                                            type="number"
                                            min={0}
                                          />
                                        </Col>

                                        <Col md="3">
                                          <div
                                            style={{
                                              display: "flex",
                                              marginTop: "27px",
                                            }}
                                          >
                                            <Button
                                              type="submit"
                                              color="primary"
                                            >
                                              {" "}
                                              Submit
                                            </Button>

                                            <Button
                                              type="reset"
                                              color="danger"
                                              style={{ marginLeft: "25px" }}
                                              onClick={() => resetReceipt()}
                                            >
                                              Reset
                                            </Button>
                                          </div>
                                        </Col>
                                      </Row>
                                    </AvForm>
                                    <MDBDataTable
                                      id="custReceiptId"
                                      responsive
                                      bordered
                                      data={receiptTableData}
                                    />
                                  </CardBody>
                                </Card>
                              </Col>
                            </Row>
                          </TabPanel>
                          <TabPanel value="3">
                            <Row>
                              <Col className="col-12">
                                <Card>
                                  <CardBody>
                                    <MDBDataTable
                                      id="customerStatementId"
                                      responsive
                                      bordered
                                      data={statementTableData}
                                    />
                                  </CardBody>
                                </Card>
                              </Col>
                            </Row>
                          </TabPanel>
                          <TabPanel value="4">
                            <Row>
                              <Col className="col-12">
                                <Card>
                                  <CardBody>
                                    <AvForm
                                      className="needs-validation"
                                      ref={formRef}
                                      onValidSubmit={(e, v) => {
                                        handleCreditSubmit(e, v);
                                      }}
                                    >
                                      <Row>
                                        <Col md="3">
                                          <Label>Total Due</Label>
                                          <AvField
                                            name="cust_due"
                                            placeholder="Total Due"
                                            value={details?.cust_due}
                                            type="number"
                                            className="form-control"
                                            readOnly
                                          />
                                        </Col>

                                        <Col md="3">
                                          <Label>Amount</Label>
                                          <AvField
                                            name="amount"
                                            placeholder="Amount"
                                            value={creditObj?.amount}
                                            type="number"
                                            min={0}
                                            className="form-control"
                                            errorMessage="Enter Amount"
                                            validate={{
                                              required: { value: true },
                                            }}
                                            onChange={(e) =>
                                              handleCreditChange(e)
                                            }
                                          />
                                        </Col>

                                        <Col md="3">
                                          <Label>Comments</Label>
                                          <AvField
                                            name="comments"
                                            placeholder="Comments"
                                            value={creditObj?.comments}
                                            type="textarea"
                                            className="form-control"
                                            rows={1}
                                            errorMessage="Enter Comments"
                                            validate={{
                                              required: { value: true },
                                            }}
                                            onChange={(e) =>
                                              handleCreditChange(e)
                                            }
                                          />
                                        </Col>

                                        <Col md="3">
                                          <div
                                            style={{
                                              display: "flex",
                                              marginTop: "27px",
                                            }}
                                          >
                                            <Button
                                              type="submit"
                                              color="primary"
                                            >
                                              Submit
                                            </Button>
                                          </div>
                                        </Col>
                                      </Row>
                                    </AvForm>

                                    <Row>
                                      <Col
                                        xl={12}
                                        style={{ marginTop: "21px" }}
                                      >
                                        <MDBDataTable
                                          id="custCreditnoteTable"
                                          responsive
                                          bordered
                                          data={creditNoteTableData}
                                        />
                                      </Col>
                                    </Row>
                                  </CardBody>
                                </Card>
                              </Col>
                            </Row>
                          </TabPanel>
                          <TabPanel value="5">
                            <Row>
                              <Col className="col-12">
                                <Card>
                                  <CardBody>
                                    <MDBDataTable
                                      id="customerWalletId"
                                      responsive
                                      bordered
                                      data={walletTableData}
                                    />
                                  </CardBody>
                                </Card>
                              </Col>
                            </Row>
                          </TabPanel>
                          <TabPanel value="6">
                            <Row>
                              <Col className="col-12">
                                <Card>
                                  <CardBody>
                                    <MDBDataTable
                                      id="customerInvoiceId"
                                      responsive
                                      bordered
                                      data={invoiceTableData}
                                    />
                                  </CardBody>
                                </Card>
                              </Col>
                            </Row>
                          </TabPanel>
                          <TabPanel value="7">
                            <table
                              id="product_available_day"
                              className="table table-bordered dataTable"
                            >
                              <tr>
                                <td style={{ padding: "10px", width: "282px" }}>
                                  Package Name :
                                </td>
                                <td>{packageData?.package_name}</td>
                                <td>Category :</td>
                                <td style={{ textAlign: "left" }}>
                                  {packageData?.cust_type_name}
                                </td>
                              </tr>
                              <tr>
                                <td style={{ padding: "10px", width: "282px" }}>
                                  Registration Fee :
                                </td>
                                <td>{packageData?.package_reg_fee}</td>
                                <td>Basic Fee :</td>
                                <td style={{ textAlign: "left" }}>
                                  {packageData?.package_basic_fee}
                                </td>
                              </tr>
                              <tr>
                                <td style={{ padding: "10px", width: "282px" }}>
                                  Package Validity in Days :
                                </td>
                                <td>{packageData?.package_validity}</td>
                                <td>Visit/Month :</td>
                                <td style={{ textAlign: "left" }}>
                                  {packageData?.package_visit_month}
                                </td>
                              </tr>
                              <tr>
                                <td style={{ padding: "10px", width: "282px" }}>
                                  Free Bags :
                                </td>
                                <td>{packageData?.total_free_bags}</td>
                                <td>Staff :</td>
                                <td style={{ textAlign: "left" }}>
                                  {packageData?.staff}
                                </td>
                              </tr>
                              <tr>
                                <td style={{ padding: "10px", width: "282px" }}>
                                  Total Amount :
                                </td>
                                <td>{packageData?.package_totat_amount}</td>
                                <td></td>
                                <td style={{ textAlign: "left" }}>{ }</td>
                              </tr>
                            </table>
                            {tariffInfo && tariffInfo.length > 0 ? (
                              <Row className="mt-4 mb-1">
                                <h5 style={{ fontWeight: "bold" }}>
                                  Tariff Details
                                </h5>
                              </Row>
                            ) : null}
                            {tariffInfo && tariffInfo.length > 0 ? (
                              <Row>
                                <Col className="col-12">
                                  <Card>
                                    <CardBody>
                                      <MDBDataTable
                                        id="customerViewTariffId"
                                        responsive
                                        bordered
                                        data={customerViewTariffData}
                                      />
                                    </CardBody>
                                  </Card>
                                </Col>
                              </Row>
                            ) : null}
                          </TabPanel>
                        </TabContext>
                      </Box>
                    </div>
                  </div>
                </div>
              </div>

              {/* End */}
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ViewCustomer;
