import React, { useEffect, useRef, useState } from "react";
import { Row, Col, Card, CardBody, Button, Label } from "reactstrap";
import { AvField, AvForm } from "availity-reactstrap-validation";
import Select from "react-select";
import { MDBDataTable } from "mdbreact";
import { Pagination } from "@mui/material"
import axios from "axios";
import { Modal } from "react-bootstrap";
import accessToken from "../../helpers/jwt-token-access/accessToken";
import {
    checkObjectIdValid,
    getLocalbody,
    getUserId,
} from "../../helpers/globalFunctions";
import moment from "moment";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import toastr from "toastr";
import { isArray } from "lodash";
import { get, jsonToExcel, post, put } from "../../helpers/api_helper";
import SweetAlert from "react-bootstrap-sweetalert";



const Survey = () => {

    const PopUpRef = useRef();

    const localbody = getLocalbody();
    const followupState = {
        localbodyId: getLocalbody(),
        userId: getUserId(),
        image: [""],
        status: "",
        comment: "",
    };
    const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";
    const [filterObject, setFilterObject] = useState({});
    const [wardOptions, setWardOptions] = useState([]);
    const [filterSelectObject, setFilterSelectObject] = useState({});
    const [page, setPage] = useState(1)
    const [count, setCount] = useState(0)
    const [limit, setLimit] = useState(20)
    const [tableData, setTableData] = useState([]);
    const [complaintId, setComplaintId] = useState(null);
    const [followUp, setFollowUp] = useState(followupState);
    const [staffComplaintIdToBeDeleted, setStaffComplaintIdToBeDeleted] =
        useState(null);
    const [confirmDeleteAlert, setConfirmDeleteAlert] = useState(null);
    const [openModal, setOpenModal] = useState(false);
    const [popupData, setPopupData] = useState({});
    const [oldNewData, setoldNewData] = useState([])
    const [selected, setSelected] = useState({});
    const [FollowUpTableData, setFollowUpTableData] = useState([]);
    const [assaignedStaff, setAssaignedStaff] = useState([]);
    const [loading, setLoading] = useState(false)
    const [groupOptions, setgroupOptions] = useState([])
    useEffect(() => {
        if (complaintId) {
            fetchAllolddata()
        }
    }, [complaintId])



    useEffect(() => {
        fetch_all_wards(localbody);

    }, [localbody, followUp?.complaintId])

    useEffect(() => {
        handleTableData(localbody);
    }, [filterObject, page, localbody]);


    function fetch_all_wards(localbody) {
        axios
            .get(`${API_URL}api/staff/wards?localbody_id=` + localbody, {
                headers: {
                    "x-access-token": accessToken,
                },
            })
            .then((res) => {
                var ward_data =
                    res.data.data &&
                    res.data.data.map((el) => {
                        return {
                            label: el.ward_name,
                            value: el._id,
                        };
                    });

                setWardOptions([
                    {
                        options: ward_data,
                    },
                ]);
            });
    }

    const handleFilterValueChange = (name, value) => {

        const obj = { ...filterObject };
        obj[name] = value;
        setFilterObject(obj);
        if (name == "wardId") fetchAllGroups(value)
    };

    const handleFilterSelectValueChange = (name, selected) => {
        const obj = { ...filterSelectObject };
        obj[name] = selected;
        setFilterSelectObject(obj);
        handleFilterValueChange(name, selected.value);
    };

    const handleFilterReset = () => {
        setFilterObject({
            fromDate: "",
            toDate: "",
            wardId: "",
            status: "",
            complaintType: "",
            against: "",
        });
        setFilterSelectObject({
            wardId: "",
            status: "",
            complaintType: "",
            against: "",
        });
    };

    const data = {
        columns: [
            {
                label: "#",
                field: "id",
                sort: "asc",
            },
            {
                label: "Name",
                field: "cust_name",
                sort: "asc",
            },
            {
                label: "Reg.No",
                field: "cust_reg_no",
                sort: "asc",
            },
            {
                label: "House",
                field: "cust_house_num",
                sort: "asc",
            },
            {
                label: "Mobile",
                field: "cust_phone",
                sort: "asc",
            },
            {
                label: "Ward",
                field: "ward",
                sort: "asc",
            },
            {
                label: "Group",
                field: "group",
                sort: "asc",
            },
            {
                label: "Added",
                field: "addded",
                sort: "asc",
            },
            {
                label: "Asset",
                field: "asset",
                sort: "asc",
            },
            {
                label: "Status",
                field: "status",
                sort: "asc",
                width: "150",
            },

            {
                label: "Details",
                field: "details",
                sort: "asc",
                width: 150,
            },

        ],
        rows: tableData,
    };

    const handlePageChange = (e, value) => {
        setPage(value)
    }

    const handleTableData = (localbody) => {
        axios
            .get(
                `${API_URL}survey/report-detail?localbodyId=${localbody}&start=${page}&limit=20&wardId=${filterObject?.wardId}&groupId=${filterObject?.groupId}`,
                {
                    headers: {
                        "x-access-token": accessToken,
                    },
                }
            )
            .then((res) => {
                setCount(res.data.count)

                let result = res.data.data
                result && result.map((item, index) => {
                    item.id = (page - 1) * limit + index + 1
                    item.addded = item?.survey?.addded ? item.survey.addded : "---"
                    item.asset = item?.survey?.asset ? item.survey.asset : "---"
                    item.status = item?.survey?.assetStatus ? item?.survey?.assetStatus : "---"
                    item.details = item?.survey?.assetStatusDetail ? item.survey?.assetStatusDetail : "---"

                    return item
                })
                setTableData(result)

            });
    };



    function fetchAllolddata() {
        axios.get(`${API_URL}complaint/customer/edit-old/data?complaintId=${complaintId}`, {
            headers: {
                "x-access-token": accessToken
            }
        }).then((res) => {
            setoldNewData(res.data.data)
        }).catch((err) => {
            console.log(err);
        })
    }

    function fetchAllGroups(id) {
        get(`${API_URL}api/staff/groups?localbody_id=${getLocalbody()}&ward_id=${id}`)
            .then((res) => {

                let result = res.data || [];
                let resultOption = result.map((item) => ({
                    label: item.group_name,
                    value: item._id
                }));

                setgroupOptions(resultOption);
            })
            .catch((error) => {
                console.error("Error fetching groups:", error);
            });
    }

    // function handleExport() {
    //     get(`survey/report-detail/export?localbodyId=${getLocalbody()}&start=1&limit=${count}&wardId=${filterObject?.wardId}&groupId=${filterObject?.groupId}`).then((res) => {

    //     }).catch((err) => {
    //         toastr.error("Failed to export")
    //     })
    // }


    const handleExport = async () => {
        setLoading(true);
        try {
    
          const response = await fetch(`${API_URL}survey/report-detail/export?localbodyId=${getLocalbody()}&start=1&limit=${count}&wardId=${filterObject?.wardId}&groupId=${filterObject?.groupId}`, {
            method: "GET",
            // credentials: "include",
            headers:{
              "x-access-token":accessToken 
            },
           });
    
          if (!response.ok) {
            throw new Error("Failed to download the file");
          }
    
          const blob = await response.blob();
          const url = window.URL.createObjectURL(blob);
    
          // Create a temporary link to download the file
          const link = document.createElement("a");
          link.href = url;
          link.download = "survey.xlsx"; // Default filename
          document.body.appendChild(link);
          link.click();
    
          // Clean up
          link.remove();
          window.URL.revokeObjectURL(url);
    
        } catch (error) {
          console.error("Error downloading the file:", error);
        }  finally {
            setLoading(false); // Stop loading
          }

      };

    // async function handleExport() {
    //     setLoading(true);
    //     const totalCount = count;
    //     const limit = 10000;
    //     const totalPages = Math.ceil(totalCount / limit);
    //     const requests = Array.from({ length: totalPages }, (_, i) => {
    //         const pagenum = i + 1;
    //         return fetch(`${API_URL}survey/report-detail?localbodyId=${getLocalbody()}&wardId=${filterObject?.wardId}&groupId=${filterObject?.groupId}&start=${pagenum}&limit=${limit}`, {
    //             headers: {
    //                 "x-access-token": accessToken
    //             }
    //         })
    //             .then(response => response.json())
    //             .catch(err => {
    //                 console.error(`Error fetching page ${pagenum}:`, err);
    //                 return [];
    //             });
    //     });

    //     try {
    //         const results = await Promise.all(requests);
    //         const combinedArray = results?.flatMap(item => item.data);
    //         let exportData=[];
    //         combinedArray?.map((item,index)=>{
    //             let exportItem={};
    //             exportItem["Sl No"]=index+1;
    //             exportItem["Name"]=item?.cust_name;
    //             exportItem["House Number"]=item?.cust_house_num;
    //             exportItem["Reg.Number"]=item?.cust_reg_no;
    //             exportItem["Mobile"]=item?.cust_phone;
    //             exportItem["Ward"]=item?.ward;
    //             exportItem["Ward Number"]=item?.ward_no;
    //             exportItem["Group"]=item?.group;
    //             exportItem["Added"]=item?.survey?.addded?item.survey.addded:"---"  
    //             exportItem["Asset"]=item?.survey?.asset?item.survey.asset:"---"  
    //             exportItem["Asset Status"]=item?.survey?.assetStatus?item.survey.assetStatus:"---"  
    //             exportItem["Asset Status Detail"]=item?.survey?.assetStatusDetail?item.survey.assetStatusDetail:"---"  
    //             exportData.push(exportItem)
    //         })

    //         if (exportData?.length > 0) {
    //             jsonToExcel(exportData, `Survey report-${moment().format("DD-MM-YY")}`);
    //           } else {
    //             toastr.warning("There is no data to export");
    //           }


    //     } catch (error) {
    //         console.error("Error exporting data:", error);
    //     } finally {
    //         setLoading(false)
    //     }
    // }

    return (
        <>


            <div className='page-content'>
                <div className='container-fluid'>
                    <Breadcrumbs title="Home" breadcrumbItem="Survey Reports" />
                    <Card>
                        <CardBody>
                            <Row>
                                <AvForm>
                                    <Row>


                                        <Col md="2">
                                            <div className="mb-3">
                                                <Label>Ward</Label>
                                                <Select
                                                    name="wardId"
                                                    options={wardOptions}
                                                    value={filterSelectObject?.wardId}
                                                    onChange={(selected) => {
                                                        handleFilterSelectValueChange("wardId", selected);
                                                    }}
                                                    classNamePrefix="select2-selection"
                                                />
                                            </div>
                                        </Col>
                                        <Col md="2">
                                            <div className="mb-3">
                                                <Label>Group</Label>
                                                <Select
                                                    name="groupId"
                                                    options={groupOptions}
                                                    value={filterSelectObject?.groupId}
                                                    onChange={(selected) => {
                                                        handleFilterSelectValueChange("groupId", selected);
                                                    }}
                                                    classNamePrefix="select2-selection"
                                                />
                                            </div>
                                        </Col>



                                        <Col style={{ paddingTop: "5px" }} md={3} >
                                            <Button
                                                color="danger"
                                                onClick={handleFilterReset}
                                                type="button"
                                                className="mt-4"

                                            >
                                                Reset
                                            </Button>
                                            <Button className="mt-4 mx-2" disabled={loading} onClick={handleExport} type="button" color="warning">
                                                {loading ? "Loading......" : "Export"}
                                            </Button>

                                        </Col>
                                    </Row>
                                </AvForm>
                                <MDBDataTable
                                    id="complainstId"
                                    data={data}
                                    sortable={false}
                                    searching={false}
                                    displayEntries={false}
                                    paging={false}
                                    disableRetreatAfterSorting={true}
                                    bordered
                                    responsive
                                    entries={20}
                                />
                                <div class="row align-items-center">
                                    <div
                                        dataTest="datatable-info"
                                        className="col-sm-12 col-md-5"
                                    >
                                        <div
                                            className="dataTables_info"
                                            role="status"
                                            ariaLive="polite"
                                        >
                                            Showing {(page - 1) * limit + 1} to{" "}
                                            {Math.min(page * limit, count)} of {count} entries
                                        </div>
                                    </div>
                                    <div
                                        data-test="datatable-pagination"
                                        className="col-sm-12 col-md-7"
                                    >
                                        <div className="dataTables_paginate">
                                            <Pagination
                                                page={page}
                                                count={Math.ceil(count / limit)}
                                                shape="rounded"
                                                size="small"
                                                onChange={handlePageChange}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </Row>
                        </CardBody>
                    </Card>

                </div>
            </div>

        </>
    )
}

export default Survey
