import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { MDBDataTable } from "mdbreact";
import { Row, Col, Card, CardBody, Button, Label, Modal } from "reactstrap";
import accessToken from "../../../helpers/jwt-token-access/accessToken";
import axios from "axios";
import $ from "jquery";
import Select from "react-select";
//import { AvForm, AvField } from "availity-reactstrap-validation";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import {
  getDate,
  getFirstday,
  getLocalbody,
} from "../../../helpers/globalFunctions";
import moment from "moment";
import "./addWorkRequest.scss";
import { CSVLink } from "react-csv";
import { getCustomerType } from "../../../store/actions";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Pagination } from "@mui/material"
import { put } from "../../../helpers/api_helper";
import toastr from "toastr";


const WasteRequest = (props) => {
  const [show, setshow] = useState(false)
  const [page, setPage] = useState(1)
  const [count, setCount] = useState(0)
  const [limit, setLimit] = useState(20)
  const [disblebutton, setdisblebutton] = useState(false)
  const [wardOptions, setWardOptions] = useState([]);
  const [selectedWard, setSelectedWard] = useState(null);
  const [groupOptions, setGroupOptions] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [requestId, setrequestId] = useState(null)

  const [searchData, setSearchData] = useState({
    // from_date: getFirstday(new Date()),
    // to_date: getDate(new Date()),
  });
  const [details, setDetails] = useState([]);
  // const [wasteItems, setWasteItems] = useState([]);
  const [staffOptions, setStaffOptions] = useState([]);
  const [selectedStaff, setSelectedStaff] = useState(null);
  const [dataToBeExported, setDataToBeExported] = useState([]);
  const [currentLocalbody, setCurrectLocalbody] = useState(getLocalbody());
  const [selectedCustomerType, setSelectedCustomerType] = useState(null);
  const [customerType, setCustomerType] = useState([]);
  const [selectValues, setselectValues] = useState({})
  const [driveroption, setdriveroption] = useState([])
  const [selectedDriver, setselectedDriver] = useState(null)


  const dispatch = useDispatch();
  const { customerTypes } = useSelector((state) => state.customers);
  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";
  useEffect(() => {
    handleTableData();
    fetch_all_wards(currentLocalbody);
    // fetch_waste_items();
    fetch_all_staff(currentLocalbody);
    dispatch(getCustomerType());
    fetch_all_category();
  }, [currentLocalbody]);

  useEffect(() => {
    fetchAllDriver()
  }, [searchData, page])


  useEffect(() => {
    var data =
      customerTypes &&
      customerTypes.map((el) => {
        return {
          label: el.customer_type_name,
          value: el._id,
        };
      });

    setCustomerType([
      {
        options: data,
      },
    ]);
  }, [customerTypes]);
  const fetch_all_staff = (localbody, query) => {
    let data = {
      keyword: query,
      localbody_id: [localbody],
    };
    axios
      .post(`${API_URL}customers/option/list`, data, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let customer_data =
          res.data.data &&
          res.data.data.map((customer) => {
            return {
              label: customer?.cust_name,
              value: customer?._id,
            };
          });
        setStaffOptions([
          {
            options: customer_data,
          },
        ]);
      });
  };

  const style = {
    control: (base) => ({
      ...base,
      border: 0,
      boxShadow: "none",
    }),
  };

  function fetch_all_wards(localbody) {
    axios
      .get(`${API_URL}api/staff/wards?localbody_id=` + localbody, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var ward_data =
          res.data.data &&
          res.data.data.map((el) => {
            return {
              label: el.ward_name,
              value: el._id,
            };
          });

        setWardOptions([
          {
            options: ward_data,
          },
        ]);
      });
  }
  function fetch_all_category() {
    axios
      .get(`${API_URL}workrequest/categorylist`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var category_data =
          res.data.data &&
          res.data.data.map((el) => {
            return {
              label: el.category_name,
              value: el._id,
            };
          });
        setCategoryOptions([
          {
            options: category_data,
          },
        ]);
      });
  }

  function fetchAllDriver() {
    axios.get(`${API_URL}api/staff/workrequest/driver/options?localbodyId=${getLocalbody()}`, {
      headers: {
        "x-access-token": accessToken
      }
    }).then((res) => {

      let result = res.data.data
      let option = result.map((item, index) => {
        return {
          label: item.firstName + " " + item.lastName,
          value: item._id
        }
      })
      setdriveroption(option)

    })
  }

  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 100,
      },
      {
        label: "Date",
        field: "date",
        sort: "asc",
        width: 400,
      },
      // {
      //   label: "Time",
      //   field: "time",
      //   sort: "asc",
      //   width: 200,
      // },
      {
        label: "ID",
        field: "unqueId",
        sort: "asc",
        width: 200,
      },
      {
        label: "Category",
        field: "category_name",
        sort: "asc",
        width: 200,
      },
      {
        label: "Qty",
        field: "qty",
        sort: "asc",
        width: 200,
      },
      {
        label: "Req.Qty",
        field: "quantity",
        sort: "asc",
        width: 200,
      },
      {
        label: "Ward",
        field: "wardname",
        sort: "asc",
        width: 200,
      },
      {
        label: "Staff",
        field: "staff_name",
        sort: "asc",
        width: 200,
      },
      {
        label: "Driver",
        field: "driver",
        sort: "asc",
        width: 200,
      },
      {
        label: "Status",
        field: "status1",
        sort: "asc",
        width: 200,
      },
    ],
    rows: details,
  };

  const handleSelectChange = (selected, name) => {
    setselectValues({ ...selectValues, [name]: selected.value })
    setSearchData({ ...searchData, [name]: selected.value })

  }

  function handleselectdriver(selected, name) {
    setselectedDriver(selected.value)
  }


  function handleTableData() {
    axios.get(`${API_URL}api/staff/workrequest/all-list?localbodyId=${getLocalbody()}&start=${page}&limit=${limit}&staffId&fromDate=${searchData?.fromDate}&toDate=${searchData?.toDate}&status&ward=${searchData?.ward}`, {
      headers: {
        "x-access-token": accessToken
      }
    }).then((res) => {
      setCount(res.data.count)
      let result = res.data.data
      result.map((item, index) => {
        item.id = (page - 1) * limit + index + 1
        item.date = item?.date ? moment(item?.date).format("DD-MM-YYYY") : "DD-MM-YYYY";
        item.category_name = item.category.category_name
        item.wardname = item?.ward?.ward_name
        item.staff_name = item.adddedBy.firstName + " " + item.adddedBy.lastName
        item.qty = item.category.quantity
        if (item.currentStatus == 1) {
          item.status1 = (
            <>
              <Button disabled color="success" size="sm">Approved</Button>
            </>
          )
        } else if (item.currentStatus == 2) {
          item.status1 = (
            <>
              <Button disabled color="danger" size="sm">Rejected</Button>
            </>
          )
        } else if (item.currentStatus == 0) {
          item.status1 = (
            <>
              <Select
                onChange={(selected) => {
                  handleSelectedStatus(selected, item._id)
                }}
                options={[{ label: "Assign Driver", value: 1 }, { label: "Reject", value: 2 },]}
              />
            </>
          )
        }
        item.assignedDriver = item.assignedDriver ? item.assignedDriver?.name : "----"
        return item
      })
      setDetails(result)

    })

  }

  function handleSelectedStatus(selected, id) {
    if (selected.value == 1) {
      setrequestId(id)
      setshow(true)
    } else if (selected.value == 2) {
      axios.put(`${API_URL}api/staff/workrequest/action`, { status: 2, workRequestId: id }, {
        headers: {
          "x-access-token": accessToken
        }
      }).then((res) => {
        toastr.success(res.data.message)
        handleTableData()
      }).catch((err) => {
        toastr.error(err.response.data.message)
      })
    }

  }

  function handlevalidSubmitRequest() {
    axios.put(`${API_URL}api/staff/workrequest/action`, { status: 1, workRequestId: requestId, assignedDriverId: selectedDriver }, {
      headers: {
        "x-access-token": accessToken
      }
    }).then((res) => {
      toastr.success(res.data.message)
      handleTableData()
      setshow(false)
      setselectedDriver(null)
      setrequestId(null)
    }).catch((err) => {
      toastr.error(err.response.data.message)
    })

  }

  const handlePageChange = (e, value) => {
    setPage(value)
  }

  function handleDate(e) {
    let { name, value } = e.target
    setSearchData({ ...searchData, [name]: value })
  }

  return (
    <React.Fragment>
      <Modal
        isOpen={show}
        size="md"
        toggle={() => {
          setshow(false);
        }}
        centered={true}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0">Select Driver </h5>
          <button
            type="button"
            onClick={() => {
              setshow(false)
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div className="modal-body">
          <Row>
            <Col md="6">
              <div>
                <Label>Select</Label>
                <Select
                  options={driveroption}
                  onChange={(selected) => {
                    handleselectdriver(selected, "driver")
                  }}
                />
              </div>
            </Col>
            <Col md="2">
              <div className="mt-4">
                <Button
                  onClick={handlevalidSubmitRequest}
                  className="mt-1" color="primary">Submit</Button>
              </div>
            </Col>
          </Row>
        </div>
      </Modal>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Work Request" />
          <Row>
            <Col className="col-12">

              <Card>
                <CardBody>
                  <Row>
                    <Col md="2">
                      <div className="mb-3">
                        <Label htmlFor="validationCustom05">From</Label>
                        <input
                          className="form-control"
                          type="date"
                          id="fromDate"
                          name="from_date"
                          value={searchData?.fromDate || ""}
                          onChange={handleDate}
                        />
                      </div>
                    </Col>
                    <Col md="2">
                      <div className="mb-3">
                        <Label htmlFor="validationCustom05">To</Label>
                        <input
                          className="form-control"
                          type="date"
                          id="to_date"
                          name="toDate"
                          value={searchData?.toDate}
                          min={searchData?.fromDate}
                          onChange={handleDate}

                        />
                      </div>
                    </Col>
                    <Col md="2">
                      <div className="mb-3">
                        <Label>Ward</Label>
                        <Select
                          name="ward"
                          value={selectValues?.ward || null}
                          onChange={(selected) => {
                            handleSelectChange(selected, "ward");
                          }}
                          options={wardOptions}
                          classNamePrefix="select2-selection"
                        />
                      </div>
                    </Col>
                    {/* <Col md="2">
                      <div className="mb-3">
                        <Label>Group</Label>
                        <Select
                          name="group_id"
                          value={selectValues.group_id||null}
                          onChange={(selected) => {
                            handleSelectChange(selected, "group");
                          }}
                          options={groupOptions}
                          classNamePrefix="select2-selection"
                        />
                      </div>
                    </Col> */}
                    {/* <Col md="2">
                      <div className="mb-3">
                        <Label>Category</Label>
                        <Select
                          name="category_id"
                          value={selectedCategory}
                          onChange={(selected) => {
                            handleSelectChange(selected, "category_id");
                          }}
                          options={categoryOptions}
                          classNamePrefix="select2-selection"
                        />
                      </div>
                    </Col> */}


                    {/* <Col md="1" style={{ paddingTop: "14px" }}>
                      <div className="mt-2 mb-3">
                        <Button
                          color="primary"
                          type="submit"
                        >
                          Search
                        </Button>
                      </div>
                    </Col> */}

                    <Col md="1" style={{ paddingTop: "14px" }}>
                      <div className="mt-2 mb-3">
                        <Button
                          color="danger"
                          type="reset"
                        >
                          Reset
                        </Button>
                      </div>
                    </Col>
                  </Row>

                  <MDBDataTable
                    // id="addWorkRequestID"
                    data={data}
                    sortable={false}
                    searching={false}
                    displayEntries={false}
                    paging={false}
                    disableRetreatAfterSorting={true}
                    bordered
                    responsive
                    entries={20}
                  />
                  <div class="row align-items-center">
                    <div
                      dataTest="datatable-info"
                      className="col-sm-12 col-md-5"
                    >
                      <div
                        className="dataTables_info"
                        role="status"
                        ariaLive="polite"
                      >
                        Showing {(page - 1) * limit + 1} to{" "}
                        {Math.min(page * limit, count)} of {count} entries
                      </div>
                    </div>
                    <div
                      data-test="datatable-pagination"
                      className="col-sm-12 col-md-7"
                    >
                      <div className="dataTables_paginate">
                        <Pagination
                          page={page}
                          count={Math.ceil(count / limit)}
                          shape="rounded"
                          size="small"
                          onChange={handlePageChange}
                        />
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};
export default WasteRequest;
