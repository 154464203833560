import React, { useState, useEffect, useRef } from "react";
import { MDBDataTable } from "mdbreact";
import {
    Row,
    Col,
    Card,
    CardBody,
    Button,
    Label,
    Modal,
    FormGroup,
    Input,
} from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";
import { AvForm, AvField } from "availity-reactstrap-validation";
import Breadcrumb from "../../components/Common/Breadcrumb";
import axios from "axios";
import accessToken from "../../helpers/jwt-token-access/accessToken";
import toastr from "toastr";

const CustomerEdit = () => {
    const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";
    const [tableDetails, settableDetails] = useState([])

    const [deletedId, setdeletedId] = useState(null)
    const [confirmDeleteAlert, setConfirmDeleteAlert] = useState(null);

    const [editObject, seteditObject] = useState({
        categoryType: false,
        customerType: false,
        designation: false,
        name: false,
        mobile: false,
        landLine: false,
        whatsUp: false,
        email: false,
        addressOne: false,
        addressTwo: false,
        numberOfMembers: false,
        district: false,
        ward: false,
        package: false,
        group: false,
        buildingNo: false,
        latitude: false,
        longitude: false,
        billingType: false,
        profileImage: false,
        qrCode: false
    })


    useEffect(() => {
        fetchAlleditCategory()

    }, [])



    const handleCheckboxChange = (e) => {
        let name = e.target.name;
        let value = e.target.checked
        console.log(name, value);

        seteditObject({
            ...editObject,
            [name]: value,
        });
    };


    function handleValidSubmit() {
        axios.post(`${API_URL}customers/edit-category`, editObject, {
            headers: {
                "x-access-token": accessToken
            }
        }).then((res) => {
            toastr.success(res.data.message)
            seteditObject({})
            fetchAlleditCategory()

        }).catch((err) => {
            toastr.error(err.response.data.message)
        })
    }


    const fetchAlleditCategory = () => {
        axios
            .get(`${API_URL}customers/edit-category/list`, {
                headers: {
                    "x-access-token": accessToken,
                },
            })
            .then((res) => {
                console.log(res, "edit list");
                let result = res.data.data;

                result &&
                    result.map((item, index) => {
                        item.id = index + 1;

                        // Replace boolean fields with checkboxes
                        item.customerType = (
                            <input
                                type="checkbox"
                                checked={item.customerType}
                                disabled
                            />
                        );
                        item.designation = (
                            <input
                                type="checkbox"
                                checked={item.designation}
                                disabled
                            />
                        );
                        item.name = (
                            <input
                                type="checkbox"
                                checked={item.name}
                                disabled
                            />
                        );
                        item.mobile = (
                            <input
                                type="checkbox"
                                checked={item.mobile}
                                disabled
                            />
                        );
                        item.landLine = (
                            <input
                                type="checkbox"
                                checked={item.landLine}
                                disabled
                            />
                        );
                        item.whatsUp = (
                            <input
                                type="checkbox"
                                checked={item.whatsUp}
                                disabled
                            />
                        );
                        item.email = (
                            <input
                                type="checkbox"
                                checked={item.email}
                                disabled
                            />
                        );
                        item.addressOne = (
                            <input
                                type="checkbox"
                                checked={item.addressOne}
                                disabled
                            />
                        );
                        item.addressTwo = (
                            <input
                                type="checkbox"
                                checked={item.addressTwo}
                                disabled
                            />
                        );
                        item.numberOfMembers = (
                            <input
                                type="checkbox"
                                checked={item.numberOfMembers}
                                disabled
                            />
                        );
                        item.district = (
                            <input
                                type="checkbox"
                                checked={item.district}
                                disabled
                            />
                        );
                        item.ward = (
                            <input
                                type="checkbox"
                                checked={item.ward}
                                disabled
                            />
                        );
                        item.package = (
                            <input
                                type="checkbox"
                                checked={item.package}
                                disabled
                            />
                        );
                        item.group = (
                            <input
                                type="checkbox"
                                checked={item.group}
                                disabled
                            />
                        );
                        item.buildingNo = (
                            <input
                                type="checkbox"
                                checked={item.buildingNo}
                                disabled
                            />
                        );
                        item.latitude = (
                            <input
                                type="checkbox"
                                checked={item.latitude}
                                disabled
                            />
                        );
                        item.longitude = (
                            <input
                                type="checkbox"
                                checked={item.longitude}
                                disabled
                            />
                        );
                        item.billingType = (
                            <input
                                type="checkbox"
                                checked={item.billingType}
                                disabled
                            />
                        );
                        item.profileImage = (
                            <input
                                type="checkbox"
                                checked={item.profileImage}
                                disabled
                            />
                        );
                        item.qrCode = (
                            <input
                                type="checkbox"
                                checked={item.qrCode}
                                disabled
                            />
                        );

                        item.action = (
                            <div style={{ display: "flex", justifyContent: "center" }}>

                                <i
                                    className="uil-trash-alt"
                                    style={{ fontSize: "1.2em", cursor: "pointer" }}
                                    onClick={() => {
                                        setdeletedId(item._id);
                                        setConfirmDeleteAlert(true);
                                    }}
                                ></i>
                            </div>
                        );

                        return item;
                    });
                settableDetails(result);
            });
    };


    const data = {
        columns: [
            {
                label: "#",
                field: "id",
                sort: "asc",
                width: 50,
            },
            {
                label: "CategoryName",
                field: "categoryName",
                sort: "asc",
                width: 50,
            },
            {
                label: "CustomerType ",
                field: "customerType",
                sort: "asc",
                width: 50,
            },
            {
                label: "Designation ",
                field: "designation",
                sort: "asc",
                width: 400,
            },
            {
                label: "Name ",
                field: "name",
                sort: "asc",
                width: 400,
            },

            {
                label: " Mobile",
                field: "mobile",
                sort: "asc",
                width: 400,
            },
            {
                label: "LandLine",
                field: "landLine",
                sort: "asc",
                width: 400,
            },
            {
                label: "WhatsApp",
                field: "whatsUp",
                sort: "asc",
                width: 400,
            },
            {
                label: "Email",
                field: "email",
                sort: "asc",
                width: 400,
            },
            {
                label: "AddressOne",
                field: "addressOne",
                sort: "asc",
                width: 400,
            },
            {
                label: "AddressTwo",
                field: "addressTwo",
                sort: "asc",
                width: 400,
            },
            {
                label: "Members",
                field: "numberOfMembers",
                sort: "asc",
                width: 400,
            },
            {
                label: "District",
                field: "district",
                sort: "asc",
                width: 400,
            },
            {
                label: "Ward",
                field: "ward",
                sort: "asc",
                width: 400,
            },
            {
                label: "Package",
                field: "package",
                sort: "asc",
                width: 400,
            },
            {
                label: "Group",
                field: "group",
                sort: "asc",
                width: 400,
            },
            {
                label: "BuildingNo",
                field: "buildingNo",
                sort: "asc",
                width: 400,
            },
            {
                label: "Latitude",
                field: "latitude",
                sort: "asc",
                width: 400,
            },
            {
                label: "Longitude",
                field: "longitude",
                sort: "asc",
                width: 400,
            },
            {
                label: "BillingType",
                field: "billingType",
                sort: "asc",
                width: 400,
            },
            {
                label: "ProfileImage",
                field: "profileImage",
                sort: "asc",
                width: 400,
            },
            {
                label: "QrCode",
                field: "qrCode",
                sort: "asc",
                width: 400,
            },
            {
                label: "Action",
                field: "action",
                sort: "asc",
                width: 400,
            },
        ],
        rows: tableDetails,
    };

    return (
        <>
            {confirmDeleteAlert ? (
                <SweetAlert
                    title=""
                    showCancel
                    confirmButtonText="Delete"
                    confirmBtnBsStyle="success"
                    cancelBtnBsStyle="danger"
                    onConfirm={() => {
                        axios
                            .delete(`${API_URL}customers/edit-category/delete?categoryId=${deletedId}`, {
                                headers: {
                                    "x-access-token": accessToken,
                                },
                            })
                            .then((res) => {
                                toastr.success(res.data.message)
                                fetchAlleditCategory()
                                setdeletedId(null)

                            })
                            .catch((err) => {
                                toastr.error(err.response.data.message);
                                return;
                            });
                        setConfirmDeleteAlert(false);
                    }}
                    onCancel={() => setConfirmDeleteAlert(false)}
                >
                    Are you sure you want to delete it?
                </SweetAlert>
            ) : null}
            <div className='page-content'>
                <div className='container-fluid'>
                    <Breadcrumb title="Home" breadcrumbItem="Manage Customer Edit" />
                    <Row>
                        <Card>
                            <CardBody>
                                <AvForm
                                    onValidSubmit={(e, v) => {
                                        handleValidSubmit(e, v);
                                    }}
                                >
                                    <Row>
                                        <Col md="3">
                                            <AvField onChange={(e) => {
                                                seteditObject({ ...editObject, [e.target.name]: e.target.value })
                                            }} name="categoryName" placeholder="Category Name" value={editObject?.categoryName || ""} />
                                        </Col>
                                        <Col md="2">
                                            <div
                                                className="form-check mb-4"
                                                style={{ marginTop: "14px" }}
                                            >
                                                <Input
                                                    name="categoryType"
                                                    type="checkbox"
                                                    checked={editObject?.categoryType}
                                                    className="form-check-input"
                                                    id="categoryType"
                                                    onClick={handleCheckboxChange}
                                                    style={{
                                                        cursor: "pointer",
                                                    }}
                                                />
                                                <Label
                                                    className="form-check-label"
                                                    htmlFor="categoryType"
                                                    style={{
                                                        cursor: "default",
                                                    }}
                                                >
                                                    Category Type
                                                </Label>
                                            </div>
                                        </Col>
                                        <Col md="2">
                                            <div
                                                className="form-check mb-4"
                                                style={{ marginTop: "14px" }}
                                            >
                                                <Input
                                                    name="customerType"
                                                    type="checkbox"
                                                    checked={editObject?.customerType}
                                                    className="form-check-input"
                                                    id="customerType"
                                                    onClick={handleCheckboxChange}
                                                    style={{
                                                        cursor: "pointer",
                                                    }}
                                                />
                                                <Label
                                                    className="form-check-label"
                                                    htmlFor="customerType"
                                                    style={{
                                                        cursor: "default",
                                                    }}
                                                >
                                                    Customer Type
                                                </Label>
                                            </div>
                                        </Col>
                                        <Col md="2">
                                            <div
                                                className="form-check mb-4"
                                                style={{ marginTop: "14px" }}
                                            >
                                                <Input
                                                    name="designation"
                                                    type="checkbox"
                                                    checked={
                                                        editObject?.designation}
                                                    className="form-check-input"
                                                    id="designation"
                                                    onClick={handleCheckboxChange}
                                                    style={{
                                                        cursor: "pointer",
                                                    }}
                                                />
                                                <Label
                                                    className="form-check-label"
                                                    htmlFor="designation"
                                                    style={{
                                                        cursor: "default",
                                                    }}
                                                >
                                                    Designation
                                                </Label>
                                            </div>
                                        </Col>
                                        <Col md="2">
                                            <div
                                                className="form-check mb-4"
                                                style={{ marginTop: "14px" }}
                                            >
                                                <Input
                                                    name="name"
                                                    type="checkbox"
                                                    checked={
                                                        editObject?.name}
                                                    className="form-check-input"
                                                    id="name"
                                                    onClick={handleCheckboxChange}
                                                    style={{
                                                        cursor: "pointer",
                                                    }}
                                                />
                                                <Label
                                                    className="form-check-label"
                                                    htmlFor="name"
                                                    style={{
                                                        cursor: "default",
                                                    }}
                                                >
                                                    Name
                                                </Label>
                                            </div>
                                        </Col>
                                        <Col md="2">
                                            <div
                                                className="form-check mb-4"
                                                style={{ marginTop: "14px" }}
                                            >
                                                <Input
                                                    name="mobile"
                                                    type="checkbox"
                                                    checked={
                                                        editObject?.mobile}
                                                    className="form-check-input"
                                                    id="mobile"
                                                    onClick={handleCheckboxChange}
                                                    style={{
                                                        cursor: "pointer",
                                                    }}
                                                />
                                                <Label
                                                    className="form-check-label"
                                                    htmlFor="mobile"
                                                    style={{
                                                        cursor: "default",
                                                    }}
                                                >
                                                    Mobile
                                                </Label>
                                            </div>
                                        </Col>
                                        <Col md="2">
                                            <div
                                                className="form-check mb-4"
                                                style={{ marginTop: "14px" }}
                                            >
                                                <Input
                                                    name="landLine"
                                                    type="checkbox"
                                                    checked={
                                                        editObject?.landLine}
                                                    className="form-check-input"
                                                    id="landLine"
                                                    onClick={handleCheckboxChange}
                                                    style={{
                                                        cursor: "pointer",
                                                    }}
                                                />
                                                <Label
                                                    className="form-check-label"
                                                    htmlFor="landLine"
                                                    style={{
                                                        cursor: "default",
                                                    }}
                                                >
                                                    Land Line
                                                </Label>
                                            </div>
                                        </Col>
                                        <Col md="2">
                                            <div
                                                className="form-check mb-4"
                                                style={{ marginTop: "14px" }}
                                            >
                                                <Input
                                                    name="whatsUp"
                                                    type="checkbox"
                                                    checked={
                                                        editObject?.whatsUp}
                                                    className="form-check-input"
                                                    id="whatsUp"
                                                    onClick={handleCheckboxChange}
                                                    style={{
                                                        cursor: "pointer",
                                                    }}
                                                />
                                                <Label
                                                    className="form-check-label"
                                                    htmlFor="whatsUp"
                                                    style={{
                                                        cursor: "default",
                                                    }}
                                                >
                                                    Whats App
                                                </Label>
                                            </div>
                                        </Col>
                                        <Col md="2">
                                            <div
                                                className="form-check mb-4"
                                                style={{ marginTop: "14px" }}
                                            >
                                                <Input
                                                    name="email"
                                                    type="checkbox"
                                                    checked={
                                                        editObject?.email}
                                                    className="form-check-input"
                                                    id="landLine"
                                                    onClick={handleCheckboxChange}
                                                    style={{
                                                        cursor: "pointer",
                                                    }}
                                                />
                                                <Label
                                                    className="form-check-label"
                                                    htmlFor="email"
                                                    style={{
                                                        cursor: "default",
                                                    }}
                                                >
                                                    Email
                                                </Label>
                                            </div>
                                        </Col>
                                        <Col md="2">
                                            <div
                                                className="form-check mb-4"
                                                style={{ marginTop: "14px" }}
                                            >
                                                <Input
                                                    name="addressOne"
                                                    type="checkbox"
                                                    checked={
                                                        editObject?.addressOne}
                                                    className="form-check-input"
                                                    id="addressOne"
                                                    onClick={handleCheckboxChange}
                                                    style={{
                                                        cursor: "pointer",
                                                    }}
                                                />
                                                <Label
                                                    className="form-check-label"
                                                    htmlFor="email"
                                                    style={{
                                                        cursor: "default",
                                                    }}
                                                >
                                                    AddressOne
                                                </Label>
                                            </div>
                                        </Col>
                                        <Col md="2">
                                            <div
                                                className="form-check mb-4"
                                                style={{ marginTop: "14px" }}
                                            >
                                                <Input
                                                    name="addressTwo"
                                                    type="checkbox"
                                                    checked={
                                                        editObject?.addressTwo}
                                                    className="form-check-input"
                                                    id="addressTwo"
                                                    onClick={handleCheckboxChange}
                                                    style={{
                                                        cursor: "pointer",
                                                    }}
                                                />
                                                <Label
                                                    className="form-check-label"
                                                    htmlFor="addressTwo"
                                                    style={{
                                                        cursor: "default",
                                                    }}
                                                >
                                                    AddressTwo
                                                </Label>
                                            </div>
                                        </Col>

                                        <Col md="2">
                                            <div
                                                className="form-check mb-4"
                                                style={{ marginTop: "14px" }}
                                            >
                                                <Input
                                                    name="numberOfMembers"
                                                    type="checkbox"
                                                    checked={
                                                        editObject?.numberOfMembers}
                                                    className="form-check-input"
                                                    id="numberOfMembers"
                                                    onClick={handleCheckboxChange}
                                                    style={{
                                                        cursor: "pointer",
                                                    }}
                                                />
                                                <Label
                                                    className="form-check-label"
                                                    htmlFor="numberOfMembers"
                                                    style={{
                                                        cursor: "default",
                                                    }}
                                                >
                                                    Number Of Members
                                                </Label>
                                            </div>
                                        </Col>

                                        <Col md="2">
                                            <div
                                                className="form-check mb-4"
                                                style={{ marginTop: "14px" }}
                                            >
                                                <Input
                                                    name="district"
                                                    type="checkbox"
                                                    checked={
                                                        editObject?.district}
                                                    className="form-check-input"
                                                    id="district"
                                                    onClick={handleCheckboxChange}
                                                    style={{
                                                        cursor: "pointer",
                                                    }}
                                                />
                                                <Label
                                                    className="form-check-label"
                                                    htmlFor="district"
                                                    style={{
                                                        cursor: "default",
                                                    }}
                                                >
                                                    District
                                                </Label>
                                            </div>
                                        </Col>
                                        <Col md="2">
                                            <div
                                                className="form-check mb-4"
                                                style={{ marginTop: "14px" }}
                                            >
                                                <Input
                                                    name="ward"
                                                    type="checkbox"
                                                    checked={
                                                        editObject?.ward}
                                                    className="form-check-input"
                                                    id="ward"
                                                    onClick={handleCheckboxChange}
                                                    style={{
                                                        cursor: "pointer",
                                                    }}
                                                />
                                                <Label
                                                    className="form-check-label"
                                                    htmlFor="ward"
                                                    style={{
                                                        cursor: "default",
                                                    }}
                                                >
                                                    Ward
                                                </Label>
                                            </div>
                                        </Col>
                                        <Col md="2">
                                            <div
                                                className="form-check mb-4"
                                                style={{ marginTop: "14px" }}
                                            >
                                                <Input
                                                    name="package"
                                                    type="checkbox"
                                                    checked={
                                                        editObject?.package}
                                                    className="form-check-input"
                                                    id="ward"
                                                    onClick={handleCheckboxChange}
                                                    style={{
                                                        cursor: "pointer",
                                                    }}
                                                />
                                                <Label
                                                    className="form-check-label"
                                                    htmlFor="package"
                                                    style={{
                                                        cursor: "default",
                                                    }}
                                                >
                                                    Package
                                                </Label>
                                            </div>
                                        </Col>
                                        <Col md="2">
                                            <div
                                                className="form-check mb-4"
                                                style={{ marginTop: "14px" }}
                                            >
                                                <Input
                                                    name="group"
                                                    type="checkbox"
                                                    checked={
                                                        editObject?.group}
                                                    className="form-check-input"
                                                    id="ward"
                                                    onClick={handleCheckboxChange}
                                                    style={{
                                                        cursor: "pointer",
                                                    }}
                                                />
                                                <Label
                                                    className="form-check-label"
                                                    htmlFor="group"
                                                    style={{
                                                        cursor: "default",
                                                    }}
                                                >
                                                    Group
                                                </Label>
                                            </div>
                                        </Col>

                                        <Col md="2">
                                            <div
                                                className="form-check mb-4"
                                                style={{ marginTop: "14px" }}
                                            >
                                                <Input
                                                    name="buildingNo"
                                                    type="checkbox"
                                                    checked={
                                                        editObject?.buildingNo}
                                                    className="form-check-input"
                                                    id="buildingNo"
                                                    onClick={handleCheckboxChange}
                                                    style={{
                                                        cursor: "pointer",
                                                    }}
                                                />
                                                <Label
                                                    className="form-check-label"
                                                    htmlFor="buildingNo"
                                                    style={{
                                                        cursor: "default",
                                                    }}
                                                >
                                                    BuildingNo
                                                </Label>
                                            </div>
                                        </Col>

                                        <Col md="2">
                                            <div
                                                className="form-check mb-4"
                                                style={{ marginTop: "14px" }}
                                            >
                                                <Input
                                                    name="latitude"
                                                    type="checkbox"
                                                    checked={
                                                        editObject?.latitude}
                                                    className="form-check-input"
                                                    id="latitude"
                                                    onClick={handleCheckboxChange}
                                                    style={{
                                                        cursor: "pointer",
                                                    }}
                                                />
                                                <Label
                                                    className="form-check-label"
                                                    htmlFor="latitude"
                                                    style={{
                                                        cursor: "default",
                                                    }}
                                                >
                                                    Latitude
                                                </Label>
                                            </div>
                                        </Col>

                                        <Col md="2">
                                            <div
                                                className="form-check mb-4"
                                                style={{ marginTop: "14px" }}
                                            >
                                                <Input
                                                    name="longitude"
                                                    type="checkbox"
                                                    checked={
                                                        editObject?.longitude}
                                                    className="form-check-input"
                                                    id="longitude"
                                                    onClick={handleCheckboxChange}
                                                    style={{
                                                        cursor: "pointer",
                                                    }}
                                                />
                                                <Label
                                                    className="form-check-label"
                                                    htmlFor="longitude"
                                                    style={{
                                                        cursor: "default",
                                                    }}
                                                >
                                                    Longitude
                                                </Label>
                                            </div>
                                        </Col>
                                        <Col md="2">
                                            <div
                                                className="form-check mb-4"
                                                style={{ marginTop: "14px" }}
                                            >
                                                <Input
                                                    name="billingType"
                                                    type="checkbox"
                                                    checked={
                                                        editObject?.billingType}
                                                    className="form-check-input"
                                                    id="billingType"
                                                    onClick={handleCheckboxChange}
                                                    style={{
                                                        cursor: "pointer",
                                                    }}
                                                />
                                                <Label
                                                    className="form-check-label"
                                                    htmlFor="billingType"
                                                    style={{
                                                        cursor: "default",
                                                    }}
                                                >
                                                    BillingType
                                                </Label>
                                            </div>
                                        </Col>

                                        <Col md="2">
                                            <div
                                                className="form-check mb-4"
                                                style={{ marginTop: "14px" }}
                                            >
                                                <Input
                                                    name="profileImage"
                                                    type="checkbox"
                                                    checked={
                                                        editObject?.profileImage}
                                                    className="form-check-input"
                                                    id="profileImage"
                                                    onClick={handleCheckboxChange}
                                                    style={{
                                                        cursor: "pointer",
                                                    }}
                                                />
                                                <Label
                                                    className="form-check-label"
                                                    htmlFor="profileImage"
                                                    style={{
                                                        cursor: "default",
                                                    }}
                                                >
                                                    ProfileImage
                                                </Label>
                                            </div>
                                        </Col>
                                        <Col md="2">
                                            <div
                                                className="form-check mb-4"
                                                style={{ marginTop: "14px" }}
                                            >
                                                <Input
                                                    name="qrCode"
                                                    type="checkbox"
                                                    checked={
                                                        editObject?.qrCode}
                                                    className="form-check-input"
                                                    id="qrCode"
                                                    onClick={handleCheckboxChange}
                                                    style={{
                                                        cursor: "pointer",
                                                    }}
                                                />
                                                <Label
                                                    className="form-check-label"
                                                    htmlFor="qrCode"
                                                    style={{
                                                        cursor: "default",
                                                    }}
                                                >
                                                    Qr Code
                                                </Label>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md="3">
                                            <div style={{ display: "flex", gap: "5px" }}>
                                                <Button type="submit" color="primary">Submit</Button>
                                                <Button type="button" color="danger">Reset</Button>
                                            </div>

                                        </Col>

                                    </Row>

                                </AvForm>
                            </CardBody>
                        </Card>
                    </Row>

                    <Row>
                        <Card>
                            <CardBody>
                                <MDBDataTable
                                    responsive
                                    bordered
                                    data={data}
                                    searching={true}
                                    info={true}
                                    disableRetreatAfterSorting={true}
                                    entries={20} />

                            </CardBody>
                        </Card>
                    </Row>


                </div>
            </div>

        </>
    )
}

export default CustomerEdit
