import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { MDBDataTable } from "mdbreact";
import jwt_decode from "jwt-decode";
import toastr from "toastr";
import axios from "axios";
import $ from "jquery";
import accessToken from "../../../helpers/jwt-token-access/accessToken";
import { Row, Col, Card, CardBody, Button, Label, Modal } from "reactstrap";
import Select from "react-select";
import {
  apiError,
  getIncomeExpenseAccType,
  getAllFundSource,
  // addIncomeExpense,
  // getAllIncomeExpenses,
  // getUsers,
} from "../../../store/actions";
// Redux
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { AvForm, AvField } from "availity-reactstrap-validation";
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import {
  getDateTime,
  getDate,
  getFirstday,
  getLocalbody,
} from "../../../helpers/globalFunctions";
import moment from "moment";
import "./incomeExpenses.scss";
import DataTable from "../../../components/Common/DataTable";
const IncomeExpense = (props) => {
  const [incomeExpenseList, setIncomeExpenseList] = useState([]);
  const [accountType, setAccountType] = useState([]);
  const [accountDetailType, setAccountDetailType] = useState([]);
  const [selectedAccountType, setSelectedAccountType] = useState(null);
  const [selectedAccountDetailType, setSelectedAccountDetailType] =
    useState(null);
  const [chartOfAccount, setChartOfAccount] = useState([]);
  const [selectedChartOfAccount, setSelectedChartOfAccont] = useState(null);
  const [fundSourceOptions, setFundSourceOptions] = useState([]);
  const [selectedFundSource, setSelectedFundSource] = useState(null);
  const [master, setMaster] = useState({
    income_expense_date: getDate(new Date()),
    income_expense_time: moment().format("HH:mm:ss"),
    localbody_id: getLocalbody(),
  });
  const [userId, setUserId] = useState(1);
  const [searchData, setSearchData] = useState({
    from_date: getFirstday(new Date()),
    to_date: getDate(new Date()),
  });
  const [selectedChartOfAccForSearch, setSelectedChartOfAccForSearch] =
    useState(null);
  const [accDetailTypeForSearch, setAccDetailTypeForSearch] = useState(null);
  const [accTypeForSearch, setAccTypeForSearch] = useState(null);
  const [accountDetailTypeSearch, setAccountDetailTypeSearch] = useState([]);
  const [chartOfAccountSearch, setChartOfAccountSearch] = useState([]);
  const [user, setUser] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [currentLocalbody, setCurrectLocalbody] = useState(getLocalbody());
  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";

  const [page, setPage] = useState(1)
  const [count, setCount] = useState(0)
  const [limit, setLimit] = useState(20)

  const dispatch = useDispatch();
  const formRef = useRef();

  const {
    incomeExpenseAccTypes,
    incomeExpenses,
    addIncomeExpenseResponse,
    addingIncomeExpense,
    error,
  } = useSelector((state) => state.incomeExpenses);

  useEffect(() => {
    dispatch(getIncomeExpenseAccType());
    dispatch(getAllFundSource(currentLocalbody));
    // dispatch(getAllIncomeExpenses(currentLocalbody));
    // dispatch(getUsers());
    fetch_all_staff(currentLocalbody);
  }, [currentLocalbody]);

  useEffect(()=>{
    handleSearch();
  },[currentLocalbody,page,limit])

  const { fundSource } = useSelector((state) => state.fundTransfers);
  // const { users } = useSelector((state) => state.users);
  useEffect(() => {
    var data =
      incomeExpenseAccTypes &&
      incomeExpenseAccTypes.map((item) => {
        return {
          label: item.acc_type_name,
          value: item._id,
        };
      });

    setAccountType([
      {
        options: data,
      },
    ]);
  }, [incomeExpenseAccTypes]);

  useEffect(() => {
    var data =
      fundSource &&
      fundSource.map((item) => {
        return {
          label: item.acc_chart_name,
          value: item._id,
        };
      });

    setFundSourceOptions([
      {
        options: data,
      },
    ]);
  }, [fundSource]);

  // useEffect(() => {
  //   var data =
  //     users &&
  //     users.map((user) => {
  //       return {
  //         label: user.firstName,
  //         value: user._id,
  //       };
  //     });

  //   setUser([
  //     {
  //       options: data,
  //     },
  //   ]);
  // }, [users]);

  // useEffect(() => {
  //   let incomeExpenseData = JSON.parse(JSON.stringify(incomeExpenses));

  //   let data = [];
  //   incomeExpenseData.map((item, index) => {
  //     item.auth_userid = userId;
  //     item.id = index + 1;
  //     item.income_expense_amount1 = item.income_expense_amount;
  //     item.income_expense_total1 = item.income_expense_total;
  //     item.income_expense_date1 = moment(item.income_expense_date).format(
  //       "DD-MM-YYYY"
  //     );
  //     if (item.income_expense_approve_status === 0) {
  //       item.status = (
  //         <div style={{ display: "flex", justifyContent: "center" }}>
  //           <Button
  //             color="primary"
  //             size="sm"
  //             onClick={() => {
  //               updateApproveStatus(item, 1);
  //             }}
  //           >
  //             Pending
  //           </Button>
  //         </div>
  //       );
  //     } else if (item.income_expense_approve_status === 1) {
  //       item.status = (
  //         <div style={{ display: "flex", justifyContent: "center" }}>
  //           <Button color="success" size="sm" disabled>
  //             Approved
  //           </Button>
  //         </div>
  //       );
  //     } else if (item.income_expense_approve_status === 2) {
  //       item.status = (
  //         <div style={{ display: "flex", justifyContent: "center" }}>
  //           <Button color="danger" size="sm" disabled>
  //             Rejected
  //           </Button>
  //         </div>
  //       );
  //     }
  //     data.push(item);
  //   });
  //   setIncomeExpenseList(data);
  // }, [incomeExpenses]);

  const updateApproveStatus = (item, selected) => {
    let user_id = "";

    if (localStorage.getItem("authUser")) {
      const obj = JSON.parse(localStorage.getItem("authUser"));
      user_id = obj._id;
    }

    let data = {
      user_id: user_id,
      localbody_id: currentLocalbody,
      status: selected?.value,
      id: item?._id,
    };
    axios
      .post(`${API_URL}api/staff/approve_expense`, data, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        if (res.data.success === true) {
          toastr.success(res.data.message);

          let date1 = searchData?.from_date ? searchData.from_date : "";
          let date2 = searchData?.to_date ? searchData.to_date : "";
          let acc_type = searchData?.income_expense_head
            ? searchData.income_expense_head
            : "";
          let acc_detail = searchData?.income_expense_subhead
            ? searchData.income_expense_subhead
            : "";
          let user_id = searchData?.income_expense_staff
            ? searchData.income_expense_staff
            : "";
          let acc_chart = searchData?.income_expense_chartofaccounts
            ? searchData.income_expense_chartofaccounts
            : "";

          handleTableData(
            date1,
            date2,
            acc_type,
            acc_detail,
            acc_chart,
            user_id
          );
        } else {
          toastr.error(res.data.message);
        }
      })
      .catch((err) => {
        toastr.error(err.response.data.message);
        return;
      });
  };

  useEffect(() => {
    if (addIncomeExpenseResponse.type === "success") {
      toastr.success(addIncomeExpenseResponse.message);
      // dispatch(getAllIncomeExpenses(currentLocalbody));
      handleTableData();
    } else if (addIncomeExpenseResponse.type === "failure") {
      toastr.error(error.data.message, addIncomeExpenseResponse.message);
    }
  }, [addIncomeExpenseResponse]);

  const handleSelectedAccDetailType = (selected) => {
    setSelectedAccountDetailType(selected);
    setSelectedChartOfAccont(null);
    setMaster({
      ...master,
      ["income_expense_subhead"]: selected.value,
    });
    getChartOfAccount(selected.value, "fromAccDetailType");
  };
  const handleSelectedAccType = (selected) => {
    setSelectedAccountType(selected);
    setSelectedAccountDetailType(null);
    setSelectedChartOfAccont(null);
    setChartOfAccount([]);
    setMaster({
      ...master,
      ["income_expense_head"]: selected.value,
    });
    getDetailType(selected.value, "fromAccType");
  };
  const getDetailType = (type, name) => {
    var url = `${API_URL}account_detail/options` + "?acc_type_id=" + type;

    axios
      .get(url, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var result = res.data.data;

        let data =
          result &&
          result.map((item) => {
            return {
              label: item.acc_detail_name,
              value: item._id,
            };
          });
        if (name === "fromAccType") {
          setAccountDetailType([
            {
              options: data,
            },
          ]);
        } else {
          setAccountDetailTypeSearch([
            {
              options: data,
            },
          ]);
        }
      });
  };
  const fetch_all_staff = (id) => {
    axios
      .get(`${API_URL}user/options?localbody_id=` + id, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var staff_data =
          res.data.data &&
          res.data.data.map((el) => {
            if (el?.lastName) el.name = el.firstName + " " + el.lastName;
            else el.name = el.firstName;
            return {
              label: el.name,
              value: el._id,
            };
          });
        setUser([
          {
            options: staff_data,
          },
        ]);
      });
  };
  const getChartOfAccount = (type, name) => {
    var url =
      `${API_URL}chart_of_account/options?localbody_id=` +
      currentLocalbody +
      "&acc_detail_id=" +
      type;

    axios
      .get(url, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var result = res.data.data;
        let data =
          result &&
          result.map((item) => {
            return {
              label: item.acc_chart_name,
              value: item._id,
            };
          });
        if (name === "fromAccDetailType") {
          setChartOfAccount([
            {
              options: data,
            },
          ]);
        } else {
          setChartOfAccountSearch([
            {
              options: data,
            },
          ]);
        }
      });
  };
  const handleSelectedChartOfAccount = (selected) => {
    setSelectedChartOfAccont(selected);
    setMaster({
      ...master,
      ["income_expense_chartofaccounts"]: selected.value,
    });
  };
  const handleSelectedFundSource = (selected) => {
    setSelectedFundSource(selected);
    setMaster({
      ...master,
      ["income_expense_fundsource"]: selected.value,
    });
  };

  const handleDate = (e) => {
    setMaster({
      ...master,
      [e.target.name]: e.target.value,
    });
  };
  const handleChangeInput = (e) => {
    setMaster({
      ...master,
      [e.target.name]: e.target.value,
    });
  };
  const onAmountChange = (e) => {
    let amount = Number(e.target.value);
    let tax = Number(
      master?.income_expense_gst ? master.income_expense_gst : 0
    );
    let Total = amount + parseFloat((amount * tax) / 100);
    setMaster({
      ...master,
      [e.target.name]: e.target.value,
      ["income_expense_total"]: Total,
    });
  };
  const onGstChange = (e) => {
    let amount = Number(
      master?.income_expense_amount ? master.income_expense_amount : 0
    );
    let tax = Number(e.target.value);

    let Total = amount + parseFloat((amount * tax) / 100);
    setMaster({
      ...master,
      [e.target.name]: e.target.value,
      ["income_expense_total"]: Total,
    });
  };
  const handleValidSubmit = (e, v) => {
    if (selectedAccountType === null) {
      toastr.error("Account type is required");
      return;
    }
    if (selectedAccountDetailType === null) {
      toastr.error("Account detail type is required");
      return;
    }
    if (selectedChartOfAccount === null) {
      toastr.error("Chart of account is required");
      return;
    }
    if (selectedFundSource === null) {
      toastr.error("Fund source is required");
      return;
    }

    axios
      .post(`${API_URL}income_expense`, master, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        if (res.data.status === 200) {
          toastr.success("Income expense added successfully");
          formRef.current.reset();
          setSelectedFundSource(null);
          setSelectedChartOfAccont(null);
          setSelectedAccountType(null);
          setSelectedAccountDetailType(null);
          setMaster({
            ["auth_userid"]: userId,
            ["income_expense_date"]: getDate(new Date()),
            ["income_expense_time"]: moment(getDateTime(new Date())).format(
              "HH:mm:ss"
            ),
            ["localbody_id"]: getLocalbody(),
          });
          // dispatch(getAllIncomeExpenses(currentLocalbody));
          handleTableData();
        } else {
          toastr.error("Failed to add income expense");
          return;
        }
      })
      .catch((err) => {
        toastr.error(err.response.data.message);
        return;
      });

    // dispatch(addIncomeExpense(master));
    // formRef.current.reset();
    // setSelectedFundSource(null);
    // setSelectedChartOfAccont(null);
    // setSelectedAccountType(null);
    // setSelectedAccountDetailType(null);
    // setMaster({
    //   ["auth_userid"]: userId,
    //   ["income_expense_date"]: getDate(new Date()),
    //   ["income_expense_time"]: moment(getDateTime(new Date())).format(
    //     "HH:mm:ss"
    //   ),
    // });
  };
  const reset = () => {
    setMaster({
      ["auth_userid"]: userId,
      ["income_expense_date"]: "",
      ["income_expense_time"]: "",
      ["localbody_id"]: getLocalbody(),
    });
    formRef.current.reset();
    setSelectedFundSource(null);
    setSelectedChartOfAccont(null);
    setSelectedAccountType(null);
    setSelectedAccountDetailType(null);
  };

  const handleSelectedForSearch = (selected, name) => {
    // let date1 = searchData?.from_date ? searchData.from_date : "";
    // let date2 = searchData?.to_date ? searchData.to_date : "";
    // let acc_type = searchData?.income_expense_head
    //   ? searchData.income_expense_head
    //   : "";
    // let acc_detail = searchData?.income_expense_subhead
    //   ? searchData.income_expense_subhead
    //   : "";
    // let user_id = searchData?.income_expense_staff
    //   ? searchData.income_expense_staff
    //   : "";
    // let acc_chart = searchData?.income_expense_chartofaccounts
    //   ? searchData.income_expense_chartofaccounts
    //   : "";
    switch (name) {
      case "chart":
        setSelectedChartOfAccForSearch(selected);
        setSearchData({
          ...searchData,
          ["income_expense_chartofaccounts"]: selected.value,
        });
        // handleTableData(
        //   date1,
        //   date2,
        //   acc_type,
        //   acc_detail,
        //   selected.value,
        //   user_id
        // );
        break;
      case "accDetailType":
        setAccDetailTypeForSearch(selected);
        setSelectedChartOfAccForSearch(null);
        setSearchData({
          ...searchData,
          ["income_expense_subhead"]: selected.value,
          ["income_expense_chartofaccounts"]: "",
        });
        getChartOfAccount(selected.value, "fromAccDetailTypeSearch");
        // handleTableData(date1, date2, acc_type, selected.value, "", user_id);
        break;
      case "accType":
        setAccTypeForSearch(selected);
        setAccDetailTypeForSearch(null);
        setSelectedChartOfAccForSearch(null);
        setChartOfAccountSearch([]);
        setSearchData({
          ...searchData,
          ["income_expense_head"]: selected.value,
          ["income_expense_subhead"]: "",
          ["income_expense_chartofaccounts"]: "",
        });
        getDetailType(selected.value, "fromAccTypeSearch");
        // handleTableData(date1, date2, selected.value, "", "", user_id);
        break;
      case "staff":
        setSelectedUser(selected);
        setSearchData({
          ...searchData,
          ["income_expense_staff"]: selected.value,
        });
        // handleTableData(
        //   date1,
        //   date2,
        //   acc_type,
        //   acc_detail,
        //   acc_chart,
        //   selected.value
        // );
        break;
      default:
        break;
    }
  };
  const handleDateSearch = (e) => {
    var date1 = $("#from_date").val();
    var date2 = $("#to_date").val();
    // let acc_type = searchData?.income_expense_head
    //   ? searchData.income_expense_head
    //   : "";
    // let acc_detail = searchData?.income_expense_subhead
    //   ? searchData.income_expense_subhead
    //   : "";
    // let user_id = searchData?.income_expense_staff
    //   ? searchData.income_expense_staff
    //   : "";
    // let acc_chart = searchData?.income_expense_chartofaccounts
    //   ? searchData.income_expense_chartofaccounts
    //   : "";
    let toDate = moment(date1);
    let fromDate = moment(date2);
    let result = fromDate.diff(toDate, "days");

    if (result + 1 <= 0) {
      setSearchData({
        ...searchData,
        [e.target.name]: e.target.value,
        ["to_date"]: "",
      });
      date2 = "";
    } else {
      setSearchData({
        ...searchData,
        [e.target.name]: e.target.value,
      });
    }

    // setSearchData({
    //   ...searchData,
    //   [e.target.name]: e.target.value,
    // });

    // handleTableData(date1, date2, acc_type, acc_detail, acc_chart, user_id);
  };
  const handleTableData = (
    from_date = "",
    to_date = "",
    acc_type = "",
    acc_detail = "",
    acc_chart = "",
    user_id = ""
  ) => {
    var url = `${API_URL}income_expense?localbody_id=${currentLocalbody}&page=${page}&limit=${limit}`;

    if (from_date === "" && to_date === "") {
      from_date = from_date ? from_date : getFirstday(new Date());
      to_date = to_date ? to_date : getDate(new Date());
    }

    url =
      url +
      "&from_date=" +
      from_date +
      "&to_date=" +
      to_date +
      "&acc_type=" +
      acc_type +
      "&acc_detail=" +
      acc_detail +
      "&acc_chart=" +
      acc_chart +
      "&user_id=" +
      user_id;

    axios
      .get(url, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        setCount(res.data.count)
        let result = res.data.data;
        let Data = [];
        result.map((item, index) => {
          item.id = (page - 1 ) * limit + index + 1;
          item.income_expense_amount1 = item.income_expense_amount;
          item.income_expense_total1 = item.income_expense_total;
          item.income_expense_date1 = moment(item.income_expense_date).format(
            "DD-MM-YYYY"
          );

          let selectedStatus = {};
          if (item.income_expense_approve_status === 0) {
            selectedStatus = {
              label: "Pending",
              value: 0,
            };
          } else if (item.income_expense_approve_status === 1) {
            selectedStatus = {
              label: "Approved",
              value: 1,
            };
          } else if (item.income_expense_approve_status === 2) {
            selectedStatus = { label: "Rejected", value: 2 };
          } else selectedStatus = null;

          if (item.income_expense_approve_status === 0) {
            item.status = (
              <div className="mb-3">
                <Select
                  styles={style}
                  name="income_expense_approve_status"
                  value={selectedStatus}
                  onChange={(selected) => {
                    updateApproveStatus(item, selected);
                  }}
                  options={[
                    // {
                    //   label: "Pending",
                    //   value: 0,
                    // },
                    {
                      label: "Approved",
                      value: 1,
                    },
                    {
                      label: "Rejected",
                      value: 2,
                    },
                  ]}
                  classNamePrefix="select2-selection"
                />
              </div>
            );
          } else {
            item.status = (
              <div className="mb-3">
                <Select
                  styles={style}
                  name="income_expense_approve_status"
                  value={selectedStatus}
                  onChange={(selected) => {
                    // updateStatus(selected, item);
                  }}
                  options={[]}
                  classNamePrefix="select2-selection"
                  isDisabled={true}
                />
              </div>
            );
          }

          // if (item.income_expense_approve_status === 0) {
          //   item.status = (
          //     <div style={{ display: "flex", justifyContent: "center" }}>
          //       <Button
          //         color="primary"
          //         size="sm"
          //         onClick={() => {
          //           updateApproveStatus(item, 1);
          //         }}
          //       >
          //         Pending
          //       </Button>
          //     </div>
          //   );
          // } else if (item.income_expense_approve_status === 1) {
          //   item.status = (
          //     <div style={{ display: "flex", justifyContent: "center" }}>
          //       <Button color="success" size="sm" disabled>
          //         Approved
          //       </Button>
          //     </div>
          //   );
          // } else if (item.income_expense_approve_status === 2) {
          //   item.status = (
          //     <div style={{ display: "flex", justifyContent: "center" }}>
          //       <Button color="danger" size="sm" disabled>
          //         Rejected
          //       </Button>
          //     </div>
          //   );
          // }

          Data.push(item);
        });
        setIncomeExpenseList(Data);
      });
  };
  const style = {
    control: (base) => ({
      ...base,
      border: 0,
      boxShadow: "none",
    }),
  };
  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 150,
      },
      {
        label: "Date",
        field: "income_expense_date1",
        sort: "asc",
        width: 400,
      },
      {
        label: "Type",
        field: "acc_type_name",
        sort: "asc",
        width: 200,
      },
      {
        label: "Detail Type",
        field: "acc_detail_name",
        sort: "asc",
        width: 200,
      },
      {
        label: "Chart of Account",
        field: "acc_chart_name",
        sort: "asc",
        width: 200,
      },
      {
        label: "Fund Source",
        field: "fund_source",
        sort: "asc",
        width: 200,
      },
      {
        label: "Amount",
        field: "income_expense_amount1",
        sort: "asc",
        width: 200,
      },
      {
        label: "Total",
        field: "income_expense_total1",
        sort: "asc",
        width: 200,
      },
      {
        label: "Comment",
        field: "income_expense_comment",
        sort: "asc",
        width: 200,
      },
      {
        label: "Staff",
        field: "added_user",
        sort: "asc",
        width: 200,
      },
      {
        label: "Status",
        field: "status",
        sort: "asc",
        width: 200,
      },
    ],
    rows: incomeExpenseList,
    //   rows:[{
    //     id:1,
    //     income_expense_date1:"04/01/2022",
    //     acc_type_name:"Income",
    //     acc_detail_name:"Receipt",
    //     acc_chart_name:"Waste Collection",
    //     fund_source:"Sanila V",
    //     income_expense_amount1:"100.00",
    //     income_expense_total1:"100.00",
    //     income_expense_comment:"Receipt amount",
    //     added_user:"Sanila"
    //   },
    //   {
    //     id:2,
    //     income_expense_date1:"04/01/2022",
    //     acc_type_name:"Income",
    //     acc_detail_name:"Receipt",
    //     acc_chart_name:"Waste Collection",
    //     fund_source:"Subisha K",
    //     income_expense_amount1:"50.00",
    //     income_expense_total1:"50.00",
    //     income_expense_comment:"Receipt amount",
    //     added_user:"Subisha"
    //   }
    // ]
  };
  const searchReset = () => {
    setSearchData({
      from_date: getFirstday(new Date()),
      to_date: getDate(new Date()),
    });
    setAccTypeForSearch(null);
    setAccDetailTypeForSearch(null);
    setChartOfAccountSearch(null);
    setSelectedUser(null);
    handleTableData();
  };
  const handleSearch = () => {
    let date1 = searchData?.from_date ? searchData.from_date : "";
    let date2 = searchData?.to_date ? searchData.to_date : "";
    let acc_type = searchData?.income_expense_head
      ? searchData.income_expense_head
      : "";
    let acc_detail = searchData?.income_expense_subhead
      ? searchData.income_expense_subhead
      : "";
    let user_id = searchData?.income_expense_staff
      ? searchData.income_expense_staff
      : "";
    let acc_chart = searchData?.income_expense_chartofaccounts
      ? searchData.income_expense_chartofaccounts
      : "";

    handleTableData(date1, date2, acc_type, acc_detail, acc_chart, user_id);
  };
  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Income/Expense" />
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <AvForm
                    ref={formRef}
                    className="needs-validation"
                    onValidSubmit={(e, v) => {
                      handleValidSubmit(e, v);
                    }}
                  >
                    <Row>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom05">Date</Label>
                          {/* <div className="col-md-10"> */}
                          <input
                            className="form-control"
                            type="date"
                            id="income_expense_date"
                            name="income_expense_date"
                            //defaultValue={master?.income_expense_date}
                            value={master?.income_expense_date}
                            onChange={handleDate}
                          />
                          {/* </div> */}
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom05">Time</Label>
                          <AvField
                            name="income_expense_time"
                            placeholder="Time"
                            type="time"
                            errorMessage="Enter Time"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="validationCustom05"
                            value={master?.income_expense_time}
                            onChange={handleChangeInput}
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label>Account Type</Label>
                          <Select
                            name="acc_type_id"
                            value={selectedAccountType}
                            onChange={(selected) => {
                              handleSelectedAccType(selected);
                            }}
                            options={accountType}
                            classNamePrefix="select2-selection"
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label> Account Details Type</Label>
                          <Select
                            name="acc_detail_id"
                            value={selectedAccountDetailType}
                            onChange={(selected) => {
                              handleSelectedAccDetailType(selected);
                            }}
                            options={accountDetailType}
                            classNamePrefix="select2-selection"
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label>Charts of Account</Label>
                          <Select
                            name="acc_chart_id"
                            value={selectedChartOfAccount}
                            onChange={(selected) => {
                              handleSelectedChartOfAccount(selected);
                            }}
                            options={chartOfAccount}
                            classNamePrefix="select2-selection"
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label>Fund Source</Label>
                          <Select
                            name="fund_source_id"
                            value={selectedFundSource}
                            onChange={(selected) => {
                              handleSelectedFundSource(selected);
                            }}
                            options={fundSourceOptions}
                            classNamePrefix="select2-selection"
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom05">Amount</Label>
                          <AvField
                            name="income_expense_amount"
                            placeholder="Amount"
                            type="number"
                            min="0"
                            errorMessage="Enter Amount"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="validationCustom05"
                            value={master?.income_expense_amount}
                            onChange={onAmountChange}
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom05">GST(%)</Label>
                          <AvField
                            name="income_expense_gst"
                            placeholder="GST"
                            type="number"
                            min="0"
                            errorMessage="Enter GST"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="validationCustom05"
                            value={master?.income_expense_gst}
                            onChange={onGstChange}
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom05">Comments</Label>
                          <AvField
                            name="income_expense_comment"
                            placeholder="Comments"
                            type="text"
                            errorMessage="Enter Comments"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="validationCustom05"
                            value={master?.income_expense_comment}
                            onChange={handleChangeInput}
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom05">
                            Total Amount
                          </Label>
                          <AvField
                            name="income_expense_total"
                            placeholder="Total Amount"
                            type="number"
                            className="form-control"
                            id="validationCustom05"
                            value={master?.income_expense_total}
                            readOnly
                          />
                        </div>
                      </Col>

                      {/* <Col className="mb-3">
                        <label
                          htmlFor="example-date-input"
                          className="col-md-2 col-form-label"
                        >
                          Date
                        </label>
                        <div className="col-md-10">
                          <input
                            className="form-control"
                            type="date"
                            defaultValue="2019-08-19"
                            id="example-date-input"
                          />
                        </div>
                      </Col> */}

                      <Col md="2" >
                        <div style={{ display: "flex" }} >
                          <div className="mt-4 mb-3 mx-1" style={{ paddingTop: "5px" }}>
                            <Button
                              color="primary"
                              type="submit"
                              disabled={addingIncomeExpense ? true : false}
                            >
                              {addingIncomeExpense ? "Adding" : "Submit"}
                            </Button>
                          </div>

                          <div className="mt-4 mb-3 mx-1" style={{ paddingTop: "5px" }}>
                            <Button
                              color="danger"
                              type="reset"
                              onClick={() => reset()}
                            >
                              Reset
                            </Button>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <Row>
                    <Col md="2">
                      <div className="mb-3">
                        <Label htmlFor="validationCustom05">From</Label>
                        {/* <div className="col-md-10"> */}
                        <input
                          className="form-control"
                          type="date"
                          id="from_date"
                          name="from_date"
                          value={searchData?.from_date}
                          onChange={handleDateSearch}
                        />
                        {/* </div> */}
                      </div>
                    </Col>
                    <Col md="2">
                      <div className="mb-3">
                        <Label htmlFor="validationCustom05">To</Label>
                        {/* <div className="col-md-10"> */}
                        <input
                          className="form-control"
                          type="date"
                          id="to_date"
                          name="to_date"
                          value={searchData?.to_date}
                          onChange={handleDateSearch}
                          min={searchData?.from_date}
                        />
                        {/* </div> */}
                      </div>
                    </Col>
                    <Col md="2">
                      <div className="mb-3">
                        <Label>Account Type</Label>
                        <Select
                          name="customer_community_id"
                          value={accTypeForSearch}
                          onChange={(selected) => {
                            handleSelectedForSearch(selected, "accType");
                          }}
                          options={accountType}
                          classNamePrefix="select2-selection"
                        />
                      </div>
                    </Col>
                    <Col md="2">
                      <div className="mb-3">
                        <Label>Account Detail</Label>
                        <Select
                          name="customer_community_id"
                          value={accDetailTypeForSearch}
                          onChange={(selected) => {
                            handleSelectedForSearch(selected, "accDetailType");
                          }}
                          options={accountDetailTypeSearch}
                          classNamePrefix="select2-selection"
                        />
                      </div>
                    </Col>
                    <Col md="2">
                      <div className="mb-3">
                        <Label>Account Chart</Label>
                        <Select
                          name="customer_community_id"
                          value={selectedChartOfAccForSearch}
                          onChange={(selected) => {
                            handleSelectedForSearch(selected, "chart");
                          }}
                          options={chartOfAccountSearch}
                          classNamePrefix="select2-selection"
                        />
                      </div>
                    </Col>
                    <Col md="2">
                      <div className="mb-3">
                        <Label>Staff</Label>
                        <Select
                          name="customer_community_id"
                          value={selectedUser}
                          onChange={(selected) => {
                            handleSelectedForSearch(selected, "staff");
                          }}
                          options={user}
                          classNamePrefix="select2-selection"
                        />
                      </div>
                    </Col>
                    <Col md="2">
                      <div style={{ display: "flex" }} >
                        <div className="mt-1 mb-3 mx-1" style={{ paddingTop: "5px" }}>
                          <Button
                            color="primary"
                            type="submit"
                            onClick={() => handleSearch()}
                          >
                            Search
                          </Button>
                        </div>

                        <div className="mt-1 mb-3 mx-1" style={{ paddingTop: "5px" }}>
                          <Button
                            color="danger"
                            type="reset"
                            onClick={() => searchReset()}
                          >
                            Reset
                          </Button>
                        </div>
                      </div>
                    </Col>
                  </Row>

                  {/* <MDBDataTable
                    id="incomeExpenseId"
                    responsive
                    bordered
                    data={data}
                    searching={true}
                    info={true}
                    disableRetreatAfterSorting={true}
                    entries={20}
                  /> */}

                    <DataTable
                    data={data}
                    count={count}
                    page={page}
                    limit={limit}
                    onPageChange={(e) => setPage(e)}
                    onLimitChange={(e) => setLimit(e)}
                    id="incomeExpenseId"
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => { };

export default withRouter(
  connect(mapStateToProps, { apiError })(IncomeExpense)
);

// Users.propTypes = {
//   error: PropTypes.any,
//   users: PropTypes.array,
// };
