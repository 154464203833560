import React, { useState, useEffect, useRef } from "react";
import { MDBDataTable } from "mdbreact";
import Select from "react-select";
import moment from "moment";
import axios from "axios";
import accessToken from "../../../helpers/jwt-token-access/accessToken";
import { getLocalbody, getLocalbodyName } from "../../../helpers/globalFunctions";
import { Modal } from "react-bootstrap";
import logoSm from "../../../assets/images/logo-sm.svg";
import $ from "jquery";
import { Link, withRouter } from "react-router-dom"
import {
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  Button,
  Label,
} from "reactstrap";

import { AvForm, AvField } from "availity-reactstrap-validation";

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import "./Calculated_Salary.scss";
import { CSVLink } from "react-csv";

const CheckinCheckout = () => {
  const [details, setDetails] = useState([]);
  const [month, setMonth] = useState("");
  const [currentLocalbody, setCurrectLocalbody] = useState(getLocalbody());
  const [dataToBeExported, setDataToBeExported] = useState([]);
  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";
  const [selectedOne, setselectedOne] = useState({});
  const [openModal, setOpenModal] = useState(false);
  const [searchSelectedStaff, setSearchSelectedStaff] = useState("");
  const [staffOptions, setStaffOptions] = useState([]);
  const handleChangeDate = (e) => {
    setMonth(e.target.value);
    handleTableData(e.target.value, searchSelectedStaff.value);
  };

  useEffect(() => {
    var body = document.body;
    body.classList.toggle("vertical-collpsed");
    body.classList.toggle("sidebar-enable");
    fetch_all_staff();
    handleTableData()

  }, [currentLocalbody]);

  const reset = () => {
    setMonth("");
    setSearchSelectedStaff("");
    handleTableData();
  };
  const fetch_all_staff = () => {
    var data = localStorage.getItem("authUser");
    var user_obj = JSON.parse(data);
    var user_id = user_obj._id;
    axios
      .post(`${API_URL}api/staff/filter_staff_list`, { localbody_id: currentLocalbody, user_id: user_id }, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var staff_data = [];
        if (res.data.success && res.data.success === true) {
          staff_data =
            res.data.data &&
            res.data.data.map((el) => {
              return {
                label: el.firstName + " " + el.lastName,
                value: el._id,
              };

            });
          staff_data.shift();
        } else {
          staff_data = [];
        }

        setStaffOptions([
          {
            options: staff_data,
          },
        ]);
      });
  };
  const handleClickOpenModal = (item) => {
    item.email = "";
    item.mobile = "";
    if (typeof item.staff_info !== "undefined" && item.staff_info.length > 0) {
      item.email = item.staff_info[0]?.email;
      item.mobile = item.staff_info[0]?.mobile;
    }
    var half_days = item.total_halfday;
    var half = half_days / 2;
    item.salary_name = item.basic_salary + "/ " + item.salary_type;
    item.total_leave = item.total_leave + half;
    item.total_present = item.total_present + half;
    item.month = moment(item.month, "YYYY-MM").format("MMMM YYYY");
    setselectedOne(item);
    setOpenModal(true)
  }
  const handleSearchSelectedData = (e, name) => {
    setSearchSelectedStaff(e);
    handleTableData(month, e.value);
  };

  const handleTableData = (month = "", staff = "") => {
    axios
      .get(`${API_URL}salary/all_slip?localbody=` + currentLocalbody + `&month=` + month + `&staff_id=` + staff, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let dataToBeExported = [];
        var data = res.data.data;
        data.map((element, index) => {
          element?.staff_info &&
            element?.staff_info.map((el) => {
              element.account_no = el?.account_no;
            })

          let exportItem = {};
          exportItem.Staff = element.staff_name;
          exportItem.Month = element.month;
          exportItem["Basic Salary"] = element.basic_salary;
          exportItem["Salary Type"] = element.salary_type;
          exportItem.Present = element.total_present;
          exportItem.Absent = element.total_leave;
          exportItem["LOP"] = element.lop;
          exportItem["Calculated"] = element.calculated_salary;
          exportItem.Advance = element.advance_balance;
          exportItem["Extra Payment"] = element.extra_payment_given;
          exportItem["Incentive"] = element.incentive_given;
          exportItem.Pay = element.to_pay;
          // exportItem["Account Number"]=element?.staff_info[0]?.account_no;
          exportItem["Address"]=element?.staff_info[0]?.address
          exportItem["Email"]=element?.staff_info[0]?.email
          exportItem["Privillage"]=element?.staff_info[0]?.privilage[0]?.privilege_name;
          exportItem["Localbody"]=element?.staff_info[0]?.local_body.map(item=>item.localbody_name).join(" , ")  
          exportItem["Group"]=element?.staff_info[0]?.user_group?.map(item=>item.group_name).join(" , ")    
          exportItem["Ward"]=element?.staff_info[0]?.ward?.map(item=>item. ward_name).join(" , ")        
          exportItem.Account_no = element.account_no;
          exportItem["IFSC"]=element?.staff_info[0]?.IFSC
          element.id = index + 1;
          if (element.salary_type != "" && typeof element.salary_type != "undefined") {
            element.salary_name = element.basic_salary + "/" + element.salary_type;
          }
          else {
            element.salary_name = element.basic_salary;
          }

          if (element.addedBy.length > 0) {
            element.addedBy = element.addedBy[0].firstName + " " + element.addedBy[0].lastName;
          }

          if (element.paidBy && element.paidBy.length > 0) {
            element.paidBy = element.paidBy[0].firstName + " " + element.paidBy[0].lastName;
          }
          if (element.paid_status == 0) {
            exportItem.Status = "Unpaid";
            element.status = (
              <span class="badge rounded-pill bg-danger" style={{ padding: "3px 8px 3px 8px" }}>Unpaid</span>
            );
          }
          else {
            exportItem.Status = "Paid";
            element.status = (
              <span class="badge rounded-pill bg-success" style={{ padding: "3px 8px 3px 8px" }}>Paid</span>
            )
          }
          element.action = (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <i
                className="fas fa-eye"
                style={{
                  fontSize: "1em",
                  cursor: "pointer",
                  marginLeft: "0.2em",
                  marginRight: "0.5em",
                }}
                onClick={() => handleClickOpenModal(element)}

              ></i>
            </div>
          );
          dataToBeExported.push(exportItem);
        });
        setDataToBeExported(dataToBeExported);
        setDetails(data);

      })
  };
  const printInvoice = () => {
    window.print()
  }

  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 150,
      },
      {
        label: "Name",
        field: "staff_name",
        sort: "asc",
        width: 150,
      },
      {
        label: "Month",
        field: "month",
        sort: "asc",
        width: 150,
      },

      {
        label: "Basic",
        field: "salary_name",
        sort: "asc",
        width: 150,
      },
      {
        label: "Present",
        field: "total_present",
        sort: "asc",
        width: 100,
      },
      {
        label: "Absent",
        field: "total_leave",
        sort: 100,
      },
      // {
      //   label: "Extra.",
      //   field: "extra_days",
      //   sort: "asc",
      //   width: 100,
      // },
      {
        label: "LOP",
        field: "lop",
        sort: "asc",
        width: 150,
      },
      {
        label: "Cal.",
        field: "calculated_salary",
        sort: "asc",
        width: 150,
      },

      {
        label: "Adv.",
        field: "advance_balance",
        sort: "asc",
        width: 150,
      },
      {
        label: "Extra.",
        field: "extra_payment_given",
        sort: "asc",
        width: 150,
      },
      {
        label: "Incentive",
        field: "incentive_given",
        sort: "asc",
        width: 150,
      },
      {
        label: "Pay",
        field: "to_pay",
        sort: "asc",
        width: 150,
      },
      // {
      //   label: "Added By",
      //   field: "addedBy",
      //   sort: "asc",
      //   width: 150,
      // },
      // {
      //   label: "Paid By",
      //   field: "paidBy",
      //   sort: "asc",
      //   width: 150,
      // },
      {
        label: "Status",
        field: "status",
        sort: "asc",
        width: 150,
      },
      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 150,
      },
    ],
    rows: details,
  };

  return (
    <>
      <React.Fragment>
        <Modal
          show={openModal}
          size="lg"
          centered={true}
          class="printable"
        >
          <div className="modal-header">
            <div className="d-print-none mt-1">
              <div className="float-end">
                <Link to="#" onClick={printInvoice} className="btn btn-dark waves-effect waves-light" style={{ padding: "2px" }}><i className="fa fa-print"></i></Link>{" "}
              </div>
            </div>
            <button
              type="button"
              onClick={() => {
                setOpenModal(false);
              }}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <CardBody>
              <div className="invoice-title">
                <table width="100%">
                  <tr>
                    <td rowSpan={2}>
                      <div className="mb-2">
                        <img src={logoSm} alt="logo" height="50" />
                      </div>
                    </td>
                    <td>
                      <h4 className="float-end font-size-16" style={{ textAlign: "right" }}>
                        Salary Slip : {selectedOne.month}<br></br>
                        <label className="font-size-12">#{selectedOne.reference_id}
                          {selectedOne.paid_status == 0 ? (
                            <span className="badge bg-danger font-size-12 ms-2">Unpaid</span>
                          ) : (
                            <span className="badge bg-success font-size-12 ms-2">Paid</span>
                          )
                          }

                        </label>


                      </h4>
                    </td>
                  </tr>

                </table>

                <div className="text-muted">
                  <p className="mb-1">{selectedOne.staff_name}</p>
                  <p className="mb-1"><i className="uil uil-envelope-alt me-1"></i>  {selectedOne.email}</p>
                  <p><i className="uil uil-phone me-1"></i> {selectedOne.mobile}</p>
                </div>
              </div>
              <hr className="my-4" />
              <table width="100%">
                <tr>
                  <th>Total Working Days</th><td>: {selectedOne.work_days}</td>
                  <th>Days Worked</th><td>: {selectedOne.total_present}</td>
                </tr>
                <tr>
                  <th>Absent Days</th><td>: {selectedOne.total_leave}</td>
                  <th>Extra Days Worked</th><td>: {selectedOne.extra_days}</td>
                </tr>
                <tr>
                  <th style={{ border: "#00000014 thin solid", padding: "5px" }}>Description</th>
                  <th style={{ border: "#00000014 thin solid", padding: "5px" }}>Earning</th>
                  <th style={{ border: "#00000014 thin solid", padding: "5px" }}>Description</th>
                  <th style={{ border: "#00000014 thin solid", padding: "5px" }}>Deduction</th>
                </tr>
                <tr>
                  <td style={{ border: "#00000014 thin solid", padding: "5px" }}>Basic Salary</td><td style={{ border: "#00000014 thin solid", padding: "5px" }}>{selectedOne.salary_name}</td><td style={{ border: "#00000014 thin solid", padding: "5px" }}>Advance</td><td style={{ border: "#00000014 thin solid", padding: "5px" }}>{selectedOne.advance_balance}</td>
                </tr>
                <tr>
                  <td style={{ border: "#00000014 thin solid", padding: "5px" }}>Extra Payment</td><td style={{ border: "#00000014 thin solid", padding: "5px" }}>{selectedOne.extra_payment_given}</td><td style={{ border: "#00000014 thin solid", padding: "5px" }}>LOP</td><td style={{ border: "#00000014 thin solid", padding: "5px" }}>{selectedOne.lop}</td>
                </tr>
                <tr>
                  <td style={{ border: "#00000014 thin solid", padding: "5px" }}>Incentive</td><td style={{ border: "#00000014 thin solid", padding: "5px" }}>{selectedOne.incentive_given}</td><td style={{ border: "#00000014 thin solid", padding: "5px" }}></td><td style={{ border: "#00000014 thin solid", padding: "5px" }}></td>
                </tr>
                <tr>
                  <td style={{ border: "#00000014 thin solid", padding: "5px", fontWeight: "bold" }}>Net Pay</td><td style={{ border: "#00000014 thin solid", padding: "5px", fontWeight: "bold" }}>{selectedOne.to_pay}</td><td style={{ border: "#00000014 thin solid", padding: "5px" }}></td><td style={{ border: "#00000014 thin solid", padding: "5px" }}></td>
                </tr>
              </table>
            </CardBody>
          </div>
        </Modal>
        <div className="page-content non-printable">
          <div className="container-fluid">
            <Breadcrumbs title="Home" breadcrumbItem="Salary Slip" />
            <Row>
              <Col xl="12">
                <Card>
                  <CardBody>
                    <AvForm className="needs-validation">
                      <Row>
                        <Col md="2">
                          <div className="mb-3">
                            <input
                              className="form-control"
                              type="month"
                              value={month}
                              id="example-date-input"
                              onChange={handleChangeDate}
                              max={moment().format("YYYY-MM")}
                            />
                          </div>
                        </Col>
                        <Col md="2">
                          <div className="mb-3">
                            <Select
                              name="staff_id"
                              placeholder="Staff"
                              value={searchSelectedStaff}
                              onChange={(selected) => {
                                handleSearchSelectedData(selected);
                              }}
                              options={staffOptions}
                              classNamePrefix="select2-selection"
                            />
                          </div>
                        </Col>

                        <Col md="2">
                          <div style={{ display: "flex" }} >
                            <div className="mt-0 mb-3 mx-1" >


                              <Button
                                color="danger"
                                type="reset"
                                onClick={() => reset()}
                              >
                                Reset
                              </Button>
                            </div>


                            <div className="mt-0 mb-3 mx-1" >

                              <Button
                                color="success"
                                type="button"
                              >
                                <CSVLink
                                  data={dataToBeExported}
                                  filename={
                                    getLocalbodyName() + " salary " + month + ".xls"
                                  }
                                  style={{ color: "white" }}
                                >
                                  Export
                                </CSVLink>
                              </Button>
                            </div>
                          </div>

                        </Col>
                      </Row>
                    </AvForm>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col className="col-12">
                <Card>
                  <CardBody>
                    <MDBDataTable id="AllSlipTable" responsive bordered data={data} />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
      </React.Fragment>
    </>
  );
};

export default CheckinCheckout;
