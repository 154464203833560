import React, { useEffect, useState } from "react";
import { Row, Col, Card, CardBody, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, UncontrolledDropdown, } from "reactstrap";
import { Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import ReactApexChart from "react-apexcharts";
import { AvField, AvForm } from "availity-reactstrap-validation";
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import CancelIcon from '@mui/icons-material/Cancel';
import HourglassBottomOutlinedIcon from '@mui/icons-material/HourglassBottomOutlined';
import { Doughnut } from "react-chartjs-2";
import { HorizontalBar } from "react-chartjs-2";
import ReactECharts from 'echarts-for-react';
import { getDate, getFirstday, getLocalbody, getUserId } from "../../helpers/globalFunctions";
import axios from "axios";
import accessToken from "../../helpers/jwt-token-access/accessToken";

const Dashboard = () => {
    const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";
    const [complaintsAll, setcomplaintsAll] = useState([])
    const [filterobjectAllcom, setfilterobjectAllcom] = useState({ fromDate: getFirstday(new Date), toDate: getDate(new Date()) })
    const [publicComplaints, setPublicComplaints] = React.useState(null);
    const [requests, setRequests] = React.useState(null);
    const [normalcompliants, setnormalcompliants] = React.useState(null);
    const [nowaste, setnowaste] = React.useState(null);
    const [selectedPeriod, setSelectedPeriod] = useState('day');
    const [dropdownOpen, setDropdownOpen] = useState(false)
    const [mydData, setmydData] = useState([])
    useEffect(() => {
        fetchAllcomplaints()
    }, [filterobjectAllcom])

    useEffect(() => {
        fetchAllcomplaintsDognut()

    }, [])

    useEffect(() => {
        fetchAllMYDcomplaints()
    }, [selectedPeriod])


    function fetchAllcomplaints() {
        axios.get(`${API_URL}complaint/complaint-dashboard?user=${getUserId()}&fromDate=${filterobjectAllcom?.fromDate}&toDate=${filterobjectAllcom?.toDate}&localbody=${getLocalbody()}`, {
            headers: {
                "x-access-token": accessToken
            }
        }).then((res) => {
            setcomplaintsAll(res.data.data)
        })
    }



    function fetchAllcomplaintsDognut() {
        axios
            .get(`${API_URL}complaint/complaint-statistics?user=${getUserId()}&localbody=${getLocalbody()}`, {
                headers: {
                    "x-access-token": accessToken,
                },
            })
            .then((res) => {
                const complaintData = res.data.data;

                // Function to transform data for a single category
                const transformCategoryData = (details) => {
                    const { total, _id, ...rest } = details;
                    const transformed = Object.entries(rest).map(([key, value]) => ({
                        value: value,
                        name: key.charAt(0).toUpperCase() + key.slice(1),
                    }));
                    return { total, data: transformed };
                };

                // Transform and set data for each category
                if (complaintData.publicComplaint) {
                    setPublicComplaints(transformCategoryData(complaintData.publicComplaint));
                }
                if (complaintData.requestComplaint) {
                    setRequests(transformCategoryData(complaintData.requestComplaint));
                }
                if (complaintData.normalComplaint) {
                    setnormalcompliants(transformCategoryData(complaintData.normalComplaint));
                }
                if (complaintData.noWasteComplaint) {
                    setnowaste(transformCategoryData(complaintData.noWasteComplaint));
                }
            })
            .catch((error) => {
                console.error("Error fetching complaint statistics:", error);
            });
    }







    const chartOptions = {
        series: [{
            name: "Edit Requests",
            data: mydData?.data || []
        }],
        options: {
            chart: {
                height: 350,
                type: 'line',
                zoom: {
                    enabled: false
                }
            },
            dataLabels: {
                enabled: false
            },
            stroke: {
                curve: 'straight'
            },
            title: {
                // text: `Total Complaints by ${selectedPeriod}`,
                align: 'left'
            },
            grid: {
                row: {
                    colors: ['#f3f3f3', 'transparent'],
                    opacity: 0.5
                },
            },
            xaxis: {
                // categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep'],
                categories: mydData?.categories || [],

            }
        }
    }



    
  

  

   


    const chartData = {
        series: [
            {
                data: [19,20,30] || [],
            },
        ],
        options: {
            chart: {
                type: "bar",
                height: 150,
            },
            plotOptions: {
                bar: {
                    borderRadius: 4,
                    borderRadiusApplication: "end",
                    horizontal: true,
                },
            },
            dataLabels: {
                enabled: true,
            },
            xaxis: {
                categories: [
                    "Basic",
                    "Email",
                    "Address",
                ],
                // categories: complaintsAll.categories || []

            },
            colors: [
                "#008FFB", "#00E396", "#FEB019",
            ],
        },
    };

    function handlechange(e) {
        let { name, value } = e.target
        setfilterobjectAllcom({ ...filterobjectAllcom, [name]: value })
    }

    function fetchAllMYDcomplaints() {
        axios.get(`${API_URL}complaint/complaint-graph?user=${getUserId()}&filterType=${selectedPeriod}&localbody=${getLocalbody()}`, {
            headers: {
                "x-access-token": accessToken
            }
        }).then((res) => {
            setmydData(res.data)
        })
    }

    return (
        <>
            <div className="page-content">
                <div className="container-fluid">
                    <Row style={{ rowGap: "20px" }}>
                        <Col md="6">
                           
                                    <Row>
                                        <Col md="6">
                                            <h6> Category Wise Edit Requests: {filterobjectAllcom?.totalComplaints}</h6>
                                        </Col>
                                        <Col md="3">

                                            <label>From</label>
                                            <input
                                                name="fromDate"
                                                type="date"
                                                className="form-control mx-1"
                                                value={filterobjectAllcom?.fromDate}
                                                onChange={handlechange}

                                            />
                                        </Col>
                                        <Col md="3">

                                            <label>To</label>
                                            <input
                                                name="toDate"
                                                type="date"
                                                className="form-control "
                                                value={filterobjectAllcom?.toDate}
                                                onChange={handlechange}

                                            />

                                        </Col>
                                    </Row>

                                    <div>

                                        <ReactApexChart
                                            options={chartData.options}
                                            series={chartData.series}
                                            type="bar"
                                            height={210}
                                            key={chartData}
                                        />

                                    </div>

                              
                        </Col>
                        <Col md="6">
                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                <h6>Total Edit Requests by {selectedPeriod} </h6>
                                <div> <select value={selectedPeriod} onChange={e => setSelectedPeriod(e.target.value)}>
                                    <option value="day">Daily</option>
                                    <option value="month">Monthly</option>
                                    <option value="year">Yearly</option>
                                </select></div>

                            </div>


                            <ReactApexChart key={selectedPeriod} options={chartOptions.options} series={chartOptions.series} type="line"  />
                        </Col>
                       
                    </Row>
                   
                </div>
            </div>
        </>
    );
};

export default Dashboard;
