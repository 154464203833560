import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { MDBDataTable } from "mdbreact";
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Label,
  Modal,
  Table,
} from "reactstrap";
import accessToken from "../../../helpers/jwt-token-access/accessToken";
import axios from "axios";
import moment from "moment";
import $ from "jquery";
import Select from "react-select";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { CSVLink } from "react-csv";
import {
  getDate,
  getFirstday,
  getLocalbody,
  isSuperAdmin,
} from "../../../helpers/globalFunctions";
import "./staffReport.scss";
import { getCustomerType } from "../../../store/actions";
import Pagination from "@mui/material/Pagination";
import toastr from "toastr";
import { jsonToExcel } from "../../../helpers/api_helper";

const StaffReport = (props) => {
  const [searchData, setSearchData] = useState({
    from_date: getFirstday(new Date()),
    to_date: getDate(new Date()),
  });
  const [wardOptions, setWardOptions] = useState([]);
  const [selectedWard, setSelectedWard] = useState(null);
  const [groupOptions, setGroupOptions] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [currentLocalbody, setCurrectLocalbody] = useState(getLocalbody());
  const [staffOptions, setStaffOptions] = useState([]);
  const [selectedStaff, setSelectedStaff] = useState(null);
  const [grandTotal, setGrandTotal] = useState(null);
  const [details, setDetails] = useState([]);
  const [dataToBeExported, setDataToBeExported] = useState([]);
  const [selectedCustomerType, setSelectedCustomerType] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [customerType, setCustomerType] = useState([]);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const { customerTypes } = useSelector((state) => state.customers);
  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";
  useEffect(() => {
    handleTableData();
    fetch_all_wards(currentLocalbody);
    fetch_all_staff(currentLocalbody);
    // dispatch(getCustomerType());
  }, [currentLocalbody]);

  useEffect(() => {
    if (selectedCategory != null) {
      dispatch(getCustomerType(selectedCategory.value))
    }
  }, [selectedCategory]);


  // pagination
  const [searchValue, setSearchValue] = useState("")
  const [totalPages, setTotalPages] = useState(0)
  const [currentPage, setCurrentPage] = useState(1)
  const [PerPageCount, setPerPageCount] = useState(20)
  const [indexOfLastTransfer, setindexOfLastTransfer] = useState(currentPage * PerPageCount)
  const [indexOfFirstTransfer, setindexOfFirstTransfer] = useState(indexOfLastTransfer - PerPageCount)

  const from_date = searchData?.from_date;
  const to_date = searchData?.to_date;
  const ward_id = searchData?.ward_id;
  const group_id = searchData?.group_id;
  const staff_id = searchData?.staff_id;
  const category_type = searchData?.category_type;
  const category_id = searchData?.category_id;

  const debounce = (func, delay) => {
    let timerId;
    return (...args) => {
      clearTimeout(timerId)
      timerId = setTimeout(() => func(...args), delay);
    }
  }

  const paginate = (event, value) => {
    setCurrentPage(value)
    handleTableData(from_date, to_date, ward_id, group_id, staff_id, category_type, category_id, value, PerPageCount)
  }

  useEffect(() => {
    if (details?.length === 0 && indexOfFirstTransfer !== 0) {
      resetPaginate();
      handleTableData(searchValue, currentLocalbody, from_date, to_date, ward_id, group_id, staff_id, category_type, category_id, currentPage, PerPageCount);
    }
  }, [details])

  const resetPaginate = () => {
    setCurrentPage(1);
    const indexOfFirstTransfer = 0;
    const indexOfLastTransfer = 20;
    setindexOfFirstTransfer(indexOfFirstTransfer);
    setindexOfLastTransfer(indexOfLastTransfer);
  }


  useEffect(() => {
    var data =
      customerTypes &&
      customerTypes.map((el) => {
        return {
          label: el.customer_type_name,
          value: el._id,
        };
      });

    setCustomerType([
      {
        options: data,
      },
    ]);
  }, [customerTypes]);

  function handleTableData(
    from_date = "",
    to_date = "",
    ward_id = "",
    group_id = "",
    staff_id = "",
    category_type = "",
    category_id = "",
    start = 1,
    limit = 20
  ) {
    let userId = "";
    if (localStorage.getItem("authUser")) {
      var data = localStorage.getItem("authUser")
      const obj = JSON.parse(data)
      userId = obj._id
    }

    var url = `${API_URL}reports/staff_report_crm?localbody_id=` +
      currentLocalbody +
      "&user_id=" +
      userId;

    if (from_date === "" && to_date === "") {
      from_date = from_date ? from_date : getFirstday(new Date());
      to_date = to_date ? to_date : getDate(new Date())
    }

    url =
      url +
      "&from_date=" +
      from_date +
      "&to_date=" +
      to_date +
      "&ward_id=" +
      ward_id +
      "&group_id=" +
      group_id +
      "&staff_id=" +
      staff_id +
      "&category_type=" +
      category_type +
      "&category_id=" +
      category_id +
      "&start=" +
      start +
      "&limit=" +
      limit;
    axios
      .get(url, {
        headers: {
          "x-access-token": accessToken,
        },
      }).then((res) => {
        let result = res.data.data;

        let dataToBeExported = [];
        let vistHouse = 0;
        let paidHouse = 0;
        let houseAmt = 0;
        let vistShop = 0;
        let paidShop = 0;
        let ShopAmt = 0;
        let totalAmount = 0;
        let housew=0;
        let shopw=0;
        let totalWeight = 0;

        setTotalPages(res.data.count)

        let id = (start - 1) * limit

        result.map((item, indx) => {
          let exportItem = {};

          id++;
          item.id = id;
          item.staff = item?.firstName + " " + item?.lastName;

          //export data
          exportItem.id = item.id;
          exportItem.Staff = item.staff;
          exportItem.Visited_Houses = item.visited_houses;
          exportItem.Paid_Houses = item.paid_houses;
          exportItem.House_Amt = item.house_amt;
          exportItem.Visited_Shops = item.visited_shops;
          exportItem.Paid_Shops = item.paid_shops;
          exportItem.Shop_Amt = item.shop_amt;
          exportItem.Total = item.total_amt;
          exportItem.Weight = item.total_weight;

          dataToBeExported.push(exportItem);

          // Calculate the total summary
          totalAmount += item?.total_amt;
          vistHouse += item?.visited_houses;
          paidHouse += item?.paid_houses;
          houseAmt += item?.house_amt;
          vistShop += item?.visited_shops;
          paidShop += item?.paid_shops;
          ShopAmt += item?.shop_amt;
          housew +=item?.houseWeight;
          shopw +=item?.shopWeight
          totalWeight += parseFloat(item?.total_weight);

        })

        // Add summary row
        if (result.length !== 0) {
          result.push({
            id: "",
            staff: <b>Total Summary</b>,
            visited_houses: <b>{vistHouse}</b>,
            paid_houses: <b>{paidHouse}</b>,
            house_amt: <b>{houseAmt}</b>,
            visited_shops: <b>{vistShop}</b>,
            paid_shops: <b>{paidShop}</b>,
            shop_amt: <b>{ShopAmt}</b>,
            total_amt: <b>{totalAmount}</b>,
            total_weight: <b>{totalWeight.toFixed(2)}</b>,
            shopWeight:<b>{shopw}</b>,
            houseWeight:<b>{housew}</b>,

          });
        }

        // Add the summary row here
        // setDataToBeExported(dataToBeExported);
        setDetails(result)

        // fetch_export_data(from_date, to_date, ward_id, group_id, staff_id, category_type, category_id)
      })
  }

  const fetch_export_data =async (
    from_date = "",
    to_date = "",
    ward_id = "",
    group_id = "",
    staff_id = "",
    category_type = "",
    category_id = "",
  ) => {
    let userId = "";
    if (localStorage.getItem("authUser")) {
      var data = localStorage.getItem("authUser")
      const obj = JSON.parse(data)
      userId = obj._id
    }

    var url = `${API_URL}reports/staff_report_crm/exports?localbody_id=` + currentLocalbody +
      "&user_id=" +
      userId;

    if (from_date === "" && to_date === "") {
      from_date = from_date ? from_date : "";
      to_date = to_date ? to_date : "";
    }

    url =
      url +
      "&from_date=" +
      from_date +
      "&to_date=" +
      to_date +
      "&ward_id=" +
      ward_id +
      "&group_id=" +
      group_id +
      "&staff_id=" +
      staff_id +
      "&category_type=" +
      category_type +
      "&category_id=" +
      category_id;
    setLoading(true);
    return await axios
      .get(url, {
        headers: {
          "x-access-token": accessToken,
        },
      }).then(res => {
        let dataToBeExported = [];
        let result = res.data.data;
        result &&
          result.map((el, indx) => {
            let exportItem = {};
            exportItem.id = indx + 1;
            exportItem.Staff = el?.firstName + " " + el?.lastName;
            exportItem.Visited_Houses = el?.visited_houses;
            exportItem.Paid_Houses = el?.paid_houses;
            exportItem.House = el?.house_amt;
            exportItem.Visited_Shops = el?.visited_shops;
            exportItem.Paid_Shops = el?.paid_shops;
            exportItem.Shop = el?.shop_amt;
            exportItem.Total = el?.total_amt;
            exportItem.Weight = el?.total_weight;

            dataToBeExported.push(exportItem)
          })

          return dataToBeExported
        // setDataToBeExported(dataToBeExported)
      }).finally(() => {
        setLoading(false)
      })

  }



  const fetch_all_staff = (id) => {
    axios
      .get(`${API_URL}user/options?localbody_id=` + id, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var staff_data =
          res.data.data &&
          res.data.data.map((el) => {
            if (el?.lastName) el.name = el.firstName + " " + el.lastName;
            else el.name = el.firstName;
            return {
              label: el?.name,
              value: el._id,
            };
          });
        setStaffOptions([
          {
            options: staff_data,
          },
        ]);
      });
  };
  function fetch_all_wards(localbody) {
    axios
      .get(`${API_URL}api/staff/wards?localbody_id=` + localbody, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var ward_data =
          res.data.data &&
          res.data.data.map((el) => {
            return {
              label: el.ward_name,
              value: el._id,
            };
          });

        setWardOptions([
          {
            options: ward_data,
          },
        ]);
      });
  }

  async function handleExport(){
  const data = await fetch_export_data(from_date, to_date, ward_id, group_id, staff_id, category_type, category_id)

  if (data.length > 0) {
    jsonToExcel(data, `staff-report-${moment().format("DD-MM-YY")}`);
  } else {
    toastr.warning("There are no data to export");
  }
  }

  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 150,
      },
      {
        label: "Staff",
        field: "staff",
        sort: "asc",
        width: 400,
      },
      {
        label: "Visited Houses",
        field: "visited_houses",
        sort: "asc",
        width: 200,
      },
      {
        label: "Paid Houses",
        field: "paid_houses",
        sort: "asc",
        width: 200,
      },
      {
        label: "Houses.W(kg)",
        field: "houseWeight",
        sort: "asc",
        width: 200,
      },
      {
        label: "House ₹",
        field: "house_amt",
        sort: "asc",
        width: 200,
      },
      {
        label: "Visited Shops",
        field: "visited_shops",
        sort: "asc",
        width: 200,
      },
      {
        label: "Paid Shops",
        field: "paid_shops",
        sort: "asc",
        width: 200,
      },
      {
        label: "Shops.W(kg)",
        field: "shopWeight",
        sort: "asc",
        width: 200,
      },
      {
        label: "Shop ₹",
        field: "shop_amt",
        sort: "asc",
        width: 200,
      },
      {
        label: "Total ₹",
        field: "total_amt",
        sort: "asc",
        width: 200,
      },
      {
        label: "Weight(Kg)",
        field: "total_weight",
        sort: "asc",
        width: 200,
      },
    ],
    rows: details,
  };

  let handleDate = (e) => {
    var date1 = $("#from_date").val();
    var date2 = $("#to_date").val();
    // let ward_id = searchData?.ward_id ? searchData.ward_id : "";
    // let group_id = searchData?.group_id ? searchData.group_id : "";
    // let staff_id = searchData?.staff_id ? searchData.staff_id : "";
    // let category_id = searchData?.category_id ? searchData.category_id : "";
    let toDate = moment(date1);
    let fromDate = moment(date2);
    let result = fromDate.diff(toDate, "days");

    if (result + 1 <= 0) {
      setSearchData({
        ...searchData,
        [e.target.name]: e.target.value,
        ["to_date"]: "",
      });
      date2 = "";
    } else {
      setSearchData({
        ...searchData,
        [e.target.name]: e.target.value,
      });
    }
  };

  const handleSelectChange = (selected, name) => {
    switch (name) {
      case "ward":
        setSelectedWard(selected);
        setSelectedGroup(null);
        setSearchData({
          ...searchData,
          ["ward_id"]: selected.value,
          group_id: "",
        });
        axios
          .get(
            `${API_URL}api/staff/groups?localbody_id=` +
            currentLocalbody +
            "&ward_id=" +
            selected.value,
            {
              headers: {
                "x-access-token": accessToken,
              },
            }
          )
          .then((res) => {
            if (res.data && res.data.success === true) {
              var group_data =
                res.data.data &&
                res.data.data.map((el) => {
                  return {
                    label: el.group_name,
                    value: el._id,
                  };
                });
            } else {
              group_data = [];
            }

            setGroupOptions([
              {
                options: group_data,
              },
            ]);
          });
        // handleTableData(
        //   date1,
        //   date2,
        //   selected.value,
        //   "",
        //   staff_id,
        //   category_id
        // );
        break;
      case "group":
        setSelectedGroup(selected);
        setSearchData({
          ...searchData,
          ["group_id"]: selected.value,
        });
        // handleTableData(
        //   date1,
        //   date2,
        //   ward_id,
        //   selected.value,
        //   staff_id,
        //   category_id
        // );
        break;
      case "staff":
        setSelectedStaff(selected);
        setSearchData({
          ...searchData,
          ["staff_id"]: selected.value,
        });
        // handleTableData(
        //   date1,
        //   date2,
        //   ward_id,
        //   group_id,
        //   selected.value,
        //   category_id
        // );
        break;
      case "category_type":
        setSelectedCategory(selected);
        setSelectedCustomerType(null);
        setSearchData({
          ...searchData,
          ["category_type"]: selected.value,
          ["category_id"]: ""
        });
        break;
      case "customerType":
        setSelectedCustomerType(selected);
        setSearchData({
          ...searchData,
          ["category_id"]: selected.value,
        });
        // handleTableData(
        //   date1,
        //   date2,
        //   ward_id,
        //   group_id,
        //   staff_id,
        //   selected.value
        // );
        break;
      default:
        break;
    }
  };
  const reset = () => {
    resetPaginate();
    setSearchData({
      from_date: getFirstday(new Date()),
      to_date: getDate(new Date()),
    });
    setSelectedWard(null);
    setSelectedGroup(null);
    setSelectedStaff(null);
    setSelectedCategory(null);
    setSelectedCustomerType(null);
    handleTableData(searchValue);
  };
  const handleSearch = (value) => {
    setSearchValue(value);
    resetPaginate()
    let ward_id = searchData?.ward_id ? searchData.ward_id : "";
    let group_id = searchData?.group_id ? searchData.group_id : "";
    let staff_id = searchData?.staff_id ? searchData.staff_id : "";
    let category_id = searchData?.category_id ? searchData.category_id : "";
    let date1 = searchData?.from_date ? searchData.from_date : "";
    let date2 = searchData?.to_date ? searchData.to_date : "";
    let category_type = searchData?.category_type ? searchData.category_type : "";
    handleTableData(date1, date2, ward_id, group_id, staff_id, category_type, category_id);
  };


  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Staff Report" />
          <Row>
            <Col className="col-12">
              {/* <Row style={{ textAlign: "right" }}>
                <h5>Grand Total : {grandTotal}</h5>
              </Row> */}
              <Card>
                <CardBody>
                  <Row>
                    <Col md="2">
                      <div className="mb-3">
                        <Label htmlFor="validationCustom05">From</Label>
                        {/* <div className="col-md-10"> */}
                        <input
                          className="form-control"
                          type="date"
                          id="from_date"
                          name="from_date"
                          value={searchData?.from_date}
                          onChange={handleDate}
                        />
                        {/* </div> */}
                      </div>
                    </Col>
                    <Col md="2">
                      <div className="mb-3">
                        <Label htmlFor="validationCustom05">To</Label>
                        {/* <div className="col-md-10"> */}
                        <input
                          className="form-control"
                          type="date"
                          id="to_date"
                          name="to_date"
                          value={searchData?.to_date}
                          min={searchData?.from_date}
                          onChange={handleDate}
                        />
                        {/* </div> */}
                      </div>
                    </Col>
                    <Col md="2">
                      <div className="mb-3">
                        <Label>Ward</Label>
                        <Select
                          name="ward_id"
                          value={selectedWard}
                          onChange={(selected) => {
                            handleSelectChange(selected, "ward");
                          }}
                          options={wardOptions}
                          classNamePrefix="select2-selection"
                        />
                      </div>
                    </Col>
                    <Col md="2">
                      <div className="mb-3">
                        <Label>Group</Label>
                        <Select
                          name="group_id"
                          value={selectedGroup}
                          onChange={(selected) => {
                            handleSelectChange(selected, "group");
                          }}
                          options={groupOptions}
                          classNamePrefix="select2-selection"
                        />
                      </div>
                    </Col>
                    <Col md="2">
                      <div className="mb-3">
                        <Label>Staff</Label>
                        <Select
                          name="staff_id"
                          value={selectedStaff}
                          onChange={(selected) => {
                            handleSelectChange(selected, "staff");
                          }}
                          options={staffOptions}
                          classNamePrefix="select2-selection"
                        />
                      </div>
                    </Col>
                    <Col md="2">
                      <div className="mb-3">
                        <Label>Category Type</Label>
                        <Select
                          name="category_type"
                          value={selectedCategory}
                          classNamePrefix="select2-selection"
                          options={[
                            { label: "Residential", value: 1 },
                            { label: "Commercial", value: 2 }
                          ]}
                          onChange={(selected) => {
                            handleSelectChange(selected, "category_type")
                          }}
                        />
                      </div>
                    </Col>
                    <Col md="2">
                      <div className="mb-3">
                        <Label>Customer Type</Label>
                        <Select
                          name="category_id"
                          value={selectedCustomerType}
                          onChange={(selected) => {
                            handleSelectChange(selected, "customerType");
                          }}
                          options={customerType}
                          classNamePrefix="select2-selection"
                        />
                      </div>
                    </Col>
                    <Col md="3">
                      <div style={{ display: "flex" }}>
                        <div
                          className="mt-1 mb-3 mx-1"
                          style={{ paddingTop: "22px" }}
                        >
                          <Button
                            color="primary"
                            type="submit"
                            onClick={() => handleSearch()}
                          >
                            Search
                          </Button>
                        </div>
                        {isSuperAdmin() ? (
                        <div
                          className="mt-1 mb-3 mx-1"
                          style={{ paddingTop: "22px" }}
                        >
                          <Button disabled={loading} onClick={()=>{
                            handleExport()
                          }} color="success" type="submit">
                            {/* <i className="mdi mdi-microsoft-excel"></i> */}
                            {loading ? 'Fetching...' : 'Export'}
                          </Button>
                        </div>
                      ) : null}
                        <div
                          className="mt-1 mb-3 mx-1"
                          style={{ paddingTop: "22px" }}
                        >
                          <Button
                            color="danger"
                            type="reset"
                            onClick={() => reset()}
                          >
                            Reset
                          </Button>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <MDBDataTable
                    id="staffReportId"
                    responsive
                    bordered
                    data={data}
                    info={false}
                    searching={true}
                    disableRetreatAfterSorting={true}
                    paging={false}
                    onSearch={debounce(handleSearch, 500)}
                    entries={PerPageCount}
                  />
                  {totalPages > 20 && (
                    <div className="pagination-div">
                      <div className="page-details">
                        Showing {((currentPage - 1) * PerPageCount) + 1} to {" "}
                        {Math.min(currentPage * PerPageCount, totalPages)} of {" "}
                        {totalPages} entries
                      </div>
                      <Pagination
                        color="standard"
                        shape="roundered"
                        defaultPage={1}
                        count={Math.ceil(totalPages / PerPageCount)}
                        page={currentPage}
                        onChange={paginate}
                        info={true}
                        size="large"
                      />
                    </div>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

export default StaffReport;