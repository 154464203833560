import axios from "axios";
import accessToken from "./jwt-token-access/accessToken";
import moment from "moment";
import * as XLSX from "xlsx";

//apply base url for axios
const API_URL = process.env.REACT_APP_APIURL;

const axiosApi = axios.create({
  baseURL: API_URL,
});

// axios.defaults.headers.common["x-access-token"] = accessToken;

axiosApi.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject(error)
);

export async function get(url, config) {
  return await axiosApi
    .get(url, {
      ...config,
      headers: {
        "x-access-token": accessToken,
      },
    })
    .then((response) => response.data);
}

export async function post(url, data, config) {
  return axiosApi
    .post(url, data, {
      ...config,
      headers: {
        "x-access-token": accessToken,
      },
    })
    .then((response) => response.data);
}

export async function put(url, data, config) {
  console.log(data, "datauser");
  return axiosApi
    .put(
      url,
      { ...data },
      {
        ...config,
        headers: {
          "x-access-token": accessToken,
        },
      }
    )
    .then((response) => response.data);
}

export async function del(url, id) {
  return await axiosApi
    .delete(url + "/" + id, {
      headers: {
        "x-access-token": accessToken,
      },
    })
    .then((response) => response.data);
}

export const jsonToExcel = (jsonArray, sheetName = moment().format("DDMMYYhhmmss")) => {
  // Create a worksheet
  const ws = XLSX.utils.json_to_sheet(jsonArray);

  // Create a workbook
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, sheetName);

  // Save to a file
  XLSX.writeFile(wb, `${sheetName}.xlsx`);
};
